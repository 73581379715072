<template>
  <div class="container" v-bind:containerid="layerData.id" data-containerid="1"
   data-type="container" v-bind:style="containerStyles" v-if="layerData" :class="disableWidthClass ? '' : layerData.disableResponsive ? `noresponsive ${!regularView ? 'actionbuttondisplay' : ''}`  : layerData.class"  :ref="layerData.id">
   <template v-if="seen">
      <template class="regular" v-if="regularView">
        <component :disableWidthClass="disableWidthClass"  :siblings="dataSibs" :is="column.type" v-for="(column,i) in layerData.columns" 
        v-bind:key="i" v-bind:layerData.sync="column" 
        :colNum ="totalColumns" :gIndex="gIndex"
        :renderFrom="renderFrom"
        :gadgetLists="gadgetLists"
        :renderLocation="renderLocation"
        :renderlocId="renderlocId"></component>
      </template>

      <template class="actionbutton" v-else>
          <actionButton  :siblings="dataSibs"  :colNum ="layerData"
          v-bind:layerData="layerData" :gIndex="gIndex"
          :renderFrom="renderFrom"
          :gadgetLists="gadgetLists"
          :renderLocation="renderLocation"
          :renderlocId="renderlocId"
          :isModal="false"/>
      </template>
    </template>
  </div>
</template>

<script>
import fn from '@/common/lib.service'
import { mapGetters } from 'vuex'

export default {
  name: 'Container',
  data () {
    return {
      totalColumns: 0,
      observer: null,
      seen:false,
      regularView: true,
      touchDevice: fn.isTouchDevice() ? true : false
    }
  },
  computed: {
    ...mapGetters(['revotioPageData','revotioGadgetData']),
    // a computed getter
    containerStyles: function () {
      return fn.pageMergeDesign(this.revotioPageData, this.layerData)
    },
    dataSibs: function () {
      return {
        id: this.layerData.id,
        sibs: this.layerData.columns
      }
    },
  },
  methods: {
    getComponentClasses: function () {
      fn.sibsGridClasses (this.layerData,  'container')
      for (let i in this.layerData.columns) {
        let item = this.layerData.columns[i]
        if (item.type === 'item')
          item.class = fn.gridClasses (1,  'item', item.width)
        else if (item.type!== 'item' && item.hidden === true) item.class = fn.gridClasses (1,  'container', 0)
        else item.class = fn.gridClasses (1,  'container', item)
      }
    },
    totalColumnsCal: function () {
      if (this.layerData.hasOwnProperty('columns')) {
        let active = this.layerData.columns.filter(data=>{
          if (!data.hide) {
            return true
          }
        })
        return active.length
      } else {
        return 0
      }
    },
    newWidth (combinedTotal, currentwidth, parentWidth) {
      let percentage = (((100/combinedTotal) * currentwidth ) / 100 ) * parentWidth
      return percentage
    },
    changeWidth (from, to, currentwidth, fromTotalWidth) {
      for (let i = from; i < to; i ++) {
        let layer = this.layerData.columns[i]
      }
    },
    checkWidth () {
      //let children
      //current pix
      let currentwidth = this.$el.offsetWidth
      let from = 0
      let fromTotalWidth
      for (let i in this.$children) {
        let data = this.$children[i]
        this['check'+data.layerData.type](data)
      }
    },
    checkitem (data) {
      if (data.layerData.type === 'item') {
        //check the gadget
        let el = data.$el
        let itemWidth = el.offsetWidth
        let isInMin = false
        let parentMin = false
        let gadgetData = {}
        for (let i in data.layerData.content) {
          gadgetData = data.layerData.content[i]
          isInMin = fn.gadgetInMin(gadgetData.type, itemWidth)
          parentMin = fn.gadgetInMin(gadgetData.type, currentwidth)
        }
        if (gadgetData.type !=='data-card'){
          return
        }
      }
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting}) => {
          if (!isIntersecting) {
            return;
          }
          this.observer.unobserve(target);
          this.seen = true
      });
    },
    checkIfContentFit (outSize = 300) {
      // TODO add the options here
      let {disableResponsive,toActionButton} = this.layerData || {}
      if (!disableResponsive && !toActionButton) {
        this.$el.style.opacity = 100
        return
      }
      // console.log(disableResponsive,toActionButton)
      setTimeout(() => {
        // if (this.layerData.id !== '25316751-81cb-7898-0be0-ed5eebf542c3') return
        let el = this.$el
        let children = this.$children
        let totalWidth = 0
        const childLoop = (childrenx) => {
          childrenx.forEach(child=> {
            if (child.layerData && ['cell','container'].includes(child.layerData.type)) {
              // console.log(child.$children)
              childLoop(child.$children)
            } else {
              // console.log(child.$el)
              if (child.layerData && child.layerData.type === 'item' && child.layerData.content[0].type === 'breadcrumbs') {
                let bd = child.$el.querySelector('nav ol')
                if (!bd) {
                  bd = child.$el.querySelectorAll('div.breadcrumbs-selection') || {}
                }
                // console.log(child,bd.clientWidth || child.$el.clientWidth || 0)
                totalWidth += bd.clientWidth || child.$el.clientWidth || 0
              } else {
                // console.log(child,child.$el.clientWidth || 0)
                totalWidth += child.$el.clientWidth || 0
              }
            }
          })
        }
        childLoop(children)
        // console.log(el.clientWidth, totalWidth)
        if (el.clientWidth < totalWidth) {
          // move to action button
          if (!this.layerData.icon) {
            this.$set(this.layerData,'icon','menu')
          }
          this.$set(this.layerData,'open','right-side-bar')
          // this.$set(this.layerData,'toActionButtonPosition','center')
          this.$set(this,'regularView', false)
          // console.log(children)
          // console.log(el,el.clientWidth, totalWidth)
        } else this.$set(this,'regularView', !this.touchDevice)
        this.$nextTick(()=>{
            if (!this.regularView) {
              if (!this.layerData.icon) {
                this.$set(this.layerData,'icon','menu')
              }
              this.$set(this.layerData,'open','right-side-bar')
            }
        })
        this.$el.style.opacity = 100
        if (this.touchDevice) {
          this.$set(this,'regularView',false)
        }
      },outSize)
    }
  },
  watch: {
    windowWidth () {
      this.getComponentClasses()
      this.checkWidth()
      this.$set(this,'regularView',true)
      this.$nextTick(()=>{
        this.checkIfContentFit(100)
      })
    },
    revotioPageData () {
      this.$destroy()
    },
    revotioGadgetData () {
      this.$el.style.opacity = 0
      this.regularView = true
      this.$nextTick(()=> {
        this.checkIfContentFit(400)
      })
    }
  },
  mounted (){
    // if (this.layerData.id === 'f4078407-d484-09f3-fd8d-a5ba16a0ec7f') {
    //   this.layerData.toActionButton = true
    //   this.layerData.disableResponsive = true
    // }
    this.$el.style.opacity = 0
    this.getComponentClasses()
    this.totalColumns = this.totalColumnsCal()
    this.$on('hideMe',data => {
      let hideWidth = 0
      for (let i in this.layerData.columns){
        let col = this.layerData.columns[i]
        if (col.id === data.layer && data.hide) {
          //hidden
          col.hide = true
          hideWidth = parseFloat(col.styles.width)
        }
      }
      this.totalColumns = this.totalColumnsCal()
      let div = hideWidth/this.totalColumns

      this.layerData.columns = this.layerData.columns.filter (col=>{
        if(!col.hide){
          col.styles.width = parseFloat(col.styles.width) + div
          return true
        }
      })
    })
    this.observer.observe(this.$el);
    if (this.revotioGadgetData.gadgetsList && this.revotioGadgetData.gadgetsList.length >= 1) {
      this.$nextTick(()=> {
        this.checkIfContentFit()
      })
    }
  },
  created() {
    this.observer = new IntersectionObserver(
      this.onElementObserved, 
      {
        root: this.$el,
        threshold: 0,
      }
    );
  },
  beforeDestroy () {
    this.$off('hidden');
    this.observer.disconnect();
  },
  props: {
    layerData: Object,
    gIndex: [Number, String],
    siblings: Object,
    disableWidthClass: { type: Boolean, default: false},
    isModal: { type: Boolean, default: false},
    renderFrom: {type: String, default:'main'},
    renderLocation: {type: String, default:'container'},
    gadgetLists: {type: [Object,Array]},
    renderlocId: {type: String, default:''}
  },
  components: {
    item: () => import('./item'),
    actionButton: () => import('./actionButton/actionButton'),
    cell: () => import('./cell'),
    portlet: () => import('@/components/gadgets/portlet'),
    modal: () => import('./modal')
  }
}
</script>
