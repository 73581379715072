<template>
  <div
    class  = "h-nav-group cursor-pointer"
    :class = "[
      {'h-nav-group-open'            : openItems        },
      {'h-nav-group-active'          : open             },
      {'disabled-item pointer-events-none': group.isDisabled },
      { 'relative':(level>1)},
      `lvl:${level}`
    ]"
    @click.prevent = "mouseover"
    @mouseleave.self  = "mouseout">

      <!-- Group Label -->
      <div class="group-header w-full flex items-center"  :class="`lvl:${level}`">
        <span class="flex items-center w-full"  :class="`lvl:${level}`">

          <!-- Group Icon -->
          <!-- <feather-icon
            v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
            :icon       = "group.icon  || 'CircleIcon'"
            :svgClasses = "iconClasses" /> -->
          <vs-avatar v-if="icon !== '' && icon !== null" color="primary" :icon="icon" size="small"  :class="`lvl:${level}`"/>
          <vs-avatar v-if="icon === '' || icon === null" :src="src" :icon="icon" size="small"  :class="`lvl:${level}`"/>
          <!-- Group Name -->
          <span class="truncate mr-3 select-none" :class="`lvl:${level}`">{{  group.name }}</span>

        </span>

        <!-- Group Collapse Icon -->
        <feather-icon
          :class     = "[{'rotate90' : (open && level >2 ) ||openItems }, 'feather-grp-header-arrow',`${level}lvl`]"
          :icon       = "bottom ? 'ChevronDownIcon' : 'ChevronRightIcon'"
          svg-classes= "w-4 h-4" />
      </div>

      <vs-progress indeterminate color="primary" v-if="showloading">primary</vs-progress>
      <!-- /Group Label -->

      <!-- Group Items -->
      <transition name="fade-bottom-2x">
      <ul  :style="styleItems" class="h-nav-group-items h-nav-menu-dd py-2" v-show="openItems" ref="childDropdown" :class="[(level>0) ? 'ml-4 mr-4 norightborder' : 'absolute shadow-drop']">
        <li v-for="(groupItem, index) in group.inner" :key="index">
          <h-nav-menu-group
            v-if        = "getItem(groupItem) == 'group'"
            :group      = "groupItem"
            :groupIndex = "Number(`${groupIndex}.${index}`)"
            :open       = "isGroupActive(groupItem)"
            :openHover  = "openHover"
            :level      = "currentLevel" />
          <h-nav-menu-item
            v-else
            icon-small
            :index  = "groupIndex + '.' + index"
            :to     = "groupItem.slug !== 'external' ? groupItem.url : null"
            :href   = "groupItem.slug === 'external' ? groupItem.url : null"
            :icon   = "itemIcon"
            :slug   = "groupItem.slug"
            :target = "groupItem.target"
            :item   = "groupItem">
              <span class="truncate">{{  groupItem.name }}</span>
              <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
          </h-nav-menu-item>
        </li>
      </ul>
      </transition>
      <!-- /Group Items -->
  </div>
</template>


<script>
// import VNavMenuItem from './VerticalNavMenuItem.vue'
import im from '@/common/images.service'
import fn from '@/common/lib.service'
import {PageApiService} from '@/common/api.service'
import HNavMenuItem from "./HorizontalNavMenuItem.vue"
import {
  mapGetters
} from 'vuex'
export default {
  name  : 'h-nav-menu-group',
  props : {
    openHover  : { type: Boolean, default: true },
    open       : { type: Boolean, default: false },
    group      : { type: Object },
    groupIndex : { type: Number },
    bottom     : { type: Boolean, default: false },
    level      : { type: Number, default: 0}
  },
  components: {
    HNavMenuItem
  },
  data: () => ({
    openItems : false,
    hovered: false,
    dropLeft: false,
    src: '',
    showloading: false,
    isLazyLoading: false
  }),
  computed: {
    ...mapGetters(['revotioAuthInfo']),
    icon () {
      if (this.group.hasOwnProperty('iconName') && (this.group.iconName !== '' && this.group.iconName !== undefined)) {
        if (this.group.iconName.includes('fa-')) {
          return fn.convertIcon2Material(this.group.iconName)
        } else return this.group.iconName
      }
      return ''
    },
    iconClasses() {
      let classes = "mr-3 "
      classes += this.groupIndex % 1 != 0 ? 'w-3 h-3' : 'w-5 h-5'
      return classes
    },
    styleItems() {
      let style = {}
      if(this.bottom) {
        style.top = "100%"
        style.left = "0"
      }else {
        style.top = "12px"
        style.left = "100%"
      }

      if(this.dropLeft) {
        style.left = null
        style.right = "100%"
      }
      return style
    },
    itemIcon() {
      // return (index) => {
      //   // if (!((index.match(/\./g) || []).length > 1)) return "CircleIcon"
      // }
      return "CircleIcon"
    },
    isGroupActive() {
      return (item) => {
        const path        = this.$route.fullPath
        let open          = false
        const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

        let func = (item) => {
          if (item.submenu) {
            item.submenu.forEach((item) => {
              if ((path == item.url || routeParent == item.slug) && item.url) { open = true}
              else if (item.submenu) { func(item) }
            })
          }
        }

        func(item)
        return open
      }
    },
    lazyload () {
      if (this.group.lazyload && this.group.inner.length <= 0) {
        this.initEmit()
        return this.group.lazyload
      }
      return null
    },
    currentLevel () {
      return parseInt(this.level) + 1
    }
  },
  watch: {
    group () {
      this.src = im.getImage(this.group.filePath)
      if (this.group.hasOwnProperty('filePath') && this.src === '') {
        this.getFile()
      }
    },
    hovered(val) {
      this.$nextTick(() => {
        if(val) {
          let dd = this.$refs.childDropdown

          if(((window.innerHeight - dd.getBoundingClientRect().top) - dd.getBoundingClientRect().height - 28) < 1) {
            const maxHeight = (window.innerHeight - dd.getBoundingClientRect().top - 70) + 50
            dd.style.maxHeight = `${maxHeight}px`
            dd.style.overflowY = "auto"
            dd.style.overflowX = "hidden"
          }

          if(dd.getBoundingClientRect().left + dd.offsetWidth - (window.innerWidth - 16) >= 0 || this.$parent.dropLeft) {
            this.dropLeft = true
          }
        }else {
          this.dropLeft = false
        }
      })
    },
    isLazyLoading() {
      if (this.$parent.hasOwnProperty('isLazyLoading')) return this.$parent.isLazyLoading = this.isLazyLoading
    }
  },
  mounted() {
    this.src = im.getImage(this.group.filePath)
    if (this.group.hasOwnProperty('filePath') &&  this.src === '') {
      this.getFile()
      // console.log(this.src)
    }
  },
  methods: {
    checkCorrectElement (target) {
      let lvl = target.classList.value.split(' ').find(i=>i.includes('lvl'))
      let lvlvalue = lvl ? lvl.split(':')[1] : 0
      if (parseInt(lvlvalue) === parseInt(this.level)) {
        return true
      }
      return false
    },
    mouseover($event) {
      let isInElement = this.checkCorrectElement($event.target)
      if (isInElement && this.level+'-'+this.hovered === this.level+'-'+false) {
        this.clickGroup()
        this.hovered = true
        if (this.openHover && this.group.inner.length > 0) {
          this.showChildren()
        }
      }
    },
    mouseout($event) {
      let isInElement = this.checkCorrectElement($event.target)
      if (this.openHover && this.level <=2 && (isInElement && !this.isLazyLoading)) {
        this.hovered = false
        this.showChildren(false)
      }
    },
    showChildren(val = true) {
      this.openItems = val
      if (val) {
        document.body.style.position = 'fixed';
        document.body.style.top = `-1px`;
      } else {
        document.body.style.position = '';
        document.body.style.top = '';
      }
    },
    getItem (ln) {
      if (ln.inner.length > 0 || ln.lazyload) {
        return 'group'
      } else {
        return 'item'
      }
    },
    initEmit () {
      this.$on('lazyLoad', data => {
        this.sendAction()
      })
    },
    async sendAction () {
      var postData = {
        gadgetType: 'Menu',
        gadgetId: 'menu',
        gadgetName: 'menu',
        gadgetData: this.group
      }
      let actiondata = {
        payload: 'Gadget',
        postData: postData,
        authInfo: this.revotioAuthInfo
      }
      this.$store.dispatch('setActionPost', actiondata)
    },
    async getFile () {
      let fileget = {
        servetype: 'file',
        path: this.group.filePath
      }
      let auth = this.$store.state.auth
      await PageApiService.serveFileV2(fileget,auth).then((response) => {
        let data = response.data
        if (!data.hasOwnProperty('error')) {
          im.addImage(data.file, data)
          this.src = im.getImage(data.file)
        }
      }).catch(error => {
        throw new Error(error)
        // TODO SHOW ERROR
      })
    },
    clickGroup() {
      if (this.lazyload) {
        this.isLazyLoading = true
        this.showloading = true
        this.$parent.isLazyLoading = true
        this.$emit('lazyLoad', {data: this.lazyload})
        this.$root.$on(this.lazyload + 'lazy', data => {
          this.showChildren(true)
          this.$parent.isLazyLoading = false
          this.showloading = false
          this.isLazyLoading = false
          this.hovered = true
        })
      }
      // if (!this.openHover) {

      //   let thisScrollHeight = this.$refs.items.scrollHeight

      //   if (this.maxHeight == '0px') {
      //     this.maxHeight = `${thisScrollHeight}px`
      //     setTimeout(() => {
      //       this.maxHeight = 'none'
      //     }, 300)

      //   } else {
      //     this.maxHeight = `${thisScrollHeight}px`
      //     setTimeout(() => {
      //       this.maxHeight = `${0}px`
      //     }, 50)
      //   }

      //   this.$parent.$children.map((child) => {
      //     if (child.isGroupActive) {
      //       if (child !== this && (!child.open) && child.maxHeight != '0px') {
      //         setTimeout(() => {
      //           child.maxHeight = `${0}px`
      //         }, 50)
      //       }
      //     }
      //   })
      // }
    }
  }
}

</script>

<style lang="scss">
@use "@/assets/scss/vuexy/components/horizontalNavMenuGroup" as *;
</style>
