<template>
  <Page/>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TokenService from '@/common/token.service'
import fn from '@/common/lib.service'
import Page from './Page.vue'
import {API_CONFIG} from '@/common/configs'
import ThemeService from '@/common/theme.service'
import _color from '@/assets/utils/color.js'
import ApiService from '@/common/api.service'
export default {
  name: 'Main',
  mounted () {
    // add the watchers. watch is not working so will use vuex
    // changes on device id
    // document.getElementsByTagName('title')[0].innerText = API_CONFIG.appName
    document.getElementById('favicon').href = API_CONFIG.favIcon || 'static/favicon.ico'
    this.$store.watch(
      (state, getters) => getters.revotioDeviceID,
      (newValue, oldValue) => {
        if (API_CONFIG.baseUrl !== 'API_URL')
          this.handleController(newValue)
        else  this.$store.dispatch('mockData', newValue)
      }
    )
    // changes on token
    this.$store.watch(
      (state, getters) => getters.revotioTokenisSet,
      (newValue, oldValue) => {
        if (newValue === true) {
          this.$store.dispatch('validateToken', TokenService.getToken())
        }
      }
    )
    const publicWork = async () => {
      let routerparam = fn.routerParams( this.$router)
      let {ap,pageName,backendApi,source,primary,button,publisherType, data} = routerparam
      publisherType = publisherType || API_CONFIG.publisherType || 'private'
      if (source && source === 'wp') {
        import("@/assets/styles/w-press.scss")
      }
      if (primary) {
        primary = primary.replace(';','#')
        primary = _color.getColor(fn.colorTextToHex(primary)) || primary 
        primary = primary.replace('rgb(','').replace(')','')
        this.$vs.theme({ primary});
        document.documentElement.style.setProperty('--vs-primary', primary);
      }
      if (button) {
        button = button.replace(';','#')
        button = _color.getColor(fn.colorTextToHex(button)).replace('rgb(','').replace(')','')
        document.documentElement.style.setProperty('--vs-button', button);
      }
      if (publisherType === 'public') {
        window.onbeforeunload = null
        const auth = this.$store.state.auth || {}
        let browserFingerPrint = auth.browserFingerPrint
        let revotioSessionId = auth.revotioSessionId
        let deviceId = auth.deviceId
        
        const doPages = (pages) => {
          let page = pages.pages ? pages.pages[0] : null
          let gadgetLists = pages.gadgetLists || null
          if (page) {
            if (!page.groupedObject.hasOwnProperty('INIT')) {
            page.groupedObject.INIT = []
            }
            page = {Page:pageName,data:data, applicationId:ap,type:'Redirect',presentationLayer:page}
            this.$store.commit('setPageData', page)
            let key = this.$store.state.auth.uid ? atob(this.$store.state.auth.uid) : '' 
            page.uid=btoa(key)
            // lets work with dummy data for now
            // let watsingadget = page.presentationLayer.content.container[0].content[0]
            // let gadgetData = fn.fetchDummdyData('gadgets',watsingadget.type.replace('-',''),watsingadget.id)
            // console.log(gadgetData)
            // let gadgetData =  await exBackend({},backendApi)
            if (gadgetLists) {
              this.$store.commit('setGadgetData', gadgetData)
            } else {
              this.$store.dispatch('directPost', backendApi) 
            }
          }
          else {
            const dispatchObj = {backendApi, method: 'get',publisherType, data, pageName, applicationId:ap}
            this.$store.dispatch('directPost', dispatchObj) 
          }
        }
        /*
        // this.$store.dispatch('directPost', 'https://api.innonation.nl/pages')
        ApiService.directPost('https://api.innonation.nl/pages',
        {applicationId:ap,pageName,data, browserFingerPrint,revotioSessionId, deviceId},'get')
        .then((data)=>{
          // doPages(pages)
        })
        */
        // let pages = await fn.pageApp( {ap,pageName,data, browserFingerPrint,revotioSessionId, deviceId})
        doPages({})
      }
    }

    // publicWork ()
    this.$store.watch (
      (state,getters) => getters.revotioSessionId,
      async (newv, oldv) => {
        if (!oldv && newv) {
          publicWork()
        }
      }
    )
    // changes on token state (valid/invalid)
    this.$store.watch (
      (state, getters) => getters.revotioTokenState,
      async (newValue, oldValue) => {
        if (newValue === 'valid') {
          let postData = this.getPostData()
          const route = this.$route
          if (route.name === 'flexiendpoint') {
            const params = route.params && route.params.flexiendpoint ? route.params.flexiendpoint : '' 
            postData.endpoint = params
            postData = {...postData,...route.query}
            window.history.replaceState({},document.title,'/')
            delete postData.route
            this.$store.dispatch('flexiPost', {postData: postData, authInfo: this.revotioAuthInfo})
          } else {
            this.$store.dispatch('entrancePost', {postData: postData, authInfo: this.revotioAuthInfo})
          }
          // this.$Progress.finish()
        } else if (newValue === 'invalid') {
          // todo
        }
      }
    )
    let store = this.$store
    window.addEventListener('popstate', function(event) {
      const location = event.location || {}
      const url = location.href || ''
      store.dispatch('moveHistory',{title:document.title, state:event.state, url:url})
    }, false);

  },
  computed: {
    ...mapGetters(['revotioFingerPrint', 'revotioDeviceID', 'revotioAuthInfo', 'revotioGadgetData','currentUrl']),
    ...mapState(['browserFingerPrint', 'tokenIsSet'])
  },
  methods: {
    handleController (deviceID) {
      let hasToken = !!TokenService.getToken()
      let dispatchData = {
        deviceID: deviceID,
        revotioSessionId: this.$store.getters.revotioSessionId
      }
      let publisherType = fn.routerParams( this.$router).publisherType
      publisherType = publisherType || API_CONFIG.publisherType || 'private'
      if (publisherType === 'public')  return
      // check if token already exists if not get the token
      if (!hasToken) {
        this.$store.dispatch('getTokenPost', dispatchData)
      } else {
        let postData = this.getPostData()
        // this.$store.dispatch('getTokenPost', dispatchData)
        this.$store.dispatch('validateToken',TokenService.getToken() )
        // this.$store.dispatch('getTokenPost', dispatchData)
        // this.$store.dispatch('entrancePost', {postData: postData, authInfo: this.revotioAuthInfo})
      }
    },
    getPostData () {
      let param = TokenService.getPathParam()
      // prepare the data
      let postData = {
        Type: 'authorized',
        ClientFP: this.revotioFingerPrint
      }
      if (param === 'activation=') {
        postData.activationToken = TokenService.getParamValue('activation')
        postData.entranceType = 'activate'
      } else if (param === 'reset=') {
        postData.resetToken = TokenService.getParamValue('reset')
        postData.entranceType = 'reset'
      } else if (param === 'partner=') {
        postData.partnerToken = TokenService.getParamValue('partner')
        postData.entranceType = 'partner'
      }  else if (param === 'i=') { 
        postData.pageSessionId = TokenService.getParamValue('i')
        postData.entranceType = 'pageSession'
      } else if (window.location.pathname !== '/') {
        postData.entranceType = 'route'
        postData.route = window.location.pathname
      } else {
        postData.entranceType = 'extendedRoute'
        postData.route = window.location.pathname
        postData.routeParams = window.location.search
      }
      // window.history.replaceState({},document.title,'/')
      return postData
    }
  },
  components: {
    Page
  }
}
</script>
