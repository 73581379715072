var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.vueAppClasses, attrs: { id: "app" } },
    [
      _vm.progressType === "type2" && _vm.pageProgress
        ? _c("vs-progress", {
            staticClass: "maiprogress",
            attrs: { indeterminate: "", color: "rgb(204 204 204 / 80%)" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.progressType === "type1" && _vm.pageProgress
        ? _c("vue-progress-bar")
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", { on: { setAppClasses: _vm.setAppClasses } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }