<template>
<img :src="'static/'+logoFileName" width="40px" class="mr-1"/> 
</template>
<script>
import { API_CONFIG } from '@/common/configs'
export default {
  name: 'applogo',
  data () {
    return {
      logoFileName : (API_CONFIG.logoFileName !== 'APP_LOGO') ? API_CONFIG.logoFileName : 'favicon.ico'
    }
  }
}
</script>