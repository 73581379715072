var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout--full-page" }, [
    _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
      _c(
        "div",
        {
          staticClass:
            "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
        },
        [
          _vm.downloadLink === "waiting"
            ? [
                _c("svg", { staticClass: "w-0" }, [
                  _c("defs", [
                    _c(
                      "filter",
                      { attrs: { id: "w-0" } },
                      [
                        _c("feGaussianBlur", {
                          attrs: {
                            in: "SourceGraphic",
                            stdDeviation: "7",
                            result: "blur",
                          },
                        }),
                        _vm._v(" "),
                        _c("feColorMatrix", {
                          attrs: {
                            in: "blur",
                            mode: "matrix",
                            values:
                              "1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -10",
                            result: "res",
                          },
                        }),
                        _vm._v(" "),
                        _c("feComposite", {
                          attrs: {
                            in: "SourceGraphic",
                            in2: "res",
                            operator: "atop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "f-w-0",
                    attrs: {
                      width: "200",
                      height: "200",
                      viewBox: "0 0 200 200",
                    },
                  },
                  [
                    _c(
                      "defs",
                      [
                        _c(
                          "linearGradient",
                          { attrs: { id: "linear-gradient" } },
                          [
                            _c("stop", {
                              staticClass: "stop1",
                              attrs: { offset: "0" },
                            }),
                            _vm._v(" "),
                            _c("stop", {
                              staticClass: "stop2",
                              attrs: { offset: "1" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("linearGradient", {
                          attrs: {
                            y2: "160",
                            x2: "160",
                            y1: "40",
                            x1: "40",
                            gradientUnits: "userSpaceOnUse",
                            id: "gradient",
                            "xlink:href": "#linear-gradient",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "path-class",
                      attrs: {
                        d: "m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776\n                     -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64\n                     0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736\n                     -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317\n                     64,-64 64,-64",
                      },
                    }),
                    _vm._v(" "),
                    _c("circle", {
                      staticClass: "cricle-class",
                      attrs: { cx: "100", cy: "100", r: "64" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "svg-class-1",
                    attrs: {
                      width: "200",
                      height: "200",
                      viewBox: "0 0 200 200",
                    },
                  },
                  [
                    _c("path", {
                      staticClass: "path-class",
                      attrs: {
                        d: "m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776\n                     -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64\n                     0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736\n                     -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64\n                     64,-64",
                      },
                    }),
                    _vm._v(" "),
                    _c("circle", {
                      staticClass: "cricle-class",
                      attrs: { cx: "100", cy: "100", r: "64" },
                    }),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.downloadLink === "success"
            ? [
                _c("img", {
                  staticClass: "mx-auto mb-4 w-5/6",
                  attrs: {
                    src: require("@/assets/images/elements/downloadsucces2.png"),
                    alt: "graphic-500",
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("img", {
                staticClass: "mx-auto mb-4 w-5/6",
                attrs: {
                  src: require("@/assets/images/elements/waitingdownload-min.jpg"),
                  alt: "graphic-500",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.downloadLink === "error"
            ? _c("img", {
                staticClass: "mx-auto mb-4 w-5/6",
                attrs: {
                  src: require("@/assets/images/elements/error-min.jpg"),
                  alt: "graphic-500",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticClass:
                "sm:mx-0 mx-4 mb-4 sm:mb-12 text-5xl d-theme-heading-color",
            },
            [_vm._v(_vm._s(_vm.message))]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }