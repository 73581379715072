import fn from '@/common/lib.service'
let ImageData = {}
let ImageTypes = {}
const ImageService = {
  addImage (name, data) {
    if (data.base64) {
      ImageData[name] = 'data:' + data.fileType + ';' + 'base64,' + data.base64
      let fileName = fn.getFileName(name)
      ImageService.addImageTypes(fileName,data.fileType);
    }
  },
  allImages () {
    return ImageData
  },
  addImageTypes (name, fileType) {
    ImageTypes[name] = fileType
  },
  getImageType (name) {
    if (ImageTypes.hasOwnProperty(name)) {
      return ImageTypes[name]
    }
    return ''
  },
  getImage (name) {
    if (ImageData.hasOwnProperty(name)) {
      return ImageData[name]
    }
    return ''
  }
}
export default ImageService
