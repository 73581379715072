<template lang="html">
    <div class="preloader" v-if="$store.state.progressType === 'type1'" >
      <vs-progress indeterminate color="primary" class="smallx blockx ":height="2" >primary</vs-progress>
      <vs-progress indeterminate color="primary" class="mediumx blockx mt-m mb-m mb-s" :height="2" >primary</vs-progress>
  </div>
   <div class="flex w-full" v-else>
      <div class="w-full bg-grey-light h-39 33.33"></div>
    </div>
</template>

<script>
export default {
 name: 'gadgetLoading'
}
</script>
