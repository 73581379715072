var render = function render(_c, _vm) {
  return _c(
    "ul",
    { staticClass: "list" },
    _vm._l(_vm.props.list, function (item, index) {
      return _c(
        "li",
        { key: index, staticClass: "list__item" },
        [
          _c("feather-icon", {
            staticClass: "w-5 h-5 mr-1",
            attrs: { icon: _vm.props.icon },
          }),
          _c("span", { domProps: { innerHTML: _vm._s(item) } }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }