<template>
  <div class="item" :id="'item'+layerData.id" v-bind:itemid="layerData.id" data-itemid="1" data-type="item" :class="`g_${gadgetContent[0].type}`"
  v-bind:style="gadgetStyles"  :ref="layerData.id" v-if="renderContainer" >
    <!-- <div class="gadget" v-for="item in layerData.content" v-bind:key="item.id"> -->

    <template class="preloader" v-if="!renderComponent && !seen">
      <div class="preloader flex flex-wrap" v-if="progressType==='type1'">
        <vs-progress indeterminate color="primary" class="w-5/12 m-2 my-4" :height="2">primary</vs-progress>
        <vs-progress indeterminate color="primary" class="w-full m-2" :height="2">primary</vs-progress>
      </div>
      <template v-if="progressType==='type2'">
      <!-- <loadertemplate :layoutType="gadgetContent[0].type" :id="gadgetContent[0].id" :itemData="gadgetContent[0]"
          :gIndex="gIndex" />-->
      </template>
    </template>
    <!--<component is="chat" :itemData="{id:'xx'}"  v-if="layerData.id === '1702118e-cb44-e403-d680-0815707efedb'"/>
    -->
    <template v-if="renderComponent && seen">
      <component :is="item.type"
      :gadget="gadget"
      :gadget-name="item.data.name || ''"
      :gadget-type="item.data.type || item.type"
      v-for="item in gadgetContent" v-bind:key="item.id + '-' + reinitialize" v-bind:itemData.sync="item"
      :gid="item.id"
      :columns="item.columns"
      :gIndex="gIndex" ref="gadget"
      :renderLocation="renderLocation"
      :renderlocId="renderlocId"
      :renderFrom="renderFrom">
      </component>
    </template>
    <!-- </div> -->
    <!-- render the gadgets -->
  </div>
</template>

<script>
import fn from '@/common/lib.service'
import TokenService from '@/common/token.service'
import { mapGetters } from 'vuex'
import * as gadgetcomponents from '@/components/gadgets/importgadgets.js'
const loadertemplate = () => ({
    component: import ('./loadertemplate'),
    timeout: 3000
})
let components = {...gadgetcomponents,loadertemplate}
import Vue from 'vue'
export default {
  name: 'item',
  computed: {
    // a computed getter
    ...mapGetters(['revotioPageData','revotioGadgetData']),
    itemName () {
      return (type) => {
        if (type.includes('revotio-')) type = type.replace('revotio-','')
        if (type.includes('revotio')) type = type.replace('revotio','')
        if (type.includes('tabsselect')) type = 'tabs-select'
        return type
      }
    },
    gadgetStyles: function () {
      return fn.pageMergeDesign(this.revotioPageData, this.layerData)
    },
    gadgetContent: function () {
      let newGadgets = []
      for (const content in this.layerData.content) {
        let info = this.layerData.content[content]
        if (info.type.includes('revotio-')) info.type = info.type.replace('revotio-','')
        if (info.type.includes('revotio')) info.type = info.type.replace('revotio','')
        if (info.type.includes('tabsselect')) info.type = 'tabs-select'
        let columns =  (info.type === 'maps') ? info.columns : null
        newGadgets.push({
          type: info.type.split('-').join(''),
          data: info,
          id: info.id,
          gIndex: this.gIndex,
          columns,
          open:info.open
        })
        // info.type = info.type.split('-').join('')
        // info.data = info
        // info.gIndex = this.gIndex
      }
      return newGadgets
    },
    newclass:  function () {
      for (let i in this.siblings.sibs) {
        let sib = this.siblings.sibs[i]
        if (this.layerData.id === sib.id){
          return sib.class
        }
      }
    },
    windowWidth() { return this.$store.state.windowWidth },
    progressType ()  { return this.$store.state.progressType  },
    show: function () {
      return true
    },
    cacheGadget: function () {
      const gadgets = TokenService.getGadgets()[this.gadgetContent[0].id]
      if (gadgets) {
        return gadgets
      } return {}
    }
  },
  mounted () {
    this.getComponentClasses()
    this.$on('hidden',data => {
      this.$parent.$emit('hidden',{item:data,layer:this.layerData.id});
    })
    let form = this.getComponent('form')
    if (!this.gIndex) this.inGadget()
    this.observer.observe(this.$el);
    let gContent = this.gadgets && this.gadgetContent[0] ? this.gadgets[this.gadgetContent[0].id] : null
    this.gadget = this.gadget ? this.gadget : gContent ? gContent : null
  },
  watch: {
    windowWidth () {
      this.getComponentClasses()
    }, 
    revotioGadgetData () {
      if (!this.gIndex) this.inGadget()
      this.$nextTick(()=> {
        if (!this.seen) {
          this.observer.unobserve(this.$el)
          this.observer.observe(this.$el)
        }
      })
    },
    revotioPageData () {
      this.$destroy()
    },
    seen (newval, oldval) {
      if(!oldval && newval && process.env.NODE_ENV === 'production') {
        console.clear()
      }
      // console.log(newval, oldval, (this.gadget ? this.gadget.gadgetType : ''))
      if (newval && !this.gIndex) {
        this.inGadget()
      }
    },
    gadgetLists () {
      this.setGadget()
      this.reinitialize++
    }
  },
  methods: {
    getComponentClasses: function (item) {
      // small
      fn.sibsGridClasses (this.gadgetContent,  'item')
      for (let i in this.gadgetContent) {
        let item = this.gadgetContent[i]
        item.class = fn.gridClasses (1,  'item', item.width)
      }
    },
    getComponent (gId) {
      for (let i in this.gadgetContent) {
        let component =  this.gadgetContent[i]
        if (gId === 'form' && component.data.type === 'custom-form') {
          return component
        } else if (component.id === gId) {
          return component
        }
      }
      return null
    },
    inGadget () {
      if (!this.seen) return
      if (this.gadgetLists && this.gadget) {
        this.renderComponent = true
        this.renderContainer = true
        return
      }
      let gadgetsList = this.revotioGadgetData && this.revotioGadgetData.gadgetsList ? JSON.parse(JSON.stringify(this.revotioGadgetData.gadgetsList)) : null
      if (this.revotioGadgetData.timeReceived) {
        // console.log(this.revotioGadgetData.timeReceived,this.revotioGadgetData)
        gadgetsList = JSON.parse(JSON.stringify(gadgetsList)).filter(i=>i.timeReceived ===  this.revotioGadgetData.timeReceived)
        const old = gadgetsList.find(i=>i.gadgetId === (this.gadgetContent[0].gadgetId || this.gadgetContent[0].id ))
        if(!old) {
          this.renderComponent = true
          this.renderContainer = true
          return
        }
      }
      if (this.gIndex) {
        this.renderComponent = false
      }
      if (!gadgetsList) {
        this.renderContainer =  true
        return
      }
      if (gadgetsList.length === 0 ) {
        this.renderContainer = true 
        return 
      }
      this.gadgetContent.forEach(item => {
        let gadgetInfo = fn.getGadgetInfo(gadgetsList, item.id || item.gadgetId, this.gIndex)
        let infoLength = Object.keys(gadgetInfo).length
        let isMenuOnly = (gadgetsList.length === 1 && gadgetsList[0].gadgetId=='menu')
        if (isMenuOnly) return
        this.gadget = infoLength > 0 ? gadgetInfo : null
        if (this.renderComponent === true && infoLength > 0 && !['revotio-calendar','calendar'].includes(gadgetInfo.gadgetType)) {
          this.reinitialize++
          // this.$destroy()
          return
        }
        if (infoLength > 0) {
          this.renderComponent = true
        }
        if (this.renderComponent === false && infoLength === 0 && !isMenuOnly) {
          this.renderContainer = false 
        } else this.renderContainer = true
      })
      // for (let i in this.gadgetContent) {
      //   let item = this.gadgetContent[i]
        
      // }

      // for (let i in this.gadgetContent) {
      //   let item = this.gadgetContent[i]
      //   this.gadgetInfo = fn.getGadgetInfo(this.revotioGadgetData.gadgetsList, item.id, this.gIndex)
      //   if (Object.keys(this.gadgetInfo)) {
      //     if (this.gadgetInfo.gadgetType === 'page-builder') {
      //       setTimeout((this.renderComponent = true), 10000)
      //     } 
      //     else this.renderComponent = true
      //   }
      //   else this.renderContainer =  false
      // }
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting}) => {
          if (!isIntersecting) {
            return;
          }
          this.observer.unobserve(target);
          this.seen = true
      });
    },
    setGadget () {
      if (!this.gadgetLists) return
        const gadgetLists = this.gadgetLists || []
        const item = this.gadgetContent[0]
        this.gadget = gadgetLists.find(i=>{ 
          const gIndex = this.gIndex !== undefined ? i.gIndex === this.gIndex : true 
          const gadgetId = i.gadgetId === item.id
          return gadgetId && gIndex})

        this.renderComponent = this.gadget ? true : false
        this.renderContainer = this.gadget ? true : true      
    }
  },
  beforeDestroy () {
    this.$off('hidden');
    this.observer.disconnect();
  },
  beforeMount () {
    this.setGadget()
  },
  components,
  data () {
    return {
      componentClasses: {},
      reinitialize: 0,
      renderComponent: this.gIndex ? true: false,
      renderContainer: true,
      observer: null,
      seen:this.isModal ?  true : false,
      gadget: null
    }
  },
  created() {
    this.observer = new IntersectionObserver(
      this.onElementObserved, 
      {
        root: this.$el,
        threshold: 0.3,
      }
    );
  },
  props: {
    layerData: Object,
    gIndex: [Number, String],
    colNum: { type: Number, default: 0 },
    siblings: Object,
    isModal: { type: Boolean, default: false},
    renderFrom: {type: String, default:'main'},
    renderLocation: {type: String, default:'container'},
    gadgetLists:{type: [Object,Array]},
    renderlocId: {type: String, default:''},
    gadgets: {type: [Object,Array]}
  }
}
</script>
