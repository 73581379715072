/*=========================================================================================
  File Name: auth.module.js
  Description:  this module is where the states needed to send and get data from the payload
  ----------------------------------------------------------------------------------------
==========================================================================================*/
import AuthService from '@/common/auth.service'
import {
  TokenApiService,
  PageApiService
} from '@/common/api.service'
import {API_CONFIG} from '@/common/configs'
import {
  JWTApiService
} from '@/common/jwt.service'
import fn from '@/common/lib.service'
import TokenService from '@/common/token.service'
import ThemeService from '@/common/theme.service'
import router from '@/router/appRouter'
// import Vuex from 'vuex'
import Vue from 'vue'

const state = {
  browserFingerPrint: {},
  deviceId: [],
  revotioSessionId: '',
  tokenIsSet: false,
  tokenState: 'invalid',
  errors: null,
  tokenData: {},
  pageData: {},
  gadgetData: {},
  navObj:{},
  exitPost: false,
  uid: API_CONFIG.appDefaultToken !== 'API_KEY' ? btoa(API_CONFIG.appDefaultToken) : '',
  currentUrl: '',
  baseApi:{}
}
// todo move the pageData and gadgetData to different module
const getters = {
  revotioFingerPrint(state) {
    return state.browserFingerPrint
  },
  revotioDeviceID(state) {
    return state.deviceId
  },
  revotioSessionId(state) {
    return state.revotioSessionId
  },
  revotioTokenisSet(state) {
    return state.tokenIsSet
  },
  revotioTokenState(state) {
    return state.tokenState
  },
  revotioTokenInfo(state) {
    return state.tokenData
  },
  revotioAuthInfo(state) {
    return state
  },
  revotioGadgetData(state) {
    return state.gadgetData
  },
  revotioPageData(state) {
    return state.pageData
  },
  currentUrl(state) {
    return state.currentUrl
  },
  baseApi(state) {
    return state.baseApi
  }
}
const actions = {
  async checkAuthInfo(context, query) {
    AuthService.setAuthInfo().then( async (info) =>  {
      context.commit('setAuthInfo', info)
    }).catch((response) => {
      context.commit('setError', response)
    })
  },
  async directPost (context, response) {
    try {
      let backendApi = typeof response === 'string' ? response : response.backendApi
      let newInfoData = {...context.getters.revotioAuthInfo,baseApi:context.getters.baseApi }
      const method = response.method || 'post'
      const publisherType = response.publisherType || 'private'
      if (typeof response === 'object') {
        delete response.method
        delete response.backendApi
        newInfoData = {...newInfoData, ...response}
      }
      await PageApiService.directPost(backendApi,newInfoData,method).then(({
        data
      }) => {
        let response = data
        const actionData = (data) => {
          if (window.location.pathname !== '/') {
            // window.history.replaceState(window.location.pathname, "", "/")
          }
          if (data.Type === 'Redirect' || data.hasOwnProperty('presentationLayer')) {
            if (!data.Page) data.Page = ''
            // if (publisherType === 'private') window.history.replaceState({},document.title,'/')
            if (data.presentationLayer && data.presentationLayer.api) {
              context.commit('setBaseApi', data.presentationLayer.api)
            } else {
              context.commit('setBaseApi',{})
            }
            // window.history.replaceState(window.location.pathname, "", "/");
            context.commit('setPageData', data)

            let menu = state.gadgetData.gadgetsList ? state.gadgetData.gadgetsList.filter(i => i.gadgetName === 'menu') : null
            let gadgetData = {
              gadgetsList: []
            }
            if (menu && menu.length === 1) {
              gadgetData.gadgetsList.push(menu[0])
            }
            if (data.hasOwnProperty('gadgetsList')) {
              const gadget = {gadgetsList:data.gadgetsList}
              context.commit('setGadgetData', gadget)
            } else {
              context.commit('setGadgetData', gadgetData)
              context.dispatch('initializePost', data)
            }
          } else if (data.hasOwnProperty('gadgetsList')) {
            context.commit('setGadgetData',data)
          } else if (data.hasOwnProperty('GadgetsList')) {
            context.commit('setGadgetData', data)
          } else if (data.url && data.url.length) {
            window.open(data.url, '_blank')
            progress.finish()
          } else if (data.hasOwnProperty('RevotioData') && data.RevotioData['logout'] === true) {
            TokenService.destroyToken(true)
          }

        }
        const curentState = context.state
        let key = curentState.uid ? atob(curentState.uid) : '' 
        if (API_CONFIG.appDefaultToken !== 'API_KEY' && data.data) {
          const decrypt = JWTApiService.decrypt(data.data, key)
          actionData({...decrypt.body,uid:btoa(key)})
        } else if (response.token && data.data) {
          
          context.dispatch('newTokenV1',response).then(res=> {
            key = res.data.key || key
            const decrypt = JWTApiService.decrypt(data.data, key)
            actionData({...decrypt.body,uid:btoa(key)})
          })
        } else {
          if (!data.data) {
            actionData({...data,uid:btoa(key)})
          }else {
            const decrypt = JWTApiService.decrypt(data.data, key)
            actionData({...decrypt.body,uid:btoa(key)})
          }
        }
        // progress.finish()
      }).catch(error => {
       console.log(error)
      })
    } catch (err) {
      console.log(err)
    }
  },
  async initializePost(context, response) {
    let progress = Vue.prototype.$Progress
    try {
      response = {...response,deviceId:context.state.deviceId, revotioSessionId:context.state.revotioSessionId,baseApi:context.getters.baseApi }
      if(!response.token) {
        response.token = TokenService.getToken()
      }
      await PageApiService.initialize({...response}).then(({
        data
      }) => {
        let response = data
        const actionData = (data) => {
          // camelClase DeviceId and RevotioSessionId
          if (data.hasOwnProperty('DeviceId')) {
            data.deviceId = data.DeviceId
            delete data.DeviceId
          }
          // camelClase DeviceId and RevotioSessionID
          if (data.hasOwnProperty('RevotioSessionId')) {
            data.revotioSessionId = data.RevotioSessionId
            delete data.RevotioSessionId
          }
          if (response.hasOwnProperty('pageName')) {
            // window.history.replaceState({},document.title,'/')
            const pagesessionId = TokenService.getParamValue('i')
            let isexport = window.location.href.includes('/export') ? true : false
            const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
            const historystate =  pagesessionId ? `/i=${pagesessionId}${isexport ? exparam : '/'}` : `${isexport ? exparam : '/'}`
            window.history.replaceState(window.location.pathname,response.pageName, historystate)
            document.title = response.pageName
          }
          if (window.location.pathname !== '/') {
            // window.history.replaceState(window.location.pathname, "", "/")
          }
          if (data.Type === 'Redirect') {
            // window.history.replaceState(window.location.pathname, "", "/");
            context.commit('setPageData', data)

            let menu = state.gadgetData.gadgetsList ? state.gadgetData.gadgetsList.filter(i => i.gadgetName === 'menu') : null
            let gadgetData = {
              gadgetsList: []
            }
            if (menu && menu.length === 1) {
              gadgetData.gadgetsList.push(menu[0])
            }
            if (data.hasOwnProperty('gadgetsList')) {
              const gadget = {gadgetsList:data.gadgetsList}
              context.commit('setGadgetData', gadget)
            } else {
              context.commit('setGadgetData', gadgetData)
              context.dispatch('initializePost', data)
            }
          } else if (data.hasOwnProperty('gadgetsList')) {
            context.commit('setGadgetData',data)
          } else if (data.hasOwnProperty('GadgetsList')) {
            context.commit('setGadgetData', data)
          } else if (data.url && data.url.length) {
            window.open(data.url, '_blank')
            progress.finish()
          } else if (data.hasOwnProperty('RevotioData') && data.RevotioData['logout'] === true) {
            TokenService.destroyToken(true)
          }

        }

        const curentState = context.state
        let key = curentState.uid ? atob(curentState.uid) : '' 
        if (API_CONFIG.appDefaultToken !== 'API_KEY' && data.data) {
          const decrypt = JWTApiService.decrypt(data.data, key)
          actionData({...decrypt.body,uid:btoa(key)})
        } else if (response.token && data.data) {
          
          context.dispatch('newTokenV1',response).then(res=> {
            key = res.data.key || key
            const decrypt = JWTApiService.decrypt(data.data, key)
            actionData({...decrypt.body,uid:btoa(key)})
          })
        } else {
          if (!data.data) {
            actionData({...data,uid:btoa(key)})
          }else {
            const decrypt = JWTApiService.decrypt(data.data, key)
            actionData({...decrypt.body,uid:btoa(key)})
          }
        }
        // progress.finish()
      }).catch(error => {
        context.dispatch('actSetError', {
          error: error,
          postData: response,
          redirect: true
        })
        // TODO SHOW ERROR
      })
    } catch (err) {
      context.dispatch('actSetError', {
        error: err,
        postData: response,
        redirect: true
      })
    }
  },
  async validateToken(context, token) {
    try {
      await TokenApiService.validate(token).then(({
        data
      }) => {
       
        if (data.tokenState === 'invalid') {
          context.dispatch('getTokenPost', {
            deviceID: state.deviceId,
            revotioSessionId: state.RevotioSessionId
          })

        } else {
          context.commit('setTokenStatus', data.tokenState)
          context.commit('setTokenInfo', data.tokenContent)
          if (data.key && API_CONFIG.appDefaultToken === 'API_KEY') {
            context.commit('setUid',data.key)
          } else {
            context.commit('setUid',API_CONFIG.appDefaultToken)
          }
        }
      }).catch(error => {
        context.dispatch('actSetError', {
          error: error,
          postData: token,
          redirect: true
        })
        TokenService.destroyToken(true)
        // TODO SHOW ERROR
      })
    } catch (err) {
      context.dispatch('actSetError', {
        error: err,
        postData: token,
        redirect: true
      })
    }
  },
  async getKey (context, infoData) {
    TokenApiService.key({
      deviceID: infoData.deviceId,
      revotioSessionId: infoData.revotioSessionId
    }).then(i=> {
      const  {key} = i
      if (key) context.commit('setUid', key)
    })
  },
  async getKeyV1 (context, infoData) {
    return TokenApiService.key({
      deviceID: infoData.deviceId,
      revotioSessionId: infoData.revotioSessionId
    }).then(i=> {
      const  {key} = i.data
      if (key) context.commit('setUid', key)
      return i
    })
  },
  async entrancePost(context, infoData) {
    const preserveInfoData = JSON.parse(JSON.stringify(infoData))
    const newInfoData = {...infoData,authInfo:context.getters.revotioAuthInfo,baseApi:context.getters.baseApi}
    try {
      await PageApiService.entrance(newInfoData).then(({
        data
      }) => {
        // set page/gadgetdata
        let response = data
        const actionData = (data) => {
          let isexport = window.location.href.includes('/export') ? true : false
          const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
          const pagesessionId = response.pageSessionId
          const historystate =  pagesessionId ? `/i=${pagesessionId}${isexport ? exparam : '/'}` : `${isexport ? exparam : '/'}`
          if (response.hasOwnProperty('pageSessionId') && !data.pageObject && !data.gadgetsList) {
            if (preserveInfoData.postData.entranceType !='pageSession') {
              window.history.pushState(historystate, response.pageSessionId, historystate)	 // pagesessionhidden
            }
              // document.title = response.pageSessionId
            context.commit('setUrl',response.pageSessionId)
          }
          if (response.hasOwnProperty('pageName')) {
            window.history.replaceState(window.location.pathname,response.pageName, historystate)
            document.title = response.pageName
            // document.getElementsByTagName('title')[0].innerText = &#65279;
          }
          if (data.pageObject) {
            if (data.pageObject && data.pageObject.presentationLayer.api) {
              context.commit('setBaseApi', data.pageObject.presentationLayer.api)
            } else {
              context.commit('setBaseApi',{})
            }
            if (!data.gadgetsList) {	
              // response.pageObject.revotioSessionId = state.revotioSessionId
              // response.pageObject.token = TokenService.getToken()
              // response.pageObject.deviceId = state.deviceId
              context.commit('setPageData', data.pageObject)
              let key = state.uid ? atob(state.uid) : '' 
              data.pageObject.uid=btoa(key)
              if (data.pageObject.gadgetsList) {
                context.commit('setGadgetData', {gadgetsList:data.pageObject.gadgetsList})	
              } else {
                context.dispatch('initializePost', data.pageObject)
              }
              
            } else {	
              context.commit('setPageData', data.pageObject)	
              context.commit('setGadgetData', {gadgetsList:data.gadgetsList})	
            }
            return
          }	

          // camelClase DeviceId and RevotioSessionId
          if (data.hasOwnProperty('DeviceId')) {
            data.deviceId = data.DeviceId
            delete data.DeviceId
          }
          // camelClase DeviceId and RevotioSessionID
          if (data.hasOwnProperty('RevotioSessionId')) {
            data.revotioSessionId = data.RevotioSessionId
            delete data.RevotioSessionId
          }

          if (data.presentationLayer && data.presentationLayer.api) {
            context.commit('setBaseApi', data.presentationLayer.api)
          } else {
            context.commit('setBaseApi',{})
          }
          if (data.gadgetsList) {
            context.commit('setGadgetData', {gadgetsList:data.gadgetsList})	
          } else {
            context.dispatch('initializePost', data)
          }
          if (data.hasOwnProperty('presentationLayer')) {
            if (data.presentationLayer.api) {
              context.commit('setBaseApi', data.presentationLayer.api)
            } else {
              context.commit('setBaseApi',{})
            }
            context.commit('setPageData', data)
          } else if (data.hasOwnProperty('gadgetsList')) {
            context.commit('setGadgetData', data)
          } else if (data.hasOwnProperty('GadgetsList')) {
            context.commit('setGadgetData', data)
          }
        }
        const curentState = context.state
        let key = curentState.uid ? atob(curentState.uid) : '' 
        if (API_CONFIG.appDefaultToken !== 'API_KEY' && data.data) {
          const decrypt = JWTApiService.decrypt(data.data, key)
          actionData({...decrypt.body,uid:btoa(key)})
        } else if (response.token && data.data) {
          context.dispatch('newTokenV1',response).then(res=> {
            key = res.data.key || key
            const decrypt = JWTApiService.decrypt(data.data, key)
            actionData({...decrypt.body,uid:btoa(key)})
          })
        } else if(data.data) {
          let decrypt = JWTApiService.decrypt(data.data, key).body
          actionData({...decrypt,uid:btoa(key)})
        } else if (data.RevotioData.logout){
          TokenService.destroyToken()
          let isexport = window.location.href.includes('/export') ? true : false
          const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
          const historystate = isexport ? exparam : '/'
          window.history.replaceState(window.location.pathname, "", historystate)
          location.reload()
        }
      }).catch(error => {
        context.dispatch('actSetError', {
          error: error,
          postData: infoData,
          redirect: true
        })
        // TODO SHOW ERROR
      })
    } catch (err) {
      context.dispatch('actSetError', {
        error: err,
        postData: infoData,
        redirect: true
      })
    }
  },
  async flexiPost (context, infoData) {
    const newInfoData = {...infoData,authInfo:context.getters.revotioAuthInfo,baseApi:context.getters.baseApi}
    try {
      await PageApiService.flexiRequest(newInfoData).then(({
        data
      }) => {
        // set page/gadgetdata
        let response = data
        const actionData = (data) => {
          let isexport = window.location.href.includes('/export') ? true : false
          const pagesessionId = response.pageSessionId
          const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
          const historystate =  pagesessionId ? `/i=${pagesessionId}${isexport ? exparam : '/'}` : `${isexport ? exparam : '/'}`
          if (response.hasOwnProperty('pageSessionId') && !data.pageObject && !data.gadgetsList) {
            window.history.pushState(`/i=${response.pageSessionId}`, response.pageSessionId, `${historystate}`)	 // pagesessionhidden
            // document.title = response.pageSessionId
            context.commit('setUrl',response.pageSessionId)
          }
          if (response.hasOwnProperty('pageName')) {
            window.history.replaceState(window.location.pathname,response.pageName,historystate)
            document.title = response.pageName
            // document.title = &#65279;
            // document.getElementsByTagName('title')[0].innerText = &#65279;
          }
          if (data.pageObject) {	
            if (data.pageObject && data.pageObject.presentationLayer.api) {
              context.commit('setBaseApi', data.pageObject.presentationLayer.api)
            } else {
              context.commit('setBaseApi',{})
            }
            if (!data.gadgetsList) {	
              // response.pageObject.revotioSessionId = state.revotioSessionId
              // response.pageObject.token = TokenService.getToken()
              // response.pageObject.deviceId = state.deviceId
              context.commit('setPageData', data.pageObject)
              let key = state.uid ? atob(state.uid) : '' 
              data.pageObject.uid=btoa(key)
              if (data.pageObject.gadgetsList) {
                const gadget = {gadgetsList:data.pageObject.gadgetsList}
                context.commit('setGadgetData', gadget)
              } else {
                context.dispatch('initializePost', data.pageObject)
              }
            } else {	
              context.commit('setPageData', data.pageObject)	
              context.commit('setGadgetData', {gadgetsList:data.gadgetsList})	
            }
            return
          }	

          // camelClase DeviceId and RevotioSessionId
          if (data.hasOwnProperty('DeviceId')) {
            data.deviceId = data.DeviceId
            delete data.DeviceId
          }
          // camelClase DeviceId and RevotioSessionID
          if (data.hasOwnProperty('RevotioSessionId')) {
            data.revotioSessionId = data.RevotioSessionId
            delete data.RevotioSessionId
          }
          if (data.hasOwnProperty('presentationLayer')) {
            if (data.presentationLayer && data.presentationLayer.api) {
              context.commit('setBaseApi', data.presentationLayer.api)
            } else {
              context.commit('setBaseApi',{})
            }
            context.commit('setPageData', data)
            if (data.gadgetsList) {
              const gadget = {gadgetsList:data.gadgetsList}
              context.commit('setGadgetData', gadget)
            } 
          } else if (data.hasOwnProperty('gadgetsList')) {
            context.commit('setGadgetData', data)
          } else if (data.hasOwnProperty('GadgetsList')) {
            context.commit('setGadgetData', data)
          }
        }
        const curentState = context.state
        let key = curentState.uid ? atob(curentState.uid) : '' 
        if (API_CONFIG.appDefaultToken !== 'API_KEY' && data.data) {
          const decrypt = JWTApiService.decrypt(data.data, key)
          actionData({...decrypt.body,uid:btoa(key)})
        } else if (response.token && data.data) {
          context.dispatch('newTokenV1',response).then(res=> {
            key = res.data.key || key
            const decrypt = JWTApiService.decrypt(data.data, key)
            actionData({...decrypt.body,uid:btoa(key)})
          })
        } else if(data.data) {
          let decrypt = JWTApiService.decrypt(data.data, key).body
          actionData({...decrypt,uid:btoa(key)})
        } else if (data.RevotioData.logout){
          TokenService.destroyToken()
          let isexport = window.location.href.includes('/export') ? true : false
          const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
          const historystate =  isexport ? exparam : '/'
          window.history.replaceState(window.location.pathname, "", historystate)
          location.reload()
        }
      }).catch(error => {
        context.dispatch('actSetError', {
          error: error,
          postData: infoData,
          redirect: true
        })
        // TODO SHOW ERROR
      })
    } catch (err) {
      context.dispatch('actSetError', {
        error: err,
        postData: infoData,
        redirect: true
      })
    }
  },
  async getTokenPostReturn(context, postData) {
    let vs = Vue.prototype.$vs || Vue.prototype.$Progress.$vm.$vs
    if (!postData.pageSessionId) {
      postData.pageSessionId = ''
    }
    try {
      return await TokenApiService.set(postData).then(({
        data
      }) => {
        if (data.tokenState === 'invalid') {
          context.dispatch('getTokenPost', postData)
        } else {
          TokenService.saveToken(data.token)
          ThemeService.saveThemeConfig()
          
          context.commit('setTokenAvailability', true)
          if (data.key && API_CONFIG.appDefaultToken === 'API_KEY') {
            context.commit('setUid', data.key)
            return data.key
          }
        }
      }).catch(error => {
        context.dispatch('actSetError', {
          error: error,
          postData: postData,
          redirect: true
        })
      })
    } catch (err) {
      context.dispatch('actSetError', {
        error: err,
        postData: postData,
        redirect: true
      })
    }
  },
  async getTokenPost(context, postData) {
    let vs = Vue.prototype.$vs || Vue.prototype.$Progress.$vm.$vs
    if (!postData.pageSessionId) {
      postData.pageSessionId = ''
    }
    try {
      TokenApiService.set(postData).then(({
        data
      }) => {
        if (data.tokenState === 'invalid') {
          context.dispatch('getTokenPost', postData)
        } else {
          TokenService.saveToken(data.token)
          ThemeService.saveThemeConfig()
          
          context.commit('setTokenAvailability', true)
          if (data.key && API_CONFIG.appDefaultToken === 'API_KEY') {
            context.commit('setUid', data.key)
          }
        }
      }).catch(error => {
        context.dispatch('actSetError', {
          error: error,
          postData: postData,
          redirect: true
        })
        TokenService.destroyToken(true)
      })
    } catch (err) {
      context.dispatch('actSetError', {
        error: err,
        postData: postData,
        redirect: true
      })
    }
  },
  async setActionPost(context, postData) {
    let vs = Vue.prototype.$vs || Vue.prototype.$Progress.$vm.$vs
    let progress = fn.getProgress(Vue, postData)
    progress.start()
    const noLoader = postData.hasOwnProperty('noLoader') ? postData.noLoader : false
    if (postData.hasOwnProperty('noLoader')) {
      delete postData.noLoader
    }
    context.commit('UPDATE_PROGRESS_STATUS',true)
    if (!noLoader) {
      fn.addRemoveAppClass('pointer-events-none', 'add')
      fn.addRemoveBodyClass('cursor-wait', 'add')
    }
    const endpoints = postData.api ? postData.api : {}
    if (endpoints) {
      delete postData.api
    }
    // context.commit('setBaseApi',{server:"https://dumychange",endPoint:"expoint"})
    try {
      postData = {...postData,authInfo:context.getters.revotioAuthInfo,baseApi:context.getters.baseApi }
      PageApiService.apiRequest(postData, false, endpoints)
      // .then(d=>console.log(d))
      .then((
        d
      ) => {
        document.body.classList.remove('pointer-events-none')
        let data = d.data
        let response = data
        const actionData = async (data) => {
          // camelClase DeviceId and RevotioSessionId
          if (data.hasOwnProperty('DeviceId')) {
            data.deviceId = data.DeviceId
            delete data.DeviceId
          }
          // camelClase DeviceId and RevotioSessionID
          if (data.hasOwnProperty('RevotioSessionId')) {
            data.revotioSessionId = data.RevotioSessionId
            delete data.RevotioSessionId
          }

          if (data.hasOwnProperty('setToken')) {
            TokenService.saveToken(data.token)
          }
          if (window.location.pathname !== '/') {
            // window.history.replaceState(window.location.pathname, "", "/")
          }
          if (data.Type === 'Redirect') {
            // window.history.replaceState({},document.title,'/')
            if (response.hasOwnProperty('pageSessionId') && !data.pageObject) {
              // window.history.replaceState(window.location.pathname, "", "/")	--tru hidden
              window.history.pushState(`/i=${response.pageSessionId}`, response.pageSessionId, `/i=${response.pageSessionId}`) // pagesession hidden	
              // document.title = response.pageSessionId	
              context.commit('setUrl',response.pageSessionId)
            }
            if (response.hasOwnProperty('pageName')) {
              const pagesessionId = TokenService.getParamValue('i')
              let isexport = window.location.href.includes('/export') ? true : false
              const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
              const historystate =  pagesessionId ? `/i=${pagesessionId}${isexport ? exparam : ''}` : `${isexport ? exparam : '/'}`
              window.history.replaceState(window.location.pathname,response.pageName,historystate)
              document.title = response.pageName
              // document.getElementsByTagName('title')[0].innerText = &#65279;
            }
            await fn.checkAppVersion(response.pageSessionId,true)
            progress.increase(30)
            context.commit('setPageData', data)
            if (data.gadgetsList) {
              const gadget = {gadgetsList:data.gadgetsList}
              context.commit('setGadgetData', gadget)
            } else {
              let menu = state.gadgetData.gadgetsList ? state.gadgetData.gadgetsList.filter(i => i.gadgetName === 'menu') : null
              let gadgetData = {
                gadgetsList: []
              }
              if (menu && menu.length === 1) {
                gadgetData.gadgetsList.push(menu[0])
              }
              context.commit('setGadgetData', gadgetData)
              context.dispatch('initializePost', data)
            }
          } else if (data.url && data.url.length) {
            window.open(data.url, '_blank')
            progress.finish()
          } else if (data.hasOwnProperty('gadgetsList')) {
            if (response.hasOwnProperty('pageName')) {
              const pagesessionId = TokenService.getParamValue('i')
              let isexport = window.location.href.includes('/export') ? true : false
              const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
              const historystate =  pagesessionId ? `/i=${pagesessionId}${isexport ? exparam : ''}` : `${isexport ? exparam : '/'}`
              window.history.replaceState({},response.pageName,historystate)
              document.title = response.pageName
            }
            progress.increase(5)
            // context.commit('setGadgetData', data)
            if(!state.gadgetData.gadgetsList) state.gadgetData.gadgetsList = []
            let gadgetList = state.gadgetData.gadgetsList.filter (i=> {
              return !data.gadgetsList.find(g=>g.gadgetId === i.gadgetId)
            })
            data.timeReceived = Date.now()
            data.gadgetsList.forEach(i=>{
              i.timeReceived = data.timeReceived
            })
            data.gadgetsList = [...gadgetList,...data.gadgetsList]
            context.commit('setGadgetData',data)
          } else if (data.hasOwnProperty('GadgetsList')) {
            progress.increase(5)
            context.commit('setGadgetData', data)
          } else if (data.hasOwnProperty('RevotioData') && data.RevotioData['logout'] === true) {
            TokenService.destroyToken()
            const pgid = TokenService.getParamValue('i')
            let isexport = window.location.href.includes('/export') ? true : false
            const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
            // const historystate =  pgid ? `i=${pgid}${isexport ? exparam : ''}` : `${isexport ? exparam : '/'}`
            const historystate = `${isexport ? exparam : '/'}`
            window.history.replaceState(window.location.pathname, "", historystate)
            location.reload()
          }

          fn.addRemoveAppClass('pointer-events-none', 'remove')
          fn.addRemoveBodyClass('cursor-wait', 'remove')
        }
        
        const curentState = context.state
        let key = curentState.uid ? atob(curentState.uid) : '' 
        if (API_CONFIG.appDefaultToken !== 'API_KEY' && data.data) {
          const decrypt = JWTApiService.decrypt(data.data, key)
          actionData({...decrypt.body,uid:btoa(key)})
        } else if (response.token && data.data) {
          context.dispatch('newTokenV1',response).then(res=> {
            key = res.data.key || key
            const decrypt = JWTApiService.decrypt(data.data, key)
            actionData({...decrypt.body,uid:btoa(key)})
          })
        } else {
          if (data.data) {
            const decrypt = JWTApiService.decrypt(data.data, key)
            actionData({...decrypt.body,uid:btoa(key)})
          } else actionData(data)
        }
      }).catch(error => {
        document.body.classList.remove('pointer-events-none')
        if (error.toString().includes("'data' of undefined")) {
          
        } else {
          context.dispatch('actSetError', {
            error: error,
            postData: postData,
            redirect: false
          })
        }
        // hiding this for now just in case they want this to revert back
        // if (error.message.includes('Network error')) {
        //   vs.notify({
        //     title: 'Error',
        //     text: error.message,
        //     color: 'danger'
        //   })
        // } else {
        //   vs.notify({
        //     title: 'Error',
        //     text: error.message,
        //     color: 'danger'
        //   })
        // }
        fn.addRemoveAppClass('pointer-events-none', 'remove')
        fn.addRemoveBodyClass('cursor-wait', 'remove')
      })
    } catch (err) {
      fn.addRemoveAppClass('pointer-events-none', 'remove')
      context.dispatch('actSetError', {
        error: err,
        postData: postData,
        redirect: false
      })
      fn.addRemoveAppClass('pointer-events-none', 'remove')
      fn.addRemoveBodyClass('cursor-wait', 'remove')
      // TODO SHOW ERROR
    } finally {
      setTimeout(
        function () {
          if (document.getElementById('app').classList.contains('pointer-events-none')) {
            fn.addRemoveAppClass('pointer-events-none', 'remove')
            fn.addRemoveBodyClass('cursor-wait', 'remove')
          }
        }, 5000);
    }
  },
  async sendActionPost(context, postData) {
    const endpoints = postData.api ? postData.api : {}
    if (endpoints) {
      delete postData.api
    }
    const newInfoData = {...postData,authInfo:context.getters.revotioAuthInfo,baseApi:context.getters.baseApi }
    return PageApiService.apiRequest(newInfoData, true, endpoints)
    .then((
      d
    ) => {
      let data = d.data
      let response = data
      const actionData = (data) => {
        // camelClase DeviceId and RevotioSessionId
        if (data.hasOwnProperty('DeviceId')) {
          data.deviceId = data.DeviceId
          delete data.DeviceId
        }
        // camelClase DeviceId and RevotioSessionID
        if (data.hasOwnProperty('RevotioSessionId')) {
          data.revotioSessionId = data.RevotioSessionId
          delete data.RevotioSessionId
        }

        if (window.location.pathname !== '/') {
          // window.history.replaceState(window.location.pathname, "", "/")
        }
        return data
      }
      const curentState = context.state
      let key = curentState.uid ? atob(curentState.uid) : '' 
      if (API_CONFIG.appDefaultToken !== 'API_KEY' && data.data) {
        const decrypt = JWTApiService.decrypt(data.data, key)
        return actionData({...decrypt.body,uid:btoa(key)})
      } else if (response.token && data.data) {
        return context.dispatch('newTokenV1',response).then(res=> {
          key = res.data.key || key
          const decrypt = JWTApiService.decrypt(data.data, key)
           return actionData({...decrypt.body,uid:btoa(key)})
        })
      } else {
        if (data.data) {
          const decrypt = JWTApiService.decrypt(data.data, key)
          return actionData({...decrypt.body,uid:btoa(key)})
        } else return actionData(data)
      }
    }).catch(error => {
      return error
      // context.dispatch('actSetError', { error: error, postData: postData, redirect: false })
    })
  },
  actSetError(context, data) {
    let progress = Vue.prototype.$Progress
    progress.fail()
    console.log(data.error)
    let errorData = {
      error: JSON.stringify(data.error),
      postData: JSON.stringify(data.postData),
      type:data.type ? data.type : 'request',
      errorHandling: data.redirect ? 'Redirected' : 'None'
    }
    if (data.redirect) {
      if (data.error.message === 'Network Error') {
        router.push("/error/networkerror/Network Error!")
      } else {
        router.push("/error/404/" + data.error.message)
      }
    }
    PageApiService.errorLog(errorData)
    context.commit('setError', data.error)
    context.commit('UPDATE_PROGRESS_STATUS',false)
  },
  async sendExitPost(context, postData) {
    await PageApiService.exitPost(postData)
  },
  async newToken (context, response) {
    let info = {
      deviceId: response.deviceId || curentState.deviceId,
      revotioSessionId: response.revotioSessionId || curentState.revotioSessionId
    }
    context.commit('setTokenInfo',response.token)
    TokenService.saveToken(response.token)
    context.dispatch('getKey',info )
  },
  async newTokenV1 (context, response) {
    const curentState = context.state
    let info = {
      deviceId: curentState.deviceId,
      revotioSessionId: curentState.revotioSessionId
    }
    context.commit('setTokenInfo',response.token)
    TokenService.saveToken(response.token)
    return context.dispatch('getKeyV1',info )
  },
  mockData (context, response) {
    const page = fn.getMockData('mocked_page')
    context.commit('setPageData', page)
    if (page.gadgetsList) {
      const gadget = {gadgetsList:page.gadgetsList}
      context.commit('setGadgetData', gadget)
    } else {
      const gadgets = fn.getMockData('mocked_gadgetsList')	
      context.commit('setGadgetData', gadgets)
    }
  },
  moveHistory (context,response) {
    let vs = Vue.prototype.$Progress ? Vue.prototype.$Progress : {start:function(){}}
    context.commit('UPDATE_PROGRESS_STATUS',true)
    vs.start()
    const url = response.url ? response.url : ''
    const pagesessionid = TokenService.getParamValue('i',url)
    const title = pagesessionid ? `i=${pagesessionid}`  : ''
    const id = title ? pagesessionid : ''
    let isexport = window.location.href.includes('/export') ? true : false
    const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
    const historystate =  pagesessionid ? `/i=${pagesessionid}${isexport ? exparam : ''}` : `${isexport ? exparam : '/'}`
    window.history.replaceState(title,'' , historystate)
    // document.title = id
    let postData = {entranceType:'route'}
    if (id) {
      postData = {
        pagesessionid: id,
        entranceType: 'pageSession',
      }
    }
    setTimeout(()=> {
      context.dispatch('entrancePost', {postData: postData, authInfo: state})
    },200)
    
  }
}
const mutations = {
  setError(state, error) {
    state.errors = error
  },
  setAuthInfo(state, info) {
    state.browserFingerPrint = info.details // browserFingerPrint
    state.deviceId = info.deviceId // DeviceID
    state.revotioSessionId = info.revotioSessionId // revotioSessionId
  },
  setRevotioSession (state, data) {
    state.revotioSessionId = data.revotioSessionId 
    state.revotioSessionId = data.deviceId 
  },
  setTokenAvailability(state, status) {
    state.tokenIsSet = status
  },
  setTokenStatus(state, status) {
    state.tokenState = status
  },
  setTokenInfo(state, info) {
    state.tokenData = info
  },
  setPageData(state, pageData) {
    state.pageData = pageData
  },
  updateGadgetLists (state, gadgetData) {
    state.gadgetData.gadgetsList = gadgetData.gadgetsList
  },
  setNavObj (state, navObj) {
    state.navObj = navObj
  },
  setGadgetData(state, gadgetData) {
    if (gadgetData.hasOwnProperty('GadgetsList')) {
      gadgetData.gadgetsList = gadgetData.GadgetsList.slice()
      delete gadgetData.GadgetsList
    }
    // gadgetData.gadgetsList = gadgetData.gadgetsList.filter(i => i.gadgetId !== "30da97a2-7a5b-4b85-534b-a38c0fe34f4d") // "cad1b1a3-23d3-b9c9-68e5-c1f76060f06c" "777356c0-2ae7-c7a6-5228-95c2fa035b72" "97b12b38-dfc6-d400-5c68-1a287f8523f8"
    // for (let i in gadgetData.gadgetsList) {
    //     let gadget = gadgetData.gadgetsList[i]
    //         // make sure we have a camel case gadget variables
    //     if (!gadget.hasOwnProperty('gadgetId')) {
    //         gadget.gadgetId = gadget.GadgetId
    //         delete gadget.GadgetId
    //     }

    //     if (!gadget.hasOwnProperty('gadgetOptions') && gadget.hasOwnProperty('GadgetOptions')) {
    //         gadget.gadgetOptions = gadget.GadgetOptions
    //         delete gadget.GadgetOptions
    //     }
    //     if (gadget.hasOwnProperty('gadgetOptions') && gadget.hasOwnProperty('GadgetSettings')) {
    //         gadget.gadgetOptions = {
    //             ...gadget.gadgetOptions,
    //             ...gadget.GadgetSettings
    //         }
    //         delete gadget.GadgetSettings
    //     } else if (!gadget.hasOwnProperty('gadgetOptions') && gadget.hasOwnProperty('GadgetSettings')) {
    //         gadget.gadgetOptions = gadget.GadgetSettings
    //         delete gadget.GadgetSettings
    //     }

    //     if (!gadget.hasOwnProperty('gadgetName')) {
    //         gadget.gadgetName = gadget.GadgetName
    //         delete gadget.GadgetName
    //     }

    //     if (!gadget.hasOwnProperty('gadgetType')) {
    //         gadget.gadgetType = gadget.GadgetType
    //         delete gadget.GadgetType
    //     }

    //     if (!gadget.hasOwnProperty('gadgetData') && gadget.hasOwnProperty('GadgetContent')) {
    //         gadget.gadgetData = gadget.GadgetContent
    //         delete gadget.GadgetContent
    //     }
    //     if (gadget.hasOwnProperty('gadgetData') && gadget.hasOwnProperty('GadgetData')) {
    //         gadget.gadgetData = {
    //             ...gadget.gadgetData,
    //             ...gadget.GadgetData
    //         }
    //         delete gadget.GadgetData
    //     } else if (!gadget.hasOwnProperty('gadgetData') && gadget.hasOwnProperty('GadgetData')) {
    //         gadget.gadgetData = gadget.GadgetData
    //         delete gadget.GadgetData
    //     }
    // }
    state.gadgetData = gadgetData
    // TokenService.saveGadgets(gadgetData)
  },
  updateGadgetList(state, gadgetData) {
    let gadget = gadgetData
    //format gadget names temporary
    if (gadget.hasOwnProperty('GadgetId')) {
      gadget.gadgetId = gadget.GadgetId
      delete gadget.GadgetId
    }
    if (gadget.hasOwnProperty('GadgetName')) {
      gadget.gadgetName = gadget.GadgetName
      delete gadget.GadgetName
    }
    if (gadget.hasOwnProperty('GadgetData')) {
      gadget.gadgetData = gadget.GadgetData
      delete gadget.GadgetData
    }
    if (gadget.hasOwnProperty('GadgetType')) {
      gadget.gadgetType = gadget.GadgetType
      delete gadget.GadgetType
    }
    if (gadget.hasOwnProperty('GadgetContent')) {
      gadget.gadgetData = gadget.GadgetContent
      delete gadget.GadgetContent
    }
    if (gadget.hasOwnProperty('gadgetData') && gadget.hasOwnProperty('GadgetData')) {
      gadget.gadgetData = {
        ...gadget.gadgetData,
        ...gadget.GadgetData
      }
      delete gadget.GadgetData
    } else if (!gadget.hasOwnProperty('gadgetData') && gadget.hasOwnProperty('GadgetData')) {
      gadget.gadgetData = gadget.GadgetData
      delete gadget.GadgetData
    }
    if (gadget.hasOwnProperty('GadgetOptions')) {
      gadget.gadgetOptions = gadget.GadgetOptions
      delete gadget.GadgetOptions
    }
    if (state.gadgetData.timeReceived) {
      gadget.timeReceived = state.gadgetData.timeReceived
    }
    if (gadget.gIndex) {
      state.gadgetData.gadgetsList = state.gadgetData.gadgetsList.filter(i=>!(gadget.gIndex === i.gIndex && gadget.gadgetId === i.gadgetId))
    } else {
      state.gadgetData.gadgetsList = state.gadgetData.gadgetsList.filter(i=>!(gadget.gadgetId === i.gadgetId))
    }
    state.gadgetData.gadgetsList.push(gadget)
    // TokenService.saveGadgets({gadgetsList:[gadget]})
  },
  setUid (state, key) {
    state.uid = btoa(key)
  },
  setUrl (state, val) {
    state.currentUrl = val
  },
  setBaseApi(state, val) {
    if (val.server && val.server.slice(-1) === '/') {
      val.server = val.server.slice(0, -1); 
    }
    return state.baseApi = val
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}

// const auth = {
//   namespaced: true,
//   state: {
//     items: (state) => {
//       return state.items
//     }
//   },
//   getters: {
//     items: (state) => {
//       return state.items
//     }
//   },
//   actions: {

//   },
//   mutations: {
//     setError (state, error) {
//       state.errors = error
//     },
//     setAuthInfo (state, info) {
//       state.browserFingerPrint = info.details // browserFingerPrint
//       state.deviceId = info.deviceId // DeviceID
//       state.revotioSessionId = info.revotioSessionId // revotioSessionId
//     },
//     setTokenAvailability (state, status) {
//       state.tokenIsSet = status
//     },
//     setTokenStatus (state, status) {
//       state.tokenState = status
//     },
//     setTokenInfo (state, info) {
//       state.tokenData = info
//     },
//     setPageData (state, pageData) {
//       state.pageData = pageData
//     },
//     setGadgetData (state, gadgetData) {
//       state.gadgetData = gadgetData
//     }
//   }
// }
