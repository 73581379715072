<template>
</template>
<script>
import fn from '@/common/lib.service'
import lzwCompress from 'lzwcompress'
import { mapGetters } from 'vuex'
import TokenService from '@/common/token.service'

export default {
  name: 'courseProgress',
  computed: {
    ...mapGetters(['revotioPageData', 'revotioGadgetData', 'items', 'revotioFingerPrint', 'revotioDeviceID', 'revotioAuthInfo', 'revotioSessionId']),
  },
  methods: {
    sendAction (){
      
      const gadgetInfo = JSON.parse(this.$route.params.gadgetInfo)
      const progress = this.$route.params.progress
      var result = progress
          ? this.decompress(progress)
          : {};
      gadgetInfo.gadgetData.progress = result
      if (!gadgetInfo) return
      let postData = {
        'gadgetId': gadgetInfo.gadgetId,
        'gadgetName': gadgetInfo.gadgetName || this.gadgetInfo.gadgetName,
        'gadgetType': gadgetInfo.gadgetType || this.gadgetInfo.gadgetType,
        'actionType': 'progress',
        'gadgetData' : gadgetInfo.gadgetData
      }
      let actiondata = {
        payload: 'Gadget',
        postData: postData,
        authInfo: {...this.revotioAuthInfo,tokenData:TokenService.getToken()}
      }
      this.$store.dispatch('getKeyV1', actiondata.authInfo)
      .then (res => {
        actiondata.authInfo = this.revotioAuthInfo
        this.$store.dispatch('sendActionPost', actiondata)
      })
      // 
    },
    decompress(stringData) {
      try {
        const data = JSON.parse(stringData);
        return data.d
          ? JSON.parse(lzwCompress.unpack(data.d))
          : data;
      } catch(e) {
        return {};
      }
    }
  },
  mounted () {
    this.sendAction()
  }
}
</script>
