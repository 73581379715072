
<template>
  <div class="layout--full-page">
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/500.png" alt="graphic-500" class="mx-auto mb-4">
            <h1 class="sm:mx-0 mx-4 mb-4 sm:mb-12 text-5xl d-theme-heading-color">{{message}}</h1>
            <!-- <p class="sm:mx-0 mx-4 mb-4 sm:mb-16 d-theme-text-inverse">susceptive nonturbinated indoctrination formulary dyskinetic deafforest Strumella frolicsomeness encrustment portia myelination lachrymatory bestain hoople piscator pyramidoidal parter clipt.</p> -->
            <vs-button size="large" @click="reRoute" >Try Again</vs-button>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'E502',
  computed: {
    image () {
      let img = '@/assets/images/pages/'
      switch (this.$route.params.error) {
        case '500' :
        case '404' : img += this.$route.params.error + '.png'
          break
        case 'networkerror' : img += '500.png'
          break
        default : img += '500.png'
      }
      return img
    },
    message () {
      return this.$route.params.message
    }
  },
  beforeDestroy (){
    this.reRoute()
  },
  mounted () {
    window.history.replaceState(window.location.hash, "", "/");
  },
  created() {
    // window.addEventListener("beforeunload", event => {
    //   console.log('dd')
    //   event.preventDefault()
    //   this.reRoute()

    //   console.log('dd3')
    // })
  },
  methods: {
    reRoute() {
      window.location.replace('/')
      window.location.href = '/'
    }
  }
}
</script>
