var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "mr-1",
    attrs: { src: "static/" + _vm.logoFileName, width: "40px" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }