import {
  API_CONFIG
} from '@/common/configs'
var nJwt = require('njwt');
var sjwt = require('jwt-simple');
import fn from '@/common/lib.service'
export const JWTApiService = {
  encryption: API_CONFIG.JWT,
  encrypt: async (data, key) => {
    if (!key) return 'nokeyyet'
    let publisherType = fn.getUrlParam('publisherType')
    publisherType = publisherType || API_CONFIG.publisherType || 'private'
    if (publisherType==='public') {
      return data
    }
    // var jwt = nJwt.create(data, key, API_CONFIG.JWT.algorithm)
    var jwt = sjwt.encode(data, key,API_CONFIG.JWT.algorithm )
    return jwt
    // return jwt.compact()
  },
  decrypt: (token, key) => {
    let publisherType = fn.getUrlParam('publisherType')
    publisherType = publisherType || API_CONFIG.publisherType || 'private'
    if (publisherType==='public') {
      return token
    }
    token = (typeof token === 'object') ? Object.keys(token)[0] : token
    try {
      // return nJwt.verify(token, key, API_CONFIG.JWT.algorithm)
      let decoded = sjwt.decode(token, key, API_CONFIG.JWT.algorithm)
      return decoded.body ? decoded : {body: decoded}
    } catch (e) {
      console.warn('Token is not valid',e)
    }
  }
}
