<template>
  <div class="presentationLayer layout--main" v-if="containerInitialize" 
   v-bind:style="pageStyles"
  v-bind:class="[pageClass, layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">
    <apptour/>
    <revotiomenu v-if="hasMenu" :menuObject="menuObject" />
    <div class="menuVerticalNav"  v-if="hasMenu">
    <!-- <feather-icon class="sm:inline-flex cursor-pointer mr-1" icon="MenuIcon" @click.stop="showSidebar"></feather-icon> -->
    </div>
    <the-customizer
      v-if                    = "!disableCustomizer"
      :footerType             = "footerType"
      :hideScrollToTop        = "hideScrollToTop"
      :navbarType             = "navbarType"
      :navbarColor            = "navbarColor"
      :routerTransition       = "routerTransition"
      @toggleHideScrollToTop  = "toggleHideScrollToTop"
      @updateFooter           = "updateFooter"
      @updateNavbar           = "updateNavbar"
      @updateNavbarColor      = "updateNavbarColor"
      @updateRouterTransition = "updateRouterTransition" />
    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div class="main-container content-wrapper" v-bind:class="[{hasMenu : hasMenu, isright : isRight},contentAreaClass, {'show-overlay': bodyOverlay}]">
      <!-- NAVBAR -->
      <template >
        <navBarHorizontalLayout
          v-if="hasMenu"
          :navbarType= "navbarType"
          v-bind:layerData="navBar"
          :key="navBarReset+'navbar-horizontal'"
          :logo= "''"
          :class="[
            {'text-white' : isNavbarDark  && !isThemeDark},
            {'text-base'  : !isNavbarDark && isThemeDark}
          ]" />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <h-nav-menu
          v-if="hasMenu && mainLayoutType === 'horizontal' && windowWidth >= 1200"
          :menuObject="menuObject" />
        <navBarLayout
          v-if="hasMenu || (navBar.length>0 || Object.keys(navBar).length>0)"
          v-bind:layerData="navBar"
          :navbarColor="navbarColor"
          :key="navBarReset+'navbar'"
          :class="[
            {'text-white' : isNavbarDark  && !isThemeDark},
            {'text-base'  : !isNavbarDark && isThemeDark}
          ]" />
      </template>
      <!-- MAIN CONTENT -->
       <div class="content-wrapper">
         <!-- <div :class="{'router-view':!fullwidth}">-->
          <div v-bind:style="pagePadding">
          <div :class="{'router-content' : hasMenu || (navBar.length>0 || Object.keys(navBar).length>0),'dcontent':mainDivWidth}">
            <!-- <revotiocalendar :itemData="{id:'dsd'}"/> -->
            <container :is="container.type" v-for="(container,inx) in pageContainer"
            v-bind:key="container.id + '-' + reinitializePage + '-'+inx" v-bind:layerData="container"/>
            <div class="content-area__content">
              <back-to-top bottom="5%" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </back-to-top>
            </div>

          </div>
        </div>
      </div>
      </div>
    </div>

<footer v-if="footer.type">
  <container :is="footer.type"
  v-bind:layerData="footer"/>
</footer>
  </div>
</template>

<script>
/* eslint-disable */
import {
  mapGetters,
  mapState
} from 'vuex'
import fn from '@/common/lib.service'
import item from './pageTemplate/item'
import container from './pageTemplate/container'
import cell from './pageTemplate/cell'
import modal from './pageTemplate/modal'
import navBarLayout from './pageTemplate/navBar'
import navBarHorizontalLayout from './pageTemplate/navBarHorizontal'
import revotiomenu from './pageTemplate/revotiomenu'
import ThemeService from '@/common/theme.service'
let themeConfigs = ThemeService.getThemeConfigs()
// import TheFooter from '@/layouts/components/TheFooter.vue'

//from theme
import TheCustomizer      from "@/layouts/components/customizer/TheCustomizer.vue"
import BackToTop          from 'vue-backtotop'
// import HNavMenu           from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue"
import HNavMenu from "@/components/pageTemplate/menu/layout/horizontal/HorizontalNavMenu.vue"
import API_CONFIGS from '@/common/configs/api_config'
export default {
  name: 'Page',
  computed: {
    ...mapGetters (['revotioFingerPrint', 'revotioDeviceID', 'revotioAuthInfo', 'revotioPageData','revotioGadgetData']),
    ...mapState (['browserFingerPrint', 'tokenIsSet']),
    pageContainer: function () {
      let objectFromWindow = this.getObjectWindow()
      return objectFromWindow.container.filter( container => (container.subType !=='navbar' && container.subType !== 'footer'))
      // if (this.revotioPageData.presentationLayer && this.revotioPageData.presentationLayer.content &&  this.revotioPageData.presentationLayer.content.container) {
      // return this.revotioPageData.presentationLayer.content.container.filter ( container => (container.subType !=='navbar' && container.subType !== 'footer'))
      // } else return []
    },
    pageClass: function () {
      return this.revotioPageData.Page.split(' ').join('-')
    },
    bodyOverlay () { return this.$store.state.bodyOverlay },
    contentAreaClass () {
      if(this.mainLayoutType === "vertical") {
        if      (this.verticalNavMenuWidth == "default") return "content-area-reduced"
        else if (this.verticalNavMenuWidth == "reduced") return "content-area-lg"
        else return "content-area-full"
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      else return "content-area-full"
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType == 'hidden',
        'footer-sticky': this.footerType == 'sticky',
        'footer-static': this.footerType == 'static',
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme == 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}` },
    mainLayoutType ()  { return this.$store.state.mainLayoutType  },
    withMainDivisions () { return this.$store.state.withMainDivisions },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType == 'hidden',
        'navbar-sticky'   : this.navbarType == 'sticky',
        'navbar-static'   : this.navbarType == 'static',
        'navbar-floating' : this.navbarType == 'floating',
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth () { return this.$store.state.windowWidth },
    pageStyles: function () {
      return fn.contentStylesFormat(this.revotioPageData.presentationLayer.pageStyle)
    },
    fullwidth () {
      if (this.revotioPageData.presentationLayer.hasOwnProperty('pageStyle')) {
        return this.revotioPageData.presentationLayer.pageStyle.full
      }
    },
    mainDivWidth () {
      const pageDiv = this.revotioPageData.hasOwnProperty('pageStyle') ? this.revotioPageData.pageStyle.noDivision : false
      const widthDiv = ['FALSE','false',false,'APP_CONTAINER_BORDER'].includes(API_CONFIGS.withDivisionMain) ? false : true
      const show  = pageDiv ? false : widthDiv
      return show
    },
    pagePadding () {
      const defaultPadding = API_CONFIGS.pagePadding || 0
      const presentation = this.revotioPageData.presentationLayer || {}
      const presentationPadding = presentation.pagePadding || 0
      const activepadding = presentationPadding || defaultPadding
      const paddingNum = parseFloat(activepadding) / 2
      return paddingNum ? {[`padding-left`]:`${paddingNum}%`,[`padding-right`]:`${paddingNum}%`} : {}
    }
  },
  watch: {
    revotioPageData (newb, oldb) {
      // lets check if has container
      this.navBarGadget = []
      this.$Progress.increase(30)
      this.containerInitialize = true
      if (newb.hasOwnProperty('presentationLayer')) {
        this.keyIndex++
        if (newb.presentationLayer.menuSettings.visible) {
          this.hasMenu = true
        }
        if (newb.presentationLayer.menuSettings.position === 'right') {
          this.isRight = true
        }
        // remove any custom styles
        document.querySelectorAll('[customstyle="true"]').forEach(i=>i.remove())
      }

      if (newb.presentationLayer.embeddedScripts) {
        // this.addedScript = []
        newb.presentationLayer.embeddedScripts.forEach(i=> {
          document.getElementsByTagName('script').forEach(e=>{
            if (String(i.type) == e.id) {
              e.remove()
              document.getElementById('amb-cnf-embedded-container') ? document.getElementById('amb-cnf-embedded-container').remove() : null
            }
          })
          let script = document.createElement('script');
          script.type = 'text/javascript';  
          script.rel = 'preload';
          script.defer = 'defer';
          script.id = String(i.type);  
          let datascript = i.script.replace('<script>','').replace('<\/script>','');
          script.innerHTML = datascript
          document.getElementsByTagName('head')[0].appendChild(script);
          this.addedScript.push(String(i.type))
        })
      }
      this.reinitializePage++
      this.initializeMenu()
    },
    async revotioGadgetData (newb, oldb) {
      document.getElementsByClassName('vs-tooltip').forEach(i => {if(i.style && i.style.display !== 'none') i.style.display = 'none' }) // this checks the tooltip
      this.initializeMenu()
      this.$nextTick(()=> {
        this.reCalculatePage(this.pageContainer, this, true)
      })
      let newbG = newb.gadgetsList.length > 0 ? true : false
      if (newbG && newb.gadgetsList.length === 1 && newb.gadgetsList[0].gadgetId === 'menu') {
        newbG = false
      }
      if (newbG) {
        setTimeout(()=> {
          this.$store.commit("UPDATE_PROGRESS_STATUS", false)
          this.$Progress.finish()
        },100)
      }
      // check navBar
    this.$nextTick(()=> {
      let newnavGadgetObj = []
      let navGObject = this.navBarGadget
      if (oldb && navGObject.length > 0) {
        let newnavGadgetObj = newb.gadgetsList.filter (g=>{
          return navGObject.includes(g.gadgetId)
        })

        if (newnavGadgetObj.length <= 0) {
          // find in old state the gadgetdata
          const gadgetsList = oldb.gadgetsList || []
          let oldNav = gadgetsList.filter(g=>navGObject.includes(g.gadgetId))
          if (oldNav.length > 0) {
            this.$store.state.auth.gadgetData.gadgetsList = [...this.$store.state.auth.gadgetData.gadgetsList,...oldNav]
          }
        }
      }
    })
    },
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark (val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff"
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType' (val) {
      this.setNavMenuVisibility(val)
      this.disableThemeTour = true
    },
    windowWidth() {
      this.reCalculatePage(this.pageContainer, this, true)
      this.setTheNavBar()
      let allRightSibar = document.querySelectorAll('.right-side-bar');
      allRightSibar.forEach(el=>el.remove());
    }
  },
  components: {
    container,
    cell,
    actionButton: () => import('./pageTemplate/actionButton/actionButton'),
    modal,
    item,
    revotiomenu,
    TheCustomizer,
    BackToTop,
    HNavMenu,
    navBarLayout,
    navBarHorizontalLayout,
    apptour: () => import('./AppTour'),
    },
  /* eslint-disable */
  methods: {
    initializeMenu (gadgetonly = false) {
      // settigs
      if (this.revotioPageData.presentationLayer && this.revotioPageData.presentationLayer.menuSettings && this.revotioPageData.presentationLayer.menuSettings.visible === true) {
        this.showMenu = true
      }
      this.menuInfo = fn.getGadgetInfo(this.revotioGadgetData.gadgetsList, 'menu')
      this.menuContent = fn.getGadgetContentData(this.menuInfo, 'gadgetData')
      this.formatMenu()

    },
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    updateNavbar (val) {
      if (val == "static") this.updateNavbarColor("#fff")
      this.navbarType = val
    },
    updateNavbarColor (val) {
      this.navbarColor = val
      if (val == "#fff") this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    updateFooter (val) {
      this.footerType = val
    },
    updateRouterTransition (val) {
      this.routerTransition = val
    },
    setNavMenuVisibility (layoutType) {
      if((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === "vertical")) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      }
      else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    toggleHideScrollToTop (val) {
      this.hideScrollToTop = val
    },
    getObjectWindow () {
      let screenProfiles = this.revotioPageData.presentationLayer && this.revotioPageData.presentationLayer.screenProfiles
      let defaultContent = this.revotioPageData.presentationLayer ? this.revotioPageData.presentationLayer.content : {container:[]}
      let objectFromWindow = {
        md: screenProfiles && screenProfiles.md && screenProfiles.md.content ? screenProfiles.md.content : defaultContent,
        xs: screenProfiles && screenProfiles.xs && screenProfiles.xs.content ? screenProfiles.xs.content : defaultContent,
        full: defaultContent
      }
      let winsize = 'full'
      if (this.windowWidth >= 768 && this.windowWidth <= 1023) {
        winsize = 'md'
      } else if (this.windowWidth >= 0 && this.windowWidth <= 767) {
        winsize = 'xs'
      } else {
         winsize = 'full'
      }
      return objectFromWindow[winsize]
    },
    setTheNavBar () {
      this.navBar = []
      this.footer = []
      this.navBarReset++
      let objectFromWindow = this.getObjectWindow()
      for (let i in objectFromWindow.container){
        let container = objectFromWindow.container[i]
        if (container.subType && container.subType === 'navbar') {
          this.navBar = container
          this.commitNavBarGadget()
        }
        if (container.subType && container.subType === 'footer') {
          this.footer = container
        }
      }
    },
    commitNavBarGadget () {
      let gadgets = []
      const checkGadget = (columns) => {
        columns.forEach(col=>{
          if (col.type ==='item') {
            gadgets = [...gadgets,col.content[0].id]
          } else checkGadget(col.columns)
        })
      }
      checkGadget([this.navBar])
      this.navBarGadget = gadgets
      // this.$store.commit('setNavObj',gadgets)
    },
    formatMenu () {
      let parent = this.menuContent.parent
      let child = this.menuContent.child ? this.setRenderMe(this.menuContent.child) : []
      if (parent !== undefined) {
        if (parent.hasOwnProperty('name')) {
          this.child(parent, child, this.menuObject)
          // add the child to parent
        } else {
          // add childb as parent\
          this.menuObject = child
        }
      }
      if(!this.revotioGadgetData.timeReceived) {
        this.setTheNavBar()
      }
    },
    setRenderMe (child) {
      return child.map(i=> {
        if (!i.hasOwnProperty('renderMe')) i.renderMe = false
        if (i.hasOwnProperty('inner')) {
          i.inner = this.setRenderMe(i.inner)
        }
        return i
      })
    },
    parent (child) {
      let newchild = []
      for (let i in child) {
        newchild[child[i].name] = child[i]
        if (child[i].hasOwnProperty('inner') && child[i].inner.length > 0) {
          let inner = this.parent(child[i].inner)
          child[i].inner = inner
        }
      }
      return newchild
    },
    child (parent, child, menuObject) {
      if (parent.hasOwnProperty('lazyload')) {
        for (const m in menuObject) {
          if (menuObject[m].hasOwnProperty('lazyload') && menuObject[m].lazyload === parent.lazyload) {
            if (parent.hasOwnProperty('organization_id') && parent.organization_id === menuObject[m].organization_id) {
              menuObject[m].inner = [] // let make sure it is empty
              menuObject[m].inner = child
              this.$root.$emit(parent.lazyload + 'lazy', true)
              this.$Progress.finish()
              return
            } else if (!parent.hasOwnProperty('organization_id')) {
              menuObject[m].inner = [] // let make sure it is empty
              menuObject[m].inner = child
              this.$root.$emit(parent.lazyload + 'lazy', true)
              this.$Progress.finish()
              return
            }
          } else if (menuObject[m].inner.length > 0) {
            this.child(parent, child, menuObject[m].inner)
          }
        }
      }
    },
    reCalculatePage (pageContainer, parentData, isMain = false) {
      let beforeSibs = []
      let beforeSibsIndex = 0
      let nextSibs = []
      let containerWidths = []

      for (let i in pageContainer) {
        let content = pageContainer[i]
        if (content.hasOwnProperty('styles') && content.styles.width && content.styles.width !== 'auto' && !content.hasOwnProperty('originalWidth')) {
          content.originalWidth = content.styles.width
        }
        let inWidth = {}
        if (content.type !== 'modal') {
          switch (content.type) {
            case 'item': inWidth = this.itemChecker(content, parentData)
              break
            case 'container':
            case 'cell': inWidth = this.containerChecker(content,parentData)
              break
          }
        }
        if (isMain && content.type === 'item') {
          let classList = inWidth.info && inWidth.info.$el ?  inWidth.info.$el.classList : null
          if (classList)  {
            if(inWidth.inMin) {
              inWidth.info.$el.classList.remove('hidden') // this is to make sure it is not hidden
            } else inWidth.info.$el.classList.add('hidden')
          }
          continue
        }

        if (content.type === 'cell' || content.type === 'container') {
          // check if cell is in Width
          let hasMissingWidth = -1
          for (let i in inWidth) {

            if (inWidth[i].inMin === false) {
              hasMissingWidth = i
            }
          }
          if (hasMissingWidth <= -1) {
            beforeSibsIndex = i
            beforeSibs.push(content)
          } else {
            if (pageContainer[parseInt(i)+1]) {
              // get the width of the next sibs
              let next = pageContainer[parseInt(i)+1]
              let removedWidth = 0
              if (next.styles.width && next.styles.width !== 'auto') {
                removedWidth = next.styles.originalWidth || next.styles.width
              } else {
                let nextInfo = this.findtheInfo(parentData, next.id, gIndex )
                removedWidth = 0
                if (nextInfo) {
                  let currentwidth = nextInfo.$el.offsetWidth
                  let parentwidth = parentData.$el.offsetWidth
                  removedWidth = (parseFloat(currentwidth) / parseFloat(parentwidth)) * 100
                  if (isNaN(removedWidth)) {
                    removedWidth = 0
                  }
                }
              }
              beforeSibs.push(content)
              beforeSibsIndex = i
              let divident = removedWidth / beforeSibs.length
              for (let s in beforeSibs) {
                if (beforeSibs[s].styles.width && beforeSibs[s].styles.width !=='auto' ) {
                  let test = this.findtheInfo(parentData, beforeSibs[s].id)
                  beforeSibs[s].styles.width = parseFloat(beforeSibs[s].styles.width) + divident
                }
              }
            } else {
              // make the current width as 100% and divided na current to the beforesibs
              if (content.styles.width && content.styles.width !== 'auto') {
                let divident = parseFloat(content.originalWidth) / beforeSibs.length
                for (let s in beforeSibs) {
                  if (beforeSibs[s].styles.width && beforeSibs[s].styles.width !=='auto' ) {
                    let test = this.findtheInfo(parentData, beforeSibs[s].id)
                    beforeSibs[s].styles.width = parseFloat(beforeSibs[s].originalWidth) + divident
                  }
                }
                content.styles.width = 100
              }
            }
          }

        } else if (content.type === 'item') {
          containerWidths.push(inWidth)
        }
      }
      return containerWidths
    },
    itemChecker (item, parentData) {
      const itemGadget = item.content[0]
      const itemInfo = this.findtheInfo(parentData, item.id)
      let inMin = itemInfo.$el ? fn.gadgetInMin(itemGadget.type, itemInfo.$el.offsetWidth) : true
      // double check if gadget is
      inMin = (itemInfo.$children && itemInfo.$children[0] && itemInfo.$children[0].hasOwnProperty('isDisabled')) ? !itemInfo.$children[0].isDisabled : inMin
      // if(itemInfo.$children[0].hasOwnProperty('isDisabled') &&
      // itemInfo.$children[0].isDisabled === true) {
      //   inMin = false
      // }
      return {
        inMin: inMin,
        info: itemInfo
      }
    },
    containerChecker (content, parentData) {
      let info = this.findtheInfo(parentData, content.id)
      let info1 = this.reCalculatePage(content.columns, info, false)
      return info1
      // console.log(info1)
    },
    findtheInfo (parentData, itemid = 0) {
      for (let i in parentData.$children) {
        let ch = parentData.$children[i]
        if (ch.$refs[itemid]) {
        ch.$refs[itemid].classList.remove('hidden')
          return ch
        }
      }
      return {}
    }
  },
  data () {
    /* eslint-disable */
    return {
      containerInitialize: false,
      hasMenu: false,
      isRight: false,
      // vuexy
      disableCustomizer : themeConfigs.disableCustomizer,
      disableThemeTour  : themeConfigs.disableThemeTour,
      dynamicWatchers   : {},
      footerType        : themeConfigs.footerType  || 'static',
      hideScrollToTop   : themeConfigs.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfigs.navbarColor || '#fff',
      navbarType        : themeConfigs.navbarType  || 'floating',
      routerTransition  : themeConfigs.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      hideScrollToTop   : themeConfigs.hideScrollToTop,
      menuInfo          : {},
      menuContent       : {},
      menuOptions       : {},
      menuObject        : [],
      navBar            : {},
      keyIndex          : 0,
      reinitializePage  : 0,
      addedScript       : [],
      navBarReset       : 0,
      footer            : [],
      navBarGadget      : []
    }
  },
  created () {
    const color = this.navbarColor == "#fff" && this.isThemeDark ? "#10163a" : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch("$store.state.windowWidth", (val) => {
      if(val < 1200) {
        this.disableThemeTour = true
        this.dynamicWatchers.windowWidth()
      }
    })

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch("$store.state.verticalNavMenuWidth", () => {
      this.dynamicWatchers.verticalNavMenuWidth()
    })
    
    const sendExitPost = () => {
      let actiondata = {
        authInfo: this.revotioAuthInfo
      }
      this.$store.dispatch('sendExitPost',actiondata)
    }
    window.onbeforeunload = function(e) {
      e.preventDefault();
      e.returnValue = 'Your session will be removed.';
    }
    window.addEventListener('unload', async function(e) {
      e.preventDefault()
      sendExitPost()
    });
    },
  beforeDestroy () {
    Object.keys(this.dynamicWatchers).map(i => {
      this.dynamicWatchers[i]()
      delete this.dynamicWatchers[i]
    })
  }

}
</script>
