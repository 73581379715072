var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout--full-page" }, [
    _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
      _c(
        "div",
        {
          staticClass:
            "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
        },
        [
          _c("img", {
            staticClass: "mx-auto mb-4",
            attrs: {
              src: require("@/assets/images/pages/500.png"),
              alt: "graphic-500",
            },
          }),
          _vm._v(" "),
          _c(
            "h1",
            {
              staticClass:
                "sm:mx-0 mx-4 mb-4 sm:mb-12 text-5xl d-theme-heading-color",
            },
            [_vm._v("Internal Server Error!")]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "sm:mx-0 mx-4 mb-4 sm:mb-16 d-theme-text-inverse" },
            [
              _vm._v(
                "susceptive nonturbinated indoctrination formulary dyskinetic deafforest Strumella frolicsomeness encrustment portia myelination lachrymatory bestain hoople piscator pyramidoidal parter clipt."
              ),
            ]
          ),
          _vm._v(" "),
          _c("vs-button", { attrs: { size: "large", to: "/" } }, [
            _vm._v("Reconnect"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }