/*
 * Created Date: 2024-11-24T12:28:45                                           *
 * Author: Lei Fuentes                                                         *
 * Modified By: Lei Fuentes                                                    *
 * Last Modified: 2024-11-24T12:28:46                                          *
 */



/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
import API_CONFIGS from '@/common/configs/api_config'
import Vue from 'vue'
import Vuesax from 'vuesax'
let colors = {
	primary : '#7367F0',
	success : '#28c76f',
	danger  : '#EA5455',
	warning : '#FF9F43',
	dark    : '#1E1E1E',
	info    : 'rgb(3, 14, 146)',
  secondary : API_CONFIGS.defaultSecondary || 'rgb(155, 149, 233)',
  textColor : API_CONFIGS.defaultTextColor || '#333333',
  button : API_CONFIGS.defaultButtonColor || '#7367F0'
}

Vue.use(Vuesax, { theme:{ colors }, rtl: false })
let progressType = 'type1'
if (API_CONFIGS.progressType !== 'APP_PROGRESS_TYPE') {
  progressType = API_CONFIGS.progressType
}
if (API_CONFIGS.defaultPrimary !== 'APP_PRIMARY_COLOR') {
  colors.primary = API_CONFIGS.defaultPrimary
}
if (API_CONFIGS.font) {
  switch (API_CONFIGS.font) {
    case 'Roboto' : API_CONFIGS.font = '"Roboto", sans-serif'
    break;
    case 'Avenir' : API_CONFIGS.font = '"Avenir",Helvetica,Arial,sans-serif'
    break;
    case 'Montserrat': API_CONFIGS.font = '"Montserrat",Helvetica,Arial,sans-serif'
  }
}
// CONFIGS
const themeConfig = {
  disableCustomizer : false,       // options[Boolean] : true, false(default)
  disableThemeTour  : false,        // options[Boolean] : true, false(default)
  footerType        : "static",    // options[String]  : static(default) / sticky / hidden
  hideScrollToTop   : false,       // options[Boolean] : true, false(default)
  mainLayoutType    : "vertical",  // options[String]  : vertical(default) / horizontal
  navbarColor       : "#fff",      // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType        : "floating",  // options[String]  : floating(default) / static / sticky / hidden
  routerTransition  : "zoom-fade", // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  sidebarCollapsed  : false,       // options[Boolean] : true, false(default)
  theme             : "light",     // options[String]  : "light"(default), "dark", "semi-dark"

  // Not required yet - WIP
  userInfoLocalStorageKey: "userInfo",
  colors            :colors,
  progressType      :progressType,
  font              :API_CONFIGS.font || '"Montserrat",Helvetica,Arial,sans-serif',
  isCustom          : false
  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
}

export default themeConfig
