var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mainLayoutType === "horizontal" && _vm.windowWidth >= 1200
    ? _c("div", { staticClass: "relative navBarLayout" }, [
        _c(
          "div",
          { staticClass: "vx-navbar-wrapper navbar-full p-0" },
          [
            _c(
              "vs-navbar",
              {
                staticClass: "navbar-custom navbar-skelton p-0 pl-4",
                class: _vm.navbarClasses,
                style: _vm.navbarStyle,
                attrs: { color: _vm.navbarColor },
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "vx-logo cursor-pointer mx-auto flex items-center",
                    attrs: { tag: "div", to: "/" },
                  },
                  [
                    _vm.logoFileName
                      ? _c("img", {
                          staticClass: "mr-1",
                          attrs: {
                            src: "static/" + _vm.logoFileName,
                            width: "40px",
                          },
                        })
                      : _c("logo", {
                          staticClass: "w-10 mr-4 fill-current text-primary",
                        }),
                    _vm._v(" "),
                    _c("span", { staticClass: "vx-logo-text text-primary" }, [
                      _vm._v(_vm._s(_vm.appName)),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.layerData &&
                ["container", "cell", "actionButton"].includes(
                  _vm.layerData.type
                ) &&
                _vm.layerData.columns
                  ? _c(
                      "div",
                      {
                        staticClass: "container",
                        class: _vm.layerData.class,
                        attrs: {
                          containerid: _vm.layerData.id,
                          "data-containerid": "1",
                          "data-type": "container",
                        },
                      },
                      _vm._l(_vm.layerData.columns, function (column) {
                        return _c(column.type, {
                          key: column.id + "h",
                          tag: "component",
                          attrs: { layerData: column },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.layerData && _vm.layerData.type === "item"
                  ? _c(
                      "div",
                      {
                        staticClass: "container",
                        class: _vm.layerData.class,
                        attrs: {
                          containerid: _vm.layerData.id,
                          "data-containerid": "1",
                          "data-type": "container",
                        },
                      },
                      [
                        _c(_vm.layerData.type, {
                          tag: "component",
                          attrs: { layerData: _vm.layerData },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }