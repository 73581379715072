export const builderMessage = {
    add: {
        inside: 'Content will be place inside this content',
        before: 'Content will be place before this content',
        after: 'Content will be place after this content',
        end: 'Content will be place at the end this content',
        new: 'Add a content',
    }
};

export const themes = ['flat']

export const colorCodes = {
  'red'     :'#FF0000',
  'white'   :'#FFFFFF',
  'silver'  :'#C0C0C0',
  'black'   :'#000000',
  'maroon'  :'#800000',
  'yellow'  :'#FFFF00',
  'lime'    :'#00FF00',
  'orange'  :'#FF8040',
  'green'   :'#008000',
  'blue'    :'#0000FF',
  'magenta' :'#FF00FF',
  'brown'   :'#A52A2A',
  'cyan'    :'#00FFFF',
  'gray'    :'#808080',
  'grey'    :'#808080',
  'violet'  :'#8D38C9',
  'purple'  :'#8E35EF',
  'pink'    :'#FAAFBE',
  'rose'    :'#E8ADAA'
}