var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "con-vs-slider",
      class: [`vs-slider-${_vm.color}`, { disabledx: _vm.disabled }],
      on: {
        mousewheel: function ($event) {
          $event.preventDefault()
          return _vm.mousewheelx.apply(null, arguments)
        },
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft",
              ])
            )
              return null
            if ("button" in $event && $event.button !== 0) return null
            return _vm.keydownLeft.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "right", 39, $event.key, [
                "Right",
                "ArrowRight",
              ])
            )
              return null
            if ("button" in $event && $event.button !== 2) return null
            return _vm.keydownRight.apply(null, arguments)
          },
        ],
      },
    },
    [
      _c(
        "button",
        {
          ref: "slider",
          staticClass: "vs-slider",
          attrs: { disabled: _vm.disabled, type: "button" },
          on: {
            click: function ($event) {
              _vm.clickSlider($event), (_vm.actived = true)
            },
          },
        },
        [
          _c("span", {
            staticClass: "vs-slider-line-one",
            class: { hasTransition: _vm.effect },
            style: _vm.styleLineOne,
          }),
          _vm._v(" "),
          _c("span", { staticClass: "vs-slider-line-two" }),
          _vm._v(" "),
          _c("span", {
            staticClass: "vs-slider-line-efect",
            class: { "run-effect": _vm.effect },
            style: _vm.styleEfect,
          }),
          _vm._v(" "),
          _vm._l(_vm.countTicks, function (tick, index) {
            return _vm.ticks && tick
              ? _c("span", {
                  staticClass: "vs-slider--tick",
                  class: { isEnd: index == _vm.countTicks - 1 },
                  style: _vm.styleTicks(index),
                })
              : _vm._e()
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          ref: "circle1",
          staticClass:
            "vs-circle-slider vs-circles-slider vs-slider--circles vs-slider--circle",
          class: {
            hasTransition: _vm.effect,
            isEquals: _vm.isEquals,
            changeValue: _vm.changeValue,
            isEndValue: _vm.value == _vm.max,
          },
          style: _vm.styleCircle,
          attrs: { disabled: _vm.disabled, type: "button" },
          on: {
            touchstart: function ($event) {
              _vm.activeFocus($event), (_vm.actived = true)
            },
            mousedown: function ($event) {
              _vm.activeFocus($event), (_vm.actived = true)
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "text-circle-slider vs-slider--circle-text",
              style: _vm.styleText,
            },
            [
              _vm._v("\n      " + _vm._s(_vm.valueCircle1) + "\n      "),
              _vm.textFixed
                ? _c("span", [
                    _vm._v("\n        " + _vm._s(_vm.textFixed) + "\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("vs-icon", {
                attrs: { "icon-pack": _vm.iconPack, icon: _vm.icon },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      Array.isArray(_vm.value)
        ? _c(
            "button",
            {
              ref: "circle2",
              staticClass:
                "vs-circle-slider-two vs-circles-slider vs-slider--circles vs-slider--circle-two",
              class: {
                hasTransition: _vm.effect,
                isEquals: _vm.isEquals,
                changeValue: _vm.changeValue,
                isEndValue: _vm.value == _vm.max,
              },
              style: _vm.styleCircleTwo,
              attrs: { disabled: _vm.disabled, type: "button" },
              on: {
                touchstart: function ($event) {
                  _vm.activeFocus($event),
                    (_vm.two = true),
                    (_vm.actived = true)
                },
                mousedown: function ($event) {
                  _vm.activeFocus($event),
                    (_vm.two = true),
                    (_vm.actived = true)
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "text-circle-slider vs-slider--circle-text",
                  style: _vm.styleText,
                },
                [
                  _vm._v("\n      " + _vm._s(_vm.valueCircle2) + "\n      "),
                  _vm.textFixed
                    ? _c("span", [
                        _vm._v(
                          "\n        " + _vm._s(_vm.textFixed) + "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.icon
                    ? _c(
                        "i",
                        {
                          staticClass: "material-icons notranslate",
                          attrs: { translate: "no" },
                        },
                        [_vm._v("\n        " + _vm._s(_vm.icon) + "\n      ")]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }