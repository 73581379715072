import LoadingState from "@/components/gadgets/loading"
const revotioSidebarGroup = () => ({
    component: import ('./revotioSidebarGroup'),
    loading: LoadingState,
    timeout: 3000
})
const revotioSidebarItem = () => ({
    component: import ('./revotioSideItem'),
    loading: LoadingState,
    timeout: 3000
})
const item = () => ({
    component: import ('./item'),
    loading: LoadingState,
    timeout: 3000
})
const group = () => ({
    component: import ('./group'),
    loading: LoadingState,
    timeout: 3000
})
const VNavMenu = () => ({
    component: import ('@/components/pageTemplate/menu/layout/vertical/VerticalNavMenu'),
    loading: LoadingState,
    timeout: 3000
})
const VNavMenuGroup = () => ({
    component: import ('@/components/pageTemplate/menu/layout/vertical/VerticalNavGroup'),
    loading: LoadingState,
    timeout: 3000
})
const VNavMenuItem = () => ({
    component: import ('@/components/pageTemplate/menu/layout/vertical/VerticalNavMenuItem'),
    loading: LoadingState,
    timeout: 3000
})
const VuePerfectScrollbar = () => ({
    component: import ('vue-perfect-scrollbar'),
    loading: LoadingState,
    timeout: 3000
})
const HNavMenu = () => ({
    component: import ('@/components/pageTemplate/menu/layout/horizontal/HorizontalNavMenu.vue'),
    loading: LoadingState,
    timeout: 3000
})

export {
    revotioSidebarGroup,
    revotioSidebarItem,
    item,
    group,
    VNavMenu,
    VNavMenuGroup,
    VNavMenuItem,
    VuePerfectScrollbar,
    HNavMenu
}