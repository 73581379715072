import Fingerprint2 from 'fingerprintjs2'
// import AuthModule from '@/store/auth.module'

const authObject = {
  setAuthInfo () {
    return new Promise(resolve => {
      let d1 = new Date()
      let details = {}
      var options = {}
      Fingerprint2.getPromise(options).then(function (components) {
        let d2 = new Date()
        let timeString = d2 - d1
        var values = components.map(function (component) { return component.value })
        var murmur = Fingerprint2.x64hash128(values.join(''), 31)
        if (typeof window.console !== 'undefined') {
          for (var index in components) {
            if (components.hasOwnProperty(index)) {
              var obj = components[index]
              var value = obj.value
              //var line = value.toString().substr(0, 100)
              //details[obj.key] = line
            }
          }
        }
        details.result = murmur
        details.timeString = timeString
        resolve({details: details, deviceId: murmur, revotioSessionId: authObject.getObjectId()})
      })
    })
  },
  getObjectId () {
    var rnd = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (r16) {
      return Math.floor(r16).toString(16)
    }
    return (rnd(Date.now() / 1000) + ' '.repeat(16).replace(/./g, function () {
      return rnd(Math.random() * 16)
    }))
  }
}
export default authObject
