var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modalGadaget", attrs: { gid: _vm.layerData.id } },
    [
      _c(
        "vs-popup",
        {
          key: "modal" + "-" + _vm.keyIndex,
          staticClass: "modalgadget",
          class: _vm.classes,
          style: _vm.gadgetStyles,
          attrs: {
            styleContent: _vm.styleContent,
            "background-color-popup": _vm.backgroundColorPopup,
            title: _vm.popupTitle,
            fullscreen: _vm.fullscreen,
            gid: _vm.layerData.id,
            active: _vm.modalShow,
          },
          on: {
            "update:active": function ($event) {
              _vm.modalShow = $event
            },
          },
        },
        [
          _c(
            "vs-icon",
            {
              ref: "btnclose",
              staticClass: "vs-popup--close vs-popup--close--icon workingclose",
              class: { hidden: _vm.noClose },
              attrs: { "icon-pack": "material-icons", icon: "close" },
              on: { click: _vm.close },
            },
            [_vm._v("close")]
          ),
          _vm._v(" "),
          _vm.formatModalContent
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.formatModalContent) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.layerData.columns, function (column) {
            return _c(column.type, {
              key: column.id + "-" + _vm.keyIndex,
              tag: "component",
              attrs: {
                isModal: true,
                renderLocation: _vm.renderLocation,
                renderlocId: _vm.renderlocId,
                layerData: column,
                gIndex: _vm.gIndex,
              },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }