/*=========================================================================================
  File Name: item.module.js
  Description: Token State
  we use the state status inorder for the system to know if the token has already been set or not
  ----------------------------------------------------------------------------------------
==========================================================================================*/
import {TokenApiService} from '@/common/api.service'
import TokenService from '@/common/token.service'

const state = {
  items: []
}

const actions = {
  setItems (context, items) {
    context.commit('setItems', items)
  },
  setItem (context, item) {
    context.commit('setItem', item)
  },
  async setActionItem (context, item) {
    try {
      await TokenApiService.set(item).then(({ data }) => {
        TokenService.saveToken(data.token)
        context.commit('setTokenAvailability', true)
      }).catch(error => {
        throw new Error(error)
        // TODO SHOW ERROR
      })
    } catch (err) {
      console.error(err)
      // TODO SHOW ERROR
    }
  }
}
const getters = {
  getItems (state) {
    return state
  },
  getItem: (state) => item => {
    if (state.items[item]) {
      return state.items[item]
    }
    return ''
  }
}
const mutations = {
  setItems (state, items) {
    state.items = items
  },
  setItem (state, item) {
    state.items[Object.keys(item)] = item[Object.keys(item)]
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  getters,
  mutations
}
