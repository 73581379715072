<template>
  <div class="modalGadaget" :gid="layerData.id" >
    <vs-popup :styleContent="styleContent"  :background-color-popup="backgroundColorPopup" :title="popupTitle"
    v-bind:fullscreen="fullscreen" :gid="layerData.id"
    class="modalgadget"
    :key="'modal' + '-' + keyIndex"
    :active.sync="modalShow" v-bind:style="gadgetStyles" :class="classes">
    <vs-icon ref="btnclose" icon-pack="material-icons" icon="close" :class="{hidden:noClose}" class="vs-popup--close vs-popup--close--icon workingclose" @click="close">close</vs-icon>
    <div v-html="formatModalContent" v-if="formatModalContent"/>
    <component
    :is="column.type" v-for="column in layerData.columns" :isModal="true"
    :renderLocation="renderLocation" :renderlocId="renderlocId"
    v-bind:key="column.id + '-' + keyIndex" v-bind:layerData="column" :gIndex="gIndex"></component>
    </vs-popup>
  </div>
</template>

<script>
import fn from '@/common/lib.service'
import { mapGetters } from 'vuex'
import ThemeService from '@/common/theme.service'
let themeConfigs = ThemeService.getThemeConfigs()
export default {
  name: 'modal',
  computed: {
    // a computed getter
    ...mapGetters(['revotioPageData', 'revotioGadgetData']),
    gadgetStyles: function () {
      return fn.pageMergeDesign(this.revotioPageData, this.layerData)
    },
    fullscreen () {
      if (this.gadgetOptions && this.gadgetOptions.hasOwnProperty('fullscreen')) {
        return this.gadgetOptions.fullscreen
      } else return false
    },
    noClose() {
      if (this.gadgetOptions.hasOwnProperty('noClose')) {
        return this.gadgetOptions.noClose
      } else return false
    },
    popupTitle () {
      return this.gadgetData.title || ''
    },
    classes () {
      let classes = 'modal '
      if (this.gadgetOptions.hasOwnProperty('position')) {
        classes += 'modal_' + this.gadgetOptions.position
      }
      return classes
    },
    backgroundColorPopup () {
      if (this.gadgetOptions.hasOwnProperty('bodyBackground')) {
        if (themeConfigs.colors.hasOwnProperty(this.gadgetOptions.bodyBackground))
        return themeConfigs.colors[this.gadgetOptions.bodyBackground]
        else return this.gadgetOptions.bodyBackground
      }
      return ''
    },
    styleContent (){
      let styles = ""
      if (this.gadgetOptions.hasOwnProperty('style')) {
        for (let i in this.gadgetOptions.style) {
          styles += i + ':' + this.gadgetOptions.style[i] + ';'
        }
      }
      if (this.gadgetOptions.hasOwnProperty('bodyBackground')) {
        if (themeConfigs.colors.hasOwnProperty(this.gadgetOptions.bodyBackground))
        return styles += 'background-color:' + themeConfigs.colors[this.gadgetOptions.bodyBackground] + ';'
        else return styles +=  'background-color:' + this.gadgetOptions.bodyBackground + ';'
      }
      else return styles
    },
    formatModalContent () {
      if(this.gadgetData.modalContent) {
        return this.gadgetData.modalContent.replace(/"\/wiki\//g,'"https://revotio.atlassian.net/wiki/')
      }
      return ''
    }
  },
  watch: {
    revotioPageData (newb, oldb) {
      if (newb) {
        this.$nextTick(()=>{
          this.modalShow = false
        })
      }
      this.$destroy()
    },
    revotioGadgetData (newVal, oldVal) {
      this.gadgetInfo = {}
      this.gadgetData = {}
      this.getGadgetContent = {}
      this.gadgetOptions = {}
      if (newVal.hasOwnProperty('timeReceived')) {
        newVal.gadgetsList = newVal.gadgetsList.filter(i=>i.timeReceived === newVal.timeReceived)
      }
      this.fillgadget(newVal)
    },
    modalShow () {
      if (this.modalShow === true) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }
  },
  props: {
    layerData: Object,
    gIndex: [Number, String],
    renderLocation: {type: String, default:'modal'},
    renderlocId: {type: String, default:''}
  },
  methods: {
    showModal () {
      if (this.gadgetData.hasOwnProperty('modalStatus')) {
        if (this.gadgetData.modalStatus === 'show') {
          this.modalShow = true
        } else {
          this.$nextTick(()=>{
            this.modalShow = false
          })
        }
      }
    },
    displayTime () {
      if (this.gadgetData.hasOwnProperty('displayTime')) {
        setTimeout(() => {
          this.modalShow = false
        }, this.gadgetData.displayTime * 1000)
      }
    },
    disableclose () { // NOTE when vuesax already added the option to click outside remove this
      if (this.gadgetInfo !== undefined) {
        let clickOutside = this.gadgetOptions.hasOwnProperty('clickOutsideToClose') ? this.gadgetOptions.clickOutsideToClose : true
        if (!clickOutside || this.noClose) {
          this.hideclose = true
          let popheader = document.querySelectorAll('.modalgadget .vs-popup--header')
          let workingclose = document.getElementsByClassName('workingclose')
          for (let i = 0; i < popheader.length; i++) {
            popheader[i].appendChild(workingclose[i])
          }
          this.$children[0].$on('close', e => {
            this.modalShow = true
          })
        }
        if (clickOutside) {
          let popheader = document.querySelectorAll('.modalgadget .vs-popup--header')
          let workingclose = document.getElementsByClassName('workingclose')
          for (let i = 0; i < popheader.length; i++) {
            popheader[i].appendChild(workingclose[i])
          }
        }
      }
    },
    close () {
      this.$nextTick(()=>{
        this.modalShow = false
      })
    },
    fillgadget (newVal) {
      if (newVal.hasOwnProperty('gadgetsList') && fn.getGadgetInfo(newVal.gadgetsList, this.layerData.id).hasOwnProperty('gadgetId')) {
        this.gadgetInfo = fn.getGadgetInfo(newVal.gadgetsList, this.layerData.id)
        this.gadgetData = fn.getGadgetData(this.gadgetInfo)
        this.getGadgetContent = fn.getGadgetContent(this.gadgetInfo)
        this.gadgetOptions = fn.getGadgetOptions(this.gadgetInfo)
        this.showModal()
        this.displayTime()
        this.disableclose()
        this.$nextTick(()=> {
          let gadgetID = {num:1,g:this.gadgetInfo.gadgetId}
          this.$root.$emit('gadgetRendered',gadgetID)
        })
      } else {
         this.$nextTick(()=>{
          this.modalShow = false
        })
      }
    }
  },
  mounted () {
    this.$root.$on('modal-' + this.layerData.id, data => {
      this.$nextTick(()=>{
        this.modalShow = data // true or false
      })
    })
    this.gadgetInfo = {}
    this.gadgetData = {}
    this.getGadgetContent = {}
    this.gadgetOptions = {}
    this.fillgadget(this.revotioGadgetData)
  },
  beforeDestroy () {
    this.$nextTick(()=>{
      this.modalShow = false
    })
    // this makes sure that the modal has been removed since the modal is different component
    document.querySelector("div[gid='"+this.layerData.id+"']").remove()
    document.body.classList.remove('overflow-hidden')
  },
  components: {
    cell: () => import('./cell'),
    container: () => import('./container'),
    item: () => import('./item'),
    portlet: () => import('@/components/gadgets/portlet')
  },
  data () {
    return {
      gadgetInfo: {},
      gadgetData: {},
      gadgetOptions: {},
      modalShow: false,
      hideclose: false,
      keyIndex: 0
    }
  }
}
</script>
<style>
.vs-popup--close.vs-popup--close--icon.workingclose.material-icons.null {
    display: block;
}
.vs-popup--close.vs-popup--close--icon.material-icons.null {
    display: none;
}
</style>
