var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-nav-group cursor-pointer",
      class: [
        { "h-nav-group-open": _vm.openItems },
        { "h-nav-group-active": _vm.open },
        { "disabled-item pointer-events-none": _vm.group.isDisabled },
        { relative: _vm.level > 1 },
        `lvl:${_vm.level}`,
      ],
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.mouseover.apply(null, arguments)
        },
        mouseleave: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.mouseout.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "group-header w-full flex items-center",
          class: `lvl:${_vm.level}`,
        },
        [
          _c(
            "span",
            {
              staticClass: "flex items-center w-full",
              class: `lvl:${_vm.level}`,
            },
            [
              _vm.icon !== "" && _vm.icon !== null
                ? _c("vs-avatar", {
                    class: `lvl:${_vm.level}`,
                    attrs: { color: "primary", icon: _vm.icon, size: "small" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.icon === "" || _vm.icon === null
                ? _c("vs-avatar", {
                    class: `lvl:${_vm.level}`,
                    attrs: { src: _vm.src, icon: _vm.icon, size: "small" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "truncate mr-3 select-none",
                  class: `lvl:${_vm.level}`,
                },
                [_vm._v(_vm._s(_vm.group.name))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("feather-icon", {
            class: [
              { rotate90: (_vm.open && _vm.level > 2) || _vm.openItems },
              "feather-grp-header-arrow",
              `${_vm.level}lvl`,
            ],
            attrs: {
              icon: _vm.bottom ? "ChevronDownIcon" : "ChevronRightIcon",
              "svg-classes": "w-4 h-4",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showloading
        ? _c(
            "vs-progress",
            { attrs: { indeterminate: "", color: "primary" } },
            [_vm._v("primary")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.openItems,
                expression: "openItems",
              },
            ],
            ref: "childDropdown",
            staticClass: "h-nav-group-items h-nav-menu-dd py-2",
            class: [
              _vm.level > 0
                ? "ml-4 mr-4 norightborder"
                : "absolute shadow-drop",
            ],
            style: _vm.styleItems,
          },
          _vm._l(_vm.group.inner, function (groupItem, index) {
            return _c(
              "li",
              { key: index },
              [
                _vm.getItem(groupItem) == "group"
                  ? _c("h-nav-menu-group", {
                      attrs: {
                        group: groupItem,
                        groupIndex: Number(`${_vm.groupIndex}.${index}`),
                        open: _vm.isGroupActive(groupItem),
                        openHover: _vm.openHover,
                        level: _vm.currentLevel,
                      },
                    })
                  : _c(
                      "h-nav-menu-item",
                      {
                        attrs: {
                          "icon-small": "",
                          index: _vm.groupIndex + "." + index,
                          to:
                            groupItem.slug !== "external"
                              ? groupItem.url
                              : null,
                          href:
                            groupItem.slug === "external"
                              ? groupItem.url
                              : null,
                          icon: _vm.itemIcon,
                          slug: groupItem.slug,
                          target: groupItem.target,
                          item: groupItem,
                        },
                      },
                      [
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v(_vm._s(groupItem.name)),
                        ]),
                        _vm._v(" "),
                        groupItem.tag
                          ? _c(
                              "vs-chip",
                              {
                                staticClass: "ml-auto",
                                attrs: { color: groupItem.tagColor },
                              },
                              [_vm._v(_vm._s(groupItem.tag))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }