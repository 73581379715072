/* eslint-disable */
/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import ThemeService from '@/common/theme.service'

const mutations = {


  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val
    ThemeService.updateTheme('mainLayoutType',val)
  },
  UPDATE_PROGRESS_TYPE(state, val) {
    state.progressType = val
    ThemeService.updateTheme('progressType',val)
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width
  },
  UPDATE_PROGRESS_STATUS(state,val) {
    state.progressShow = val
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages     = list.concat(starredPagesMore)
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) { state.bodyOverlay       = val },
  UPDATE_PRIMARY_COLOR(state, val)   {
    state.themePrimaryColor = val
   },
  UPDATE_SECONDARY_COLOR(state, val)   {
    state.themeSecondaryColor = val
  },
  UPDATE_BUTTON_COLOR(state, val)   {
    state.themeButtonColor = val
  },
  UPDATE_TEXT_COLOR(state, val)   {
    state.themeTextColor = val
  },
  UPDATE_FONT_FAMILY(state, val)   {
    state.themeFontFamily = val
  },
  UPDATE_THEME(state, val)           {
    state.theme             = val
    ThemeService.updateTheme('theme',val)
   },
  UPDATE_DEFAULT_THEME(state, isCustom) {
    ThemeService.updateTheme('isCustom',isCustom)
    state.isCustom = isCustom
  },
  UPDATE_WINDOW_WIDTH(state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y(state, val) { state.scrollY = val },

  UPDATE_COLOR(state, val)   { state.themeColors = val },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, payload) {

    // Get Data localStorage
    let userInfo = JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser

    for (const property of Object.keys(payload)) {

      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }


    }
    // Store data in localStorage
    localStorage.setItem("userInfo", JSON.stringify(userInfo))
  },
}

export default mutations

