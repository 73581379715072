
import { JWTApiService } from '@/common/jwt.service.js'
const REVOTIO_TOKEN_KEY = 'revotio_token'
const REVOTIO_CACHE = 'revotio_cache'
const REVOTIO_GDG_TOKEN = 'gdg'
export const getToken = () => {
  return window.localStorage.getItem(REVOTIO_TOKEN_KEY)
}

export const saveToken = token => {
  window.localStorage.setItem(REVOTIO_TOKEN_KEY, token)
}

export const destroyToken = (reload = false) => {
  window.localStorage.removeItem(REVOTIO_TOKEN_KEY)
  if (reload) {
    window.onbeforeunload = null;
    let isexport = window.location.href.includes('/export') ? true : false
    const exparam = window.location.href.substring(window.location.href.indexOf('/export') )
    const historystate = `${isexport ? exparam : '/'}`
    window.history.replaceState(window.location.pathname, "", historystate)
    // location.reload()
  }

}

export const getPathParam = () => {
  var path = window.location.pathname
  var pathParam = path.substr(1, path.indexOf('='))
  return pathParam
}
export const getParamValue = (name, url) => {
  const newurl = url ? url : window.location.pathname
  var params = newurl.substr(1).split('&')
  for (var i = 0; i < params.length; i++) {
    var p = params[i].split('=')
    if (p[0] === name) {
      const moreparams =p[1].split('/')
      return moreparams.length > 1 ? moreparams[0] : decodeURIComponent(p[1])
    }
  }
  return false
}

export const getGadgets = () => {
  try {
    let gadgets = JWTApiService.decrypt(window.localStorage.getItem(REVOTIO_CACHE),REVOTIO_GDG_TOKEN)
    if (gadgets.body.data === undefined) return []
    return gadgets.body.data
  } catch(e) {
    return {}
  }
}

export const saveGadgets = (gadgets) => {
  let gadgetLists = JSON.parse( JSON.stringify(getGadgets()))
  let gagetList1 =new Array();
  if(gadgetLists[0] === undefined) {
    gadgetLists = new Array();
  }
  gadgets.gadgetsList.forEach(i=> {
    let arr = undefined
    if (i.hasOwnProperty('gIndex')) {
      arr = gadgetLists.findIndex(g=>g.gadgetId === i.gadgetId && i.gIndex==g.gIndex)
    } else {
      arr = gadgetLists.findIndex(g=>g.gadgetId === i.gadgetId)
    }
    const gadget = JSON.parse(JSON.stringify(i))
    if (arr == -1 && typeof i === 'object') {
      gagetList1.push(gadget)
    } else if (typeof i === 'object') {
      gadgetLists[arr] = gadget
    }
  })
  gadgetLists = JSON.parse( JSON.stringify(getGadgets()))
  gagetList1 = [...gadgetLists,...gagetList1]
  let gadgetEncrypt = JWTApiService.encrypt({data:gagetList1},REVOTIO_GDG_TOKEN)
  gadgetEncrypt.then(i => {
    window.localStorage.setItem(REVOTIO_CACHE, i)
  })
}
export default {
  getToken,
  saveToken,
  destroyToken,
  getPathParam,
  getParamValue,
  saveGadgets,
  getGadgets
}
