<template>
  <div id="parentx menuGadget" v-if="showMenu">
    <!-- <vs-sidebar :position-right="positionRight" :hidden-background="backgroundhidden" :reduce="reduce" :reduce-not-hover-expand="notExpand"   color="success" class="sidebarx" spacer v-model="active">
      <div class="header-sidebar" slot="header">
        <vs-avatar  size="70px" :src="src" />
      </div>
      <component :is="getItem(menu)"  :item="menu" v-for="(menu, menuIndex) in menuObject" :index="menuIndex" v-bind:key="menuIndex"/>
    </vs-sidebar> -->
    <v-nav-menu
      :navMenuItems = "menuObject"
      :enableThemeMenu = "enableThemeMenu"
      :enableAppTour = "enableAppTour"
      :title         = "appName"
      parent        = ".layout--main" />
  </div>
</template>

<script>
import fn from '@/common/lib.service'
import * as components from './menu'
import im from '@/common/images.service'
import {PageApiService} from '@/common/api.service'
import {
  API_CONFIG
} from '@/common/configs'
import {
  mapGetters,
  mapState
} from 'vuex'
export default {
  name: 'revotiomenu',
  computed: {
    ...mapGetters(['revotioFingerPrint', 'revotioDeviceID', 'revotioAuthInfo', 'revotioPageData', 'revotioGadgetData']),
    ...mapState(['browserFingerPrint', 'tokenIsSet']),
    pageContainer: function () {
      return this.revotioPageData.presentationLayer.content.container
    },
    pageClass: function () {
      return this.revotioPageData.Page.split(' ').join('-')
    },
    mainLayoutType()  { return this.$store.state.mainLayoutType  }
  },
  watch: {
    revotioPageData (newb, oldb) {
      // lets check if has container
      this.initializeMenu()
    },
    revotioGadgetData (newb, oldb) {
      // lets check if has container
      if (newb.hasOwnProperty('gadgetsList') && fn.getGadgetInfo(newb.gadgetsList, 'menu').hasOwnProperty('gadgetId')) {
        this.initializeMenu(true)
      }
    }
  },
  components,
  created () {
    this.initializeMenu()
  },
  methods: {
    initializeMenu (gadgetonly = false) {
      // settigs
      let menuSettings = this.revotioPageData.presentationLayer && this.revotioPageData.presentationLayer.menuSettings ? this.revotioPageData.presentationLayer.menuSettings : {}
      this.enableAppTour = menuSettings.tourToggle || false
      this.showMenu = menuSettings.visible || false
      if (gadgetonly) {
        let gadgetInfo = fn.getGadgetInfo(this.revotioGadgetData.gadgetsList, 'menu')
        let gadgetOptions = gadgetInfo.gadgetOptions || {}
        if (gadgetOptions && gadgetOptions.hasOwnProperty('visible')) {
          this.showMenu = gadgetOptions.visible
        }
      }
    },
    getItem (ln) {
      if (ln.inner.length > 0 || ln.lazyload) {
        return 'group'
      } else {
        return 'item'
      }
    },
    async getFile () {
      let fileget = {
        servetype: 'file',
        path: this.revotioPageData.UserData.avatar
      }
      let auth = this.$store.state.auth
      await PageApiService.serveFileV2(fileget,auth).then((response) => {
        let data = response.data
        if (!data.hasOwnProperty('error')) {
          im.addImage(data.file, data)
          this.src = im.getImage(data.file)
        }
      }).catch(error => {
        throw new Error(error)
        // TODO SHOW ERROR
      })
    }
  },
  data () {
    return {
      active: true,
      reduce: true,
      notExpand: false,
      backgroundhidden: true,
      showMenu: false,
      gadgetInfo: {},
      gadgetContent: {},
      gadgetSettings: {},
      // menuObject: [],
      positionRight: false,
      src: '',
      appName: API_CONFIG.appName,
      enableThemeMenu:API_CONFIG.enableThemeMenu,
      enableAppTour:API_CONFIG.enableAppTour || true,
    }
  },
  props: {
    menuObject: { inheritAttrs: false }
  }

}
</script>
<style>

</style>
