// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#app > .vs-progress--background {
  z-index: 20000;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/App.vue","webpack://./App.vue"],"names":[],"mappings":"AAuCA;EACI,cAAA;EACI,cAAA;ACtCR","sourcesContent":["\n\n$authbg: '../../assets/img/auth-bg.jpg';\n$authbgDark: '../../assets/img/authbgdark2.png';\n$mobile: '../../assets/img/mobile2.jpg';\n\n// base color settings;\n// $primary: rgb(23, 201, 100);\n// $success: rgb(134,184,127);\n// $danger: rgb(184,127,127);\n// $warning: rgb(193,144,73);\n// $dark: rgb(0, 99, 41);\n// $info: rgb(3, 14, 146);\n\n//fonts settings\n$fontfamily: var(--vs-font-family);\n$font-color:  rgba(var(--vs-textColor),1);\n\n//sizes\n$basespan: 1em;\n$mediumspan: 2em;\n$largespan: 2em;\n$fullwidth: 100%;\n$largewidth: 80%;\n$smallwidth: 20%;\n\n$breakpoint-xl: 1280px;\n$breakpoint-lg: 1024px;\n$breakpoint-md: 960px;\n$breakpoint-sm: 520px;\n$breakpoint-xs: 460px;\n$breakpoint-xxs: 320px;\n\n$builderdarker : #272727 ;\n$builderdark : #9a9a9a;\n$builderlight : #cdcdcd;\n\n\n// @import \"./assets/styles/global.scss\";\n#app >.vs-progress--background { \n    z-index: 20000;\n        display: block;\n}\n","#app > .vs-progress--background {\n  z-index: 20000;\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
