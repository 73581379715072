<!-- =========================================================================================
    File Name: VerticalNavMenuItem.vue
    Description: Vertical NavMenu item component. Extends vuesax framework's 'vs-sidebar-item' component
    Component Name: VerticalNavMenuItem
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    v-if="canSee"
    class="h-nav-menu-item"
    :class="[
      {'h-nav-active-item text-primary font-medium' : activeLink},
      {'disabled-item pointer-events-none' : isDisabled},
    ]" >

      <router-link
        v-if="to"
        exact
        :class="[{'router-link-active': activeLink}, 'nav-link flex items-center']"
        :to="to"
        :target="target" >
          <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon" />
          <feather-icon v-else :class="iconClasses" :icon="icon" />
          <slot />
      </router-link>

      <label v-else :target="target" @click="callAction" :href="href" class="nav-link flex items-center">
        <vs-avatar :src="src" size="small"  v-if="src !==''"/>
        <vs-icon
          :icon-pack="iconPack"
          :icon="icon"
          v-if="icon !==null">
        </vs-icon>
        <feather-icon v-else :class="iconClasses" :icon="icon" />
        <slot />
      </label>
  </div>
</template>

<script>
import im from '@/common/images.service'
import fn from '@/common/lib.service'
import {PageApiService} from '@/common/api.service'
import {
  mapGetters
} from 'vuex'
export default {
  name: 'v-nav-menu-item',
  props: {
    iconSmall   : { type: Boolean,                default: false            },
    iconPack    : { type: String,                 default: 'material-icons' },
    href        : { type: [String, null],         default: ''              },
    to          : { type: [String, Object, null], default: null             },
    slug        : { type: String,                 default: null             },
    index       : { type: [String, Number],       default: null             },
    featherIcon : { type: Boolean,                default: true             },
    target      : { type: String,                 default: '_self'          },
    isDisabled  : { type: Boolean,                default: false            },
    item        : { type: [Object, Array]                                   }
  },
  data () {
    return {
      src : ''
    }
  },
  mounted() {
    this.src = im.getImage(this.item.filePath)
    if (this.item.hasOwnProperty('filePath') &&  this.src === '') {
      this.getFile()
    }
  },
  computed: {
    ...mapGetters(['revotioFingerPrint', 'revotioDeviceID', 'revotioAuthInfo', 'revotioPageData', 'revotioGadgetData']),
    icon () {
      if (this.item.hasOwnProperty('iconName') && (this.item.iconName !== '' && this.item.iconName !== undefined)) {
        if (this.item.iconName.includes('fa-')) {
          return fn.convertIcon2Material(this.item.iconName)
        } else return this.item.iconName
      }
      return ''
    },
    iconClasses() {
      return this.iconSmall ? 'w-3 h-3 mr-3' : 'w-5 h-5 mr-3'
    },
    canSee() {
      return true
      // this.$acl.check(this.$store.state.AppActiveUser.userRole)
      // return this.to ? this.$acl.check(this.$router.match(this.to).meta.rule) : true
    },
    activeLink() {
      return ((this.to == this.$route.path) || (this.$route.meta.parent == this.slug) && this.to) ? true : false
    }
  },
  methods:{
    callAction () {
      if (this.item.themeTour) {
        if (!this.revotioPageData.hasOwnProperty('tour')) {
          this.revotioPageData.tour = true
        }
        let tour = this.revotioPageData.hasOwnProperty('tour') ? this.revotioPageData.tour : true
        this.revotioPageData.tour = !tour
        this.$root.$emit('appTourToggle',!tour)
      } else if (this.item.themeConfig) {
        this.$root.$emit('customizerShow',true)
      } else {
        var postData = {
          gadgetType: 'Menu',
          gadgetId: 'menu',
          gadgetName: 'menu',
          gadgetData: this.item
        }
        let actiondata = {
          payload: 'Gadget',
          postData: postData,
          authInfo: this.revotioAuthInfo
        }
        this.$store.dispatch('setActionPost', actiondata)
      }
      this.isactive = true
      this.$root.$emit('newActive',this.item)
    },
    async getFile () {
      let fileget = {
        servetype: 'file',
        path: this.item.filePath
      }
      let auth = this.$store.state.auth
      await PageApiService.serveFileV2(fileget,auth).then((response) => {
        let data = response.data
        if (!data.hasOwnProperty('error')) {
          im.addImage(data.file, data)
          this.src = im.getImage(data.file)
        }
      }).catch(error => {
        throw new Error(error)
        // TODO SHOW ERROR
      })
    },
  }
}

</script>

<style lang="scss">
@use "@/assets/scss/vuexy/components/horizontalNavMenuItem" as *;
</style>
