// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./material-icon2022.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./materialOutlined.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* fallback */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
    font-display: swap;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    /* -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased; */
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 100 700;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
  }

  .material-icons {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  }

  @font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 100 700;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/materialIcon.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,4DAAqD;IACrD,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,sBAAsB;IACtB,oBAAoB;IACpB,qBAAqB;IACrB,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;IACd;0CACsC;AAC1C;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,oBAAoB;IACpB,4DAAoD;EACtD;;EAEA;IACE,wCAAwC;IACxC,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,cAAc;IACd,oBAAoB;IACpB,sBAAsB;IACtB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,wCAAwC;IACxC,kBAAkB;IAClB,oBAAoB;IACpB,4DAAoD;EACtD","sourcesContent":["/* fallback */\n\n@font-face {\n    font-family: 'Material Icons';\n    font-style: normal;\n    font-weight: 400;\n    src: url('./material-icon2022.woff2') format('woff2');\n    font-display: swap;\n}\n\n.material-icons {\n    font-family: 'Material Icons';\n    font-weight: normal;\n    font-style: normal;\n    font-size: 24px;\n    line-height: 1;\n    letter-spacing: normal;\n    text-transform: none;\n    display: inline-block;\n    white-space: nowrap;\n    word-wrap: normal;\n    direction: ltr;\n    /* -webkit-font-feature-settings: 'liga';\n    -webkit-font-smoothing: antialiased; */\n}\n\n@font-face {\n    font-family: 'Material Icons';\n    font-style: normal;\n    font-weight: 100 700;\n    src: url('./materialOutlined.woff2') format('woff2');\n  }\n\n  .material-icons {\n    font-family: 'Material Symbols Outlined';\n    font-weight: normal;\n    font-style: normal;\n    font-size: 24px;\n    display: inline-block;\n    line-height: 1;\n    text-transform: none;\n    letter-spacing: normal;\n    word-wrap: normal;\n    white-space: nowrap;\n    direction: ltr;\n  }\n\n  @font-face {\n    font-family: 'Material Symbols Outlined';\n    font-style: normal;\n    font-weight: 100 700;\n    src: url('./materialOutlined.woff2') format('woff2');\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
