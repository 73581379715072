<template>
  <div id="app" :class="vueAppClasses">
    <vs-progress indeterminate  v-if="progressType === 'type2' && pageProgress" class="maiprogress" :color="'rgb(204 204 204 / 80%)'"></vs-progress>
    <vue-progress-bar  v-if="progressType === 'type1' && pageProgress"></vue-progress-bar>
    <router-view  @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
/* eslint-disable */
import ThemeService from '@/common/theme.service';
import API_CONFIGS from '@/common/configs/api_config';
let themeConfigs = ThemeService.getThemeConfigs()
import { mapGetters } from 'vuex'
import fn from '@/common/lib.service'
export default {
  name: 'App',
  data () {
    return {
      vueAppClasses: [],
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    }
  },
  methods: {
    toggleClassInBody (className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      }
      else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      }
      else {
        if (document.body.className.match('theme-dark'))      document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    }
  },
  mounted () {
    this.toggleClassInBody(themeConfigs.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
    const appTheme = ['flat'].includes(API_CONFIGS.theme) 
    if (appTheme) document.body.classList.add('flat')
    const apptype = fn.appType()
    const touchDevice = fn.isTouchDevice() ? 'is-mobile' : ''
    if (touchDevice) {
      document.body.classList.add(touchDevice)
    }
    document.body.classList.add(apptype)
    // for mobile
    if (touchDevice) {
      // document.addEventListener('touchend',()=> {
        // this.$nextTick(()=> {
        //   document.querySelectorAll('button.vs-button-flat.isActive').forEach(el=>{
        //     el.classList.remove('isActive')
        //     el.style.width = '';
        //     el.style.height = '';
        //   })
        // })
      // })
    }
  },
  created () {
    this.$vs.theme(themeConfigs.colors)
    this.$Progress.start()

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

  },
  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapGetters(['pageProgress']),
    progressType ()  { return this.$store.state.progressType  }
  }
}
</script>

<style lang="scss">
// @import "./assets/styles/global.scss";
#app >.vs-progress--background { 
    z-index: 20000;
        display: block;
}
</style>
