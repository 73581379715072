var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderContainer
    ? _c(
        "div",
        {
          ref: _vm.layerData.id,
          staticClass: "item",
          class: `g_${_vm.gadgetContent[0].type}`,
          style: _vm.gadgetStyles,
          attrs: {
            id: "item" + _vm.layerData.id,
            itemid: _vm.layerData.id,
            "data-itemid": "1",
            "data-type": "item",
          },
        },
        [
          !_vm.renderComponent && !_vm.seen
            ? [
                _vm.progressType === "type1"
                  ? _c(
                      "div",
                      { staticClass: "preloader flex flex-wrap" },
                      [
                        _c(
                          "vs-progress",
                          {
                            staticClass: "w-5/12 m-2 my-4",
                            attrs: {
                              indeterminate: "",
                              color: "primary",
                              height: 2,
                            },
                          },
                          [_vm._v("primary")]
                        ),
                        _vm._v(" "),
                        _c(
                          "vs-progress",
                          {
                            staticClass: "w-full m-2",
                            attrs: {
                              indeterminate: "",
                              color: "primary",
                              height: 2,
                            },
                          },
                          [_vm._v("primary")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.progressType === "type2" ? void 0 : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.renderComponent && _vm.seen
            ? _vm._l(_vm.gadgetContent, function (item) {
                return _c(item.type, {
                  key: item.id + "-" + _vm.reinitialize,
                  ref: "gadget",
                  refInFor: true,
                  tag: "component",
                  attrs: {
                    gadget: _vm.gadget,
                    "gadget-name": item.data.name || "",
                    "gadget-type": item.data.type || item.type,
                    itemData: item,
                    gid: item.id,
                    columns: item.columns,
                    gIndex: _vm.gIndex,
                    renderLocation: _vm.renderLocation,
                    renderlocId: _vm.renderlocId,
                    renderFrom: _vm.renderFrom,
                  },
                  on: {
                    "update:itemData": function ($event) {
                      item = $event
                    },
                    "update:item-data": function ($event) {
                      item = $event
                    },
                  },
                })
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }