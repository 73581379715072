import LoadingState from "./loading"
const timeout = 10000

// declare new gadget here
// to do sort this to alpahebitcal order
const apexcharts = () => ({
    component: import ('./apex-charts/apex-charts'),
    loading: LoadingState,
    timeout: timeout
})
const cardgame = () => ({
    component: import ('./card-game/card-game'),
    loading: LoadingState,
    timeout: timeout
})
const codeblock = () => ({
    component: import ('./code-block/code-block'),
    loading: LoadingState,
    timeout: timeout
})
const customform = () => ({
    component: import ('./custom-form/custom-form'),
    loading: LoadingState,
    timeout: timeout
})
const chartc3 = () => ({
    component: import ('./chartc3'),
    loading: LoadingState,
    timeout: timeout
})
const datacard = () => ({
    component: import ('./data-card/data-card'),
    loading: LoadingState,
    timeout: timeout
})
const datatable = () => ({
    component: import ('./data-table/data-table'),
    loading: LoadingState,
    timeout: timeout
})
const dynamicbtn = () => ({
    component: import ('./dynamic-btn/dynamic-btn'),
    loading: LoadingState,
    timeout: timeout
})
const dynamictextarea = () => ({
    component: import ('./dynamic-text-area'),
    loading: LoadingState,
    timeout: timeout
})
const highcharts = () => ({
    component: import ('./highcharts/highcharts'),
    loading: LoadingState,
    timeout: timeout
})
const infiniteaccordion = () => ({
    component: import ('./infinite-accordion/infinite-accordion'),
    loading: LoadingState,
    timeout: timeout
})
const maps = () => ({
    component: import ('./maps/maps'),
    loading: LoadingState,
    timeout: timeout
})
const mediaviewer = () => ({
    component: import ('./media-viewer/media-viewer'),
    loading: LoadingState,
    timeout: timeout
})
const mediarecorder = () => ({
    component: import ('./media-recorder/media-recorder'),
    loading: LoadingState,
    timeout: timeout
})
const portlet = () => ({
    component: import ('./portlet'),
    loading: LoadingState,
    timeout: timeout
})
const progressbar = () => ({
    component: import ('./progress-bar/progress-bar'),
    loading: LoadingState,
    timeout: timeout
})
const breadcrumbs = () => ({
    component: import ('./breadcrumbs/breadcrumbs'),
    loading: LoadingState,
    timeout: timeout
})
const calendar = () => ({
    component: import ('./calendar/calendar'),
    loading: LoadingState,
    timeout: timeout
})
const chat = () => ({
    component: import ('./chat/chat'),
    loading: LoadingState,
    timeout: timeout
})

const chip = () => ({
    component: import ('./chip/chip'),
    loading: LoadingState,
    timeout: timeout
})
const customitemcontainer = () => ({
    component: import ('./custom-item-container/custom-item-container'),
    loading: LoadingState,
    timeout: timeout
})
const iframecontainer = () => ({
    component: import ('./iframe-container'),
    loading: LoadingState,
    timeout: timeout
})
const divider = () => ({
    component: import ('./divider/divider'),
    loading: LoadingState,
    timeout: timeout
})
const filemanager = () => ({
    component: import ('./file-manager/file-manager'),
    loading: LoadingState,
    timeout: timeout
})
const slider = () => ({
    component: import ('./slider/slider'),
    loading: LoadingState,
    timeout: timeout
})
const tabsselect = () => ({
    component: import ('./tabs-select/tabs-select'),
    loading: LoadingState,
    timeout: timeout
})
const userslider = () => ({
    component: import ('./user-slider'),
    loading: LoadingState,
    timeout: timeout
})
const pagebuilder = () => ({
    component: import ('./page-builder/page-builder'),
    loading: LoadingState,
    timeout: timeout
})
const catalog = () => ({
    component: import ('./catalog/catalog'),
    loading: LoadingState,
    timeout: timeout
})
const xapicourse = () => ({
    component: import ('./xapi-course/xapi-course'),
    loading: LoadingState,
    timeout: timeout
})
const payment = () => ({
    component: import ('./payment/payment'),
    loading: LoadingState,
    timeout: timeout
})
const wysiwyg = () => ({
    component: import ('./wysiwyg/wysiwyg'),
    loading: LoadingState,
    timeout: timeout
})
export {
    apexcharts,
    cardgame,
    codeblock,
    customform,
    chartc3,
    datacard,
    datatable,
    dynamicbtn,
    dynamictextarea,
    highcharts,
    infiniteaccordion,
    maps,
    mediaviewer,
    mediarecorder,
    portlet,
    progressbar,
    breadcrumbs,
    calendar,
    chat,
    chip,
    customitemcontainer,
    iframecontainer,
    divider,
    filemanager,
    slider,
    tabsselect,
    pagebuilder,
    userslider,
    xapicourse,
    wysiwyg,
    payment,
    catalog
}