var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "theme-customizer" } },
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            "click-not-close": "",
            "hidden-background": "",
            "position-right": "",
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "div",
            { staticClass: "h-full" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "customizer-header mt-6 flex items-center justify-between px-6",
                },
                [
                  _c("div", [
                    _c("h4", [_vm._v("THEME CUSTOMIZER")]),
                    _vm._v(" "),
                    _c("small", [_vm._v("Customize & Preview in Real Time")]),
                  ]),
                  _vm._v(" "),
                  _c("feather-icon", {
                    staticClass: "cursor-pointer",
                    attrs: { icon: "XIcon" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.active = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-divider", { staticClass: "mb-0" }),
              _vm._v(" "),
              _c(
                "VuePerfectScrollbar",
                {
                  staticClass: "scroll-area--customizer pt-4 pb-6",
                  attrs: { settings: _vm.settings },
                },
                [
                  _c(
                    "div",
                    { staticClass: "px-6" },
                    [
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: {
                                  color: "warning",
                                  type: "filled",
                                  disabled: !_vm.isCustom,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetTheme(false)
                                  },
                                },
                              },
                              [_vm._v("Reset to Default")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v("Layout Type"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "vertical",
                                  "vs-name": "layout-type-vertical",
                                },
                                model: {
                                  value: _vm.layoutType,
                                  callback: function ($$v) {
                                    _vm.layoutType = $$v
                                  },
                                  expression: "layoutType",
                                },
                              },
                              [_vm._v("Vertical")]
                            ),
                            _vm._v(" "),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "horizontal",
                                  "vs-name": "layout-type-horizontal",
                                },
                                model: {
                                  value: _vm.layoutType,
                                  callback: function ($$v) {
                                    _vm.layoutType = $$v
                                  },
                                  expression: "layoutType",
                                },
                              },
                              [_vm._v("Horizontal")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("h4", { staticClass: "mb-4" }, [
                            _vm._v("THEME COLORS"),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v("Primary"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "clearfix" },
                            [
                              _vm._l(_vm.themeColors, function (color) {
                                return _c("li", {
                                  key: color,
                                  staticClass:
                                    "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                  class: {
                                    "shadow-outline": color == _vm.primaryColor,
                                  },
                                  style: { backgroundColor: color },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updatePrimaryColor(color)
                                    },
                                  },
                                })
                              }),
                              _vm._v(" "),
                              _c("li", {
                                staticClass:
                                  "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                class: {
                                  "shadow-outline":
                                    _vm.customPrimaryColor == _vm.primaryColor,
                                },
                                style: {
                                  backgroundColor: _vm.customPrimaryColor,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updatePrimaryColor(
                                      _vm.customPrimaryColor
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("li", { staticClass: "float-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customPrimaryColor,
                                      expression: "customPrimaryColor",
                                    },
                                  ],
                                  staticClass:
                                    "w-10 cursor-pointer h-10 rounded-lg m-2",
                                  attrs: { type: "color" },
                                  domProps: { value: _vm.customPrimaryColor },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updatePrimaryColor(
                                        _vm.customPrimaryColor
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.customPrimaryColor =
                                        $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("vs-divider", {
                            attrs: { borderStyle: "dashed" },
                          }),
                          _vm._v(" "),
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v("Secondary"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "clearfix" },
                            [
                              _vm._l(_vm.themeColors, function (color) {
                                return _c("li", {
                                  key: color,
                                  staticClass:
                                    "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                  class: {
                                    "shadow-outline": color == _vm.primaryColor,
                                  },
                                  style: { backgroundColor: color },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateSecondary(color)
                                    },
                                  },
                                })
                              }),
                              _vm._v(" "),
                              _c("li", {
                                staticClass:
                                  "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                class: {
                                  "shadow-outline":
                                    _vm.customSecondaryColor ==
                                    _vm.secondaryColor,
                                },
                                style: {
                                  backgroundColor: _vm.customSecondaryColor,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateSecondary(
                                      _vm.customSecondaryColor
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("li", { staticClass: "float-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customSecondaryColor,
                                      expression: "customSecondaryColor",
                                    },
                                  ],
                                  staticClass:
                                    "w-10 cursor-pointer h-10 rounded-lg m-2",
                                  attrs: { type: "color" },
                                  domProps: { value: _vm.customSecondaryColor },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateSecondary(
                                        _vm.customSecondaryColor
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.customSecondaryColor =
                                        $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("vs-divider", {
                            attrs: { borderStyle: "dashed" },
                          }),
                          _vm._v(" "),
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v("Button Colors"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "clearfix" },
                            [
                              _vm._l(_vm.themeColors, function (color) {
                                return _c("li", {
                                  key: color,
                                  staticClass:
                                    "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                  class: {
                                    "shadow-outline": color == _vm.buttonColor,
                                  },
                                  style: { backgroundColor: color },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateButtonDefault(color)
                                    },
                                  },
                                })
                              }),
                              _vm._v(" "),
                              _c("li", {
                                staticClass:
                                  "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                class: {
                                  "shadow-outline":
                                    _vm.customButtonColor == _vm.buttonColor,
                                },
                                style: {
                                  backgroundColor: _vm.customButtonColor,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateButtonDefault(
                                      _vm.customButtonColor
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("li", { staticClass: "float-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customButtonColor,
                                      expression: "customButtonColor",
                                    },
                                  ],
                                  staticClass:
                                    "w-10 cursor-pointer h-10 rounded-lg m-2",
                                  attrs: { type: "color" },
                                  domProps: { value: _vm.customButtonColor },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateButtonDefault(
                                        _vm.customButtonColor
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.customButtonColor =
                                        $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _c("div", [
                        _c("h5", { staticClass: "mb-4" }, [
                          _vm._v("Text Default Color"),
                        ]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "clearfix" }, [
                          _c("li", { staticClass: "float-left" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customTextColor,
                                  expression: "customTextColor",
                                },
                              ],
                              staticClass:
                                "w-10 cursor-pointer h-10 rounded-lg m-2",
                              attrs: { type: "color" },
                              domProps: { value: _vm.customTextColor },
                              on: {
                                change: function ($event) {
                                  return _vm.updateTextColor(
                                    _vm.customTextColor
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.customTextColor = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v("Font Family"),
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            staticClass: "selectExample w-full py-1 pb-1",
                            attrs: {
                              placeholder: "Condition",
                              options: _vm.fontOptions,
                            },
                            on: { input: _vm.updateFontFamily },
                            model: {
                              value: _vm.customFontFamily,
                              callback: function ($$v) {
                                _vm.customFontFamily = $$v
                              },
                              expression: "customFontFamily",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v("Theme Mode"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "light",
                                  "vs-name": "theme-mode-light",
                                },
                                model: {
                                  value: _vm.themeMode,
                                  callback: function ($$v) {
                                    _vm.themeMode = $$v
                                  },
                                  expression: "themeMode",
                                },
                              },
                              [_vm._v("Light")]
                            ),
                            _vm._v(" "),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "dark",
                                  "vs-name": "theme-mode-dark",
                                },
                                model: {
                                  value: _vm.themeMode,
                                  callback: function ($$v) {
                                    _vm.themeMode = $$v
                                  },
                                  expression: "themeMode",
                                },
                              },
                              [_vm._v("Dark")]
                            ),
                            _vm._v(" "),
                            _vm.layoutType === "vertical"
                              ? _c(
                                  "vs-radio",
                                  {
                                    attrs: {
                                      "vs-value": "semi-dark",
                                      "vs-name": "theme-mode-semi-dark",
                                    },
                                    model: {
                                      value: _vm.themeMode,
                                      callback: function ($$v) {
                                        _vm.themeMode = $$v
                                      },
                                      expression: "themeMode",
                                    },
                                  },
                                  [_vm._v("Semi Dark")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v("Progress Type"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "type1",
                                  "vs-name": "layout-type-vertical",
                                },
                                model: {
                                  value: _vm.progressType,
                                  callback: function ($$v) {
                                    _vm.progressType = $$v
                                  },
                                  expression: "progressType",
                                },
                              },
                              [_vm._v("Type 1 (default)")]
                            ),
                            _vm._v(" "),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "type2",
                                  "vs-name": "layout-type-horizontal",
                                },
                                model: {
                                  value: _vm.progressType,
                                  callback: function ($$v) {
                                    _vm.progressType = $$v
                                  },
                                  expression: "progressType",
                                },
                              },
                              [_vm._v("Type 2")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _vm.layoutType === "vertical" ? void 0 : _vm._e(),
                      _vm._v(" "),
                      _vm.layoutType === "vertical"
                        ? [
                            _c("div", { staticClass: "mt-4" }, [
                              _c("h5", [_vm._v("Navbar Color")]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "clearfix" },
                                [
                                  _c("li", {
                                    staticClass:
                                      "w-10 m-2 h-10 rounded-lg float-left cursor-pointer border border-solid d-theme-border-grey-light",
                                    class: _vm.navbarColorOptionClasses(
                                      _vm.navbarColorInitial
                                    ),
                                    style: {
                                      background: _vm.navbarColorInitial,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.navbarColorLocal =
                                          _vm.navbarColorInitial
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.themeColors, function (color) {
                                    return _c("li", {
                                      key: color,
                                      staticClass:
                                        "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                      class:
                                        _vm.navbarColorOptionClasses(color),
                                      style: { backgroundColor: color },
                                      on: {
                                        click: function ($event) {
                                          _vm.navbarColorLocal = color
                                        },
                                      },
                                    })
                                  }),
                                  _vm._v(" "),
                                  _c("li", {
                                    staticClass:
                                      "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                    class: _vm.navbarColorOptionClasses(
                                      _vm.navbarColorOptionClasses
                                    ),
                                    style: {
                                      backgroundColor: _vm.customNavbarColor,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.navbarColorLocal =
                                          _vm.customNavbarColor
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "float-left" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.customNavbarColor,
                                          expression: "customNavbarColor",
                                        },
                                      ],
                                      staticClass:
                                        "w-10 cursor-pointer h-10 rounded-lg m-2",
                                      attrs: { type: "color" },
                                      domProps: {
                                        value: _vm.customNavbarColor,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.navbarColorLocal =
                                            _vm.customNavbarColor
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.customNavbarColor =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("vs-divider"),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.layoutType === "vertical" ||
                                _vm.windowWidth < 1200
                                ? "Navbar"
                                : "Nav Menu"
                            ) + " Type"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm.layoutType === "vertical" ||
                            _vm.windowWidth < 1200
                              ? _c(
                                  "vs-radio",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false",
                                      },
                                    ],
                                    staticClass: "mr-4",
                                    attrs: {
                                      "vs-value": "hidden",
                                      "vs-name": "navbar-type-hidden",
                                    },
                                    model: {
                                      value: _vm.navbarTypeLocal,
                                      callback: function ($$v) {
                                        _vm.navbarTypeLocal = $$v
                                      },
                                      expression: "navbarTypeLocal",
                                    },
                                  },
                                  [_vm._v("Hidden")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "static",
                                  "vs-name": "navbar-type-static",
                                },
                                model: {
                                  value: _vm.navbarTypeLocal,
                                  callback: function ($$v) {
                                    _vm.navbarTypeLocal = $$v
                                  },
                                  expression: "navbarTypeLocal",
                                },
                              },
                              [_vm._v("Static")]
                            ),
                            _vm._v(" "),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "sticky",
                                  "vs-name": "navbar-type-sticky",
                                },
                                model: {
                                  value: _vm.navbarTypeLocal,
                                  callback: function ($$v) {
                                    _vm.navbarTypeLocal = $$v
                                  },
                                  expression: "navbarTypeLocal",
                                },
                              },
                              [_vm._v("Sticky")]
                            ),
                            _vm._v(" "),
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-value": "floating",
                                  "vs-name": "navbar-type-floating",
                                },
                                model: {
                                  value: _vm.navbarTypeLocal,
                                  callback: function ($$v) {
                                    _vm.navbarTypeLocal = $$v
                                  },
                                  expression: "navbarTypeLocal",
                                },
                              },
                              [_vm._v("Floating")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v("Footer Type"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "hidden",
                                  "vs-name": "footer-type-hidden",
                                },
                                model: {
                                  value: _vm.footerTypeLocal,
                                  callback: function ($$v) {
                                    _vm.footerTypeLocal = $$v
                                  },
                                  expression: "footerTypeLocal",
                                },
                              },
                              [_vm._v("Hidden")]
                            ),
                            _vm._v(" "),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "static",
                                  "vs-name": "footer-type-static",
                                },
                                model: {
                                  value: _vm.footerTypeLocal,
                                  callback: function ($$v) {
                                    _vm.footerTypeLocal = $$v
                                  },
                                  expression: "footerTypeLocal",
                                },
                              },
                              [_vm._v("Static")]
                            ),
                            _vm._v(" "),
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-value": "sticky",
                                  "vs-name": "footer-type-sticky",
                                },
                                model: {
                                  value: _vm.footerTypeLocal,
                                  callback: function ($$v) {
                                    _vm.footerTypeLocal = $$v
                                  },
                                  expression: "footerTypeLocal",
                                },
                              },
                              [_vm._v("Sticky")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("vs-divider"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-4 flex justify-between" },
                        [
                          _c("h5", { staticClass: "mb-2" }, [
                            _vm._v("Hide Scroll To Top"),
                          ]),
                          _vm._v(" "),
                          _c("vs-switch", {
                            model: {
                              value: _vm.hideScrollToTopLocal,
                              callback: function ($$v) {
                                _vm.hideScrollToTopLocal = $$v
                              },
                              expression: "hideScrollToTopLocal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("vs-divider"),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }