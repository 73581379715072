
import themeConfig from "@/../themeConfig.js"
const THEME_KEY = 'local_theme'
export const getThemeConfigs = () => {
  if (window.localStorage.getItem(THEME_KEY) === null) {
    saveThemeConfig()
  }
  let configs = JSON.parse(window.localStorage.getItem(THEME_KEY))
  if (!configs.isCustom) {
    saveThemeConfig()
    return themeConfig
  }
  const font = Object.keys(document.documentElement.style).find(i=>document.documentElement.style[i]==='--vs-font-family')
  if (!font) {
    const family = configs.font || themeConfig.font
    setStyleProperty('--vs-font-family', family)
  }
  //compare if there are new configs
  if (Object.keys(configs).length !== Object.keys(themeConfig)) {
    configs = {...themeConfig,...configs}
    saveThemeConfig(configs)
  }
  if (Object.keys(configs.colors).length !== Object.keys(themeConfig.colors)) {
    configs.colors = {...themeConfig.colors,...configs.colors}
    saveThemeConfig(configs)
  }
  return configs
}
export const saveThemeConfig = (configs = null) => {
  const theConfig = configs ? configs : themeConfig
  setStyleProperty('--vs-font-family', theConfig.font)
  window.localStorage.setItem(THEME_KEY, JSON.stringify(theConfig))
}

export function saveColor (type, color) {
  let configs = getThemeConfigs()
  if (configs.colors.hasOwnProperty(type))  {
    configs.colors[type] = color
    destroyToken()
    window.localStorage.setItem(THEME_KEY, JSON.stringify(configs))
  }
}
export function updateFont (font) {
  let configs = getThemeConfigs()
  if (configs.hasOwnProperty('font')) {
    configs.font = font
    setStyleProperty('--vs-font-family', font)
    if(typeof window !== 'undefined'){
      document.documentElement.style.setProperty('--vs-font-family', font);
    }
    destroyToken()
    window.localStorage.setItem(THEME_KEY, JSON.stringify(configs))
  }
}

export function setStyleProperty (property,value) {
  if(typeof window !== 'undefined'){
    document.documentElement.style.setProperty(property, value);
  }
}

export function updateTheme (type, val) {
  let configs = getThemeConfigs()
  if (configs.hasOwnProperty(type)){
    configs[type] = val
    destroyToken()
    window.localStorage.setItem(THEME_KEY, JSON.stringify(configs))
  }
}

export const destroyToken = () => {
  window.localStorage.removeItem(THEME_KEY)
}

export const resetTheme = () => {
  saveThemeConfig()
}
export default {
  saveThemeConfig,
  getThemeConfigs,
  saveColor,
  updateTheme,
  updateFont,
  setStyleProperty,
  resetTheme
}
