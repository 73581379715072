import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import TokenService from '@/common/token.service'
import {
  JWTApiService
} from '@/common/jwt.service'
import {
  API_CONFIG
} from '@/common/configs'
let requests = 0
let pendingSource = true
let CancelToken = axios.CancelToken;
let source = CancelToken.source();
let apiKey = API_CONFIG.appDefaultToken
const resourceEncrypt = API_CONFIG.encryptCall || ["/token","/exit"]
const publicPublisherServer = () => {
  let server = ''
  try {
    let publisherType = location.search.split('publisherType=')[1].slice(0,6)
    publisherType = publisherType || API_CONFIG.publisherType || 'private'
    if (publisherType.includes('public')) {
      server = decodeURIComponent(location.search.split('backendApi=')[1]).split('&')[0]
      server = server.includes('http') ? server : `https://${server}`
    }
    return server
  } catch(e){
    return server
  }
}
const getUrlParam = (paramName) => {
  let searchParams = new URLSearchParams(location.search);
  return searchParams.get(paramName)
}

const getWpPage = () => {
  let page = getUrlParam('wpPage') || ''
  return page
}
const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_CONFIG.baseUrl
  },
  setHeader () {
    Vue.axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    Vue.axios.defaults.headers.post['Accept-Encoding'] = 'gzip, compress, br'
  },
  query (resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error('ApiService' + `${error}`)
    })
  },
  async postAsync (endpoints, params) {
    let slug = typeof endpoints === 'object' ? endpoints.endPoint : endpoints
    if (slug.charAt(0) !== '/') slug = `/${slug}`
    if (endpoints.server && endpoints.server.slice(-1) === '/') {endpoints.server = endpoints.server.slice(0,-1)}
    let resource = endpoints.server ? `${endpoints.server}${slug}` : slug
    const isEncrypt = resourceEncrypt.includes(resource)
    const encryptedParams = isEncrypt ? {postData:await JWTApiService.encrypt(params,apiKey)} : params
    return Vue.axios.post(`${resource}`, encryptedParams).then(response=> {
      if (!isEncrypt) return response
      const decrypt = JWTApiService.decrypt(response.data, apiKey)
      return {data:decrypt.body}
    })
  },
  async post (endpoints, params, type = true, timeout = null) {
    let slug = typeof endpoints === 'object' ? endpoints.endPoint : endpoints
    if (slug.charAt(0) !== '/') slug = `/${slug}`
    if (endpoints.server && endpoints.server.slice(-1) === '/') {endpoints.server = endpoints.server.slice(0,-1)}
    let resource = endpoints.server ? `${endpoints.server}${slug}` : slug
    const resourceReset = ["/entrance"]
    const isEncrypt = resourceEncrypt.includes(resource)
    const encryptedParams = isEncrypt ? {postData:await JWTApiService.encrypt(params,apiKey)} : params
    if (type && requests > 1 && resource === '/action') {
      source.cancel('Operation canceled by the user.')
      pendingSource = false
      if (!pendingSource) {
        CancelToken = axios.CancelToken;
        source = CancelToken.source();
        pendingSource = true
      }
      return Vue.axios.post(`${resource}`, encryptedParams,{cancelToken:source.token, timeout:timeout})
      .then(response=> {
        if (!isEncrypt) return response

        const decrypt = JWTApiService.decrypt(response.data, apiKey)
        return {data:decrypt.body}
      })
      .catch(err => {
        if (JSON.stringify(err).includes('timeout') && resourceReset.includes(resource) && API_CONFIG.publisherType!=='public') {
          TokenService.destroyToken(true)
        }
        if(Vue.axios.isCancel(err)) {
          // if (requests > 1) requests--
          console.log(requests,'Previous request has automatically canceled due to new request.')
        } else {
          console.log(err)
        }
      })

    } else {
      if (!pendingSource) {
        CancelToken = axios.CancelToken;
        source = CancelToken.source();
      }
      return Vue.axios.post(`${resource}`, encryptedParams,{cancelToken:source.token, timeout:timeout})
      .then(response=> {
        if (!isEncrypt) return response

        const decrypt = JWTApiService.decrypt(response.data, apiKey)
        return {data:decrypt.body}
      })
      .catch(err => {
        if (JSON.stringify(err).includes('timeout') && resourceReset.includes(resource)) {
          TokenService.destroyToken(true)
        }
        if(Vue.axios.isCancel(err)) {
          // if (requests > 1) requests--
          console.log(requests,'Previous request has automatically canceled due to new request.')
        }
      })
    }
  },
  async directPost (resource, params, method = 'post') {
    const isEncrypt = resourceEncrypt.includes(resource)
    let wpPage = getWpPage()
    if (wpPage) {
      params.wpPage = getWpPage()
    }
    params.requestingUrl = API_CONFIG.currentHostName
    const encryptedParams = isEncrypt ? {postData:await JWTApiService.encrypt(params,apiKey)} : params
    if (!resource.includes('http')) {
      resource = `https://${resource}`
    }
    if (method === 'post') {
      return Vue.axios.post(`${resource}`, encryptedParams)
    } else {
      const params = new URLSearchParams(encryptedParams).toString();
      // console.log(resource, encryptedParams)
      if (resource.slice(-1) !== '/') {
        resource = resource + '/?' + params
      } else {
        resource = resource + '?' + params
      }
      return Vue.axios.get(`${resource}`, {})
    }
  },
  media (resource, params, changeBase = false) {
    if (changeBase && API_CONFIG.fileUrl !=='FILE_ENDPOINT_URL') {
      let url = API_CONFIG.fileUrl || API_CONFIG.baseUrl
      resource = `${url}${resource}`
    }
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  get (resource, slug,changeBase = false) {
    if (changeBase && API_CONFIG.fileUrl !=='FILE_ENDPOINT_URL') {
      let url = API_CONFIG.fileUrl || API_CONFIG.baseUrl
      resource = `${url}${resource}`
    }
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error('ApiService' + `${error}`)
    })
  }
}
export default ApiService

export const TokenApiService = {
  set (postData) {

    var sessionData = {
      deviceId: postData.deviceID,
      revotioSessionId: postData.revotioSessionId,
    }
    if (postData.hasOwnProperty('pageSessionId')) {
      sessionData.pageSessionId = postData.pageSessionId
    }
    var params = {
      type: 'generate',
      content: JSON.stringify(sessionData),
      requestingUrl:API_CONFIG.currentHostName
    }
    return ApiService.post('/token', params)
  },
  key (postData) {
    var sessionData = {	
      deviceId: postData.deviceID,	
      revotioSessionId: postData.revotioSessionId	
    }	
    var params = {	
      type: 'key',	
      content: JSON.stringify(sessionData),	
      token: TokenService.getToken(),
      requestingUrl:API_CONFIG.currentHostName
    }	
    return ApiService.post('/token', params)
  },
  validate (tokenData) {
    var params = {
      type: 'validate',
      token: tokenData,
      requestingUrl:API_CONFIG.currentHostName
    }
    return ApiService.post('/token', params)
  }
}

export const PageApiService = {
  entrance (data) {
    let postData = data.postData
    let authInfo = data.authInfo
    let sessionData = {
      BrowserId: authInfo.browserFingerPrint,
      UserId: '123',
      revotioSessionId: authInfo.revotioSessionId,
      deviceId: authInfo.deviceId,
      AppId: API_CONFIG.appId,
      entranceType: 'default',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      currentUserTime: moment().format('DD-MM-YYYY hh:mm:ss'),
      gmtTime: moment.utc(moment()).format()
    }
    if (postData.entranceType === 'activate') {
      // Add postData to sessionData
      sessionData.entranceType = postData.entranceType
      sessionData.activationToken = postData.activationToken
      // Delete postData details
      delete postData.entranceType
      delete postData.activationToken
    } else if (postData.entranceType === 'reset') {
      // Add postData to sessionData
      sessionData.entranceType = postData.entranceType
      sessionData.resetToken = postData.resetToken
      // Delete postData details
      delete postData.entranceType
      delete postData.resetToken
    } else if (postData.entranceType === 'partner') {
      // Add postData to sessionData
      sessionData.entranceType = postData.entranceType
      sessionData.partnerToken = postData.partnerToken
      // Delete postData details
      delete postData.entranceType
      delete postData.partnerToken
    }  else if (postData.entranceType === 'pageSession') {
      // Add postData to sessionData
      sessionData.entranceType = postData.entranceType
      sessionData.pageSessionId = postData.pageSessionId
      // Delete postData details
      delete postData.entranceType
      delete postData.pageSessionId
    } else if (postData.entranceType) {
      // Add postData to sessionData
      sessionData.entranceType = postData.entranceType
      // Delete postData details
      delete postData.entranceType
    }

    // Check if it has route
    if (postData.route) {
      // Add postData to sessionData
      sessionData.route = postData.route
      // Delete postData details
      delete postData.route
    }

    // check if has routeParams
    if (postData.routeParams) {
      sessionData.routeParams = postData.routeParams
      // Delete postData details
      delete postData.routeParams
    }

    // check if has token
    let token = TokenService.getToken()
    if (token) {
      sessionData.token = token
    }

    let params = {
      payload: 'Entrance',
      PostData: JSON.stringify(postData),
      SessionData: JSON.stringify(sessionData)
    }
    const key = authInfo.uid ? atob(authInfo.uid) : ''
    return JWTApiService.encrypt(params, key).then(encrypt => {
      let newdata = {
        data : encrypt,
        deviceId: sessionData.deviceId,
        revotioSessionId: sessionData.revotioSessionId,
        token: token,
        requestingUrl:API_CONFIG.currentHostName
      }
      return ApiService.post('/entrance',newdata, true, 15000)
    })
  },
  initialize (response) {
    let endPoints
    let revotioData = {
      token: response.token
    }
    endPoints = {
      endPoint : response.baseApi && response.baseApi.endPoint ? response.baseApi.endPoint : '/initialize',
      server: response.baseApi && response.baseApi.server ? response.baseApi.server : API_CONFIG.url
    }
    //change revotioSessionId and Device id
    if (!response.hasOwnProperty('deviceId')) {
      revotioData.deviceId = response.deviceId
    } else revotioData.deviceId = response.deviceId

    if (!response.hasOwnProperty('revotioSessionId')) {
      revotioData.revotioSessionId = response.RevotioSessionId
    } else revotioData.revotioSessionId = response.revotioSessionId

    let params = {
      RevotioData: JSON.stringify(revotioData)
    }
    const key = response.uid ? atob(response.uid) : ''
    let publicpublisherServer = publicPublisherServer()
    if (publicpublisherServer) {
      params.pageName = pageName
      params.messageType = 'INIT'
      endPoints = {
        server:publicpublisherServer
      }
    }
    return JWTApiService.encrypt(params, key).then(encrypt => {
      const newdata = {
        data : encrypt,
        deviceId: response.deviceId,
        revotioSessionId: response.revotioSessionId,
        token: TokenService.getToken(),
        requestingUrl:API_CONFIG.currentHostName
      }
      if (publicpublisherServer) {
        newdata.pageName= pageName
        newdata.messageType = 'INIT'
        delete newdata.token
      }
      return ApiService.post(endPoints, newdata)
    })
  },
  actionPost (data, isAsync = false) {
    let payload = data.payload
    let postData = data.postData
    let authInfo = data.authInfo

    let endPoints = {
      endPoint : data.baseApi && data.baseApi.endPoint ? data.baseApi.endPoint : '/action',
      server: data.baseApi && data.baseApi.server ? data.baseApi.server : ''
    }
    var sessionData = {
      BrowserId: authInfo.browserFingerPrint,
      UserId: '123',
      revotioSessionId: authInfo.revotioSessionId,
      deviceId: authInfo.deviceId,
      AppId: API_CONFIG.appId,
      token: TokenService.getToken()
    }
    let params = {
      payload: payload,
      PostData: JSON.stringify(postData),
      SessionData: JSON.stringify(sessionData)
    }
    const key = authInfo.uid ? atob(authInfo.uid) : ''
    return JWTApiService.encrypt(params, key).then(encrypt => {
      const newdata = {
        data : encrypt,
        deviceId: sessionData.deviceId,
        revotioSessionId: sessionData.revotioSessionId,
        token: TokenService.getToken(),
        requestingUrl:API_CONFIG.currentHostName
      }
      if (!isAsync) {
        requests++
        return ApiService.post(endPoints, newdata).finally(()=>{
          if (requests > 0) requests--
        })
      } else {
        return  ApiService.postAsync(endPoints, newdata)
      }

    })
  },
  // this has no ecryption
  async directPost (data, infoData, method = 'post') {
    let publisherType = getUrlParam('publisherType')
    publisherType = publisherType || API_CONFIG.publisherType || 'private'
    let dataObj = {
      revotioSessionId:infoData.revotioSessionId,
      deviceId:infoData.deviceId,
      BrowserId:JSON.stringify(infoData.browserFingerPrint),
    }
    let objdata = infoData.data
    try {
      objdata = JSON.parse(infoData.data.toLocaleString())
    } catch (e) {
      objdata = infoData.data
    }
    if (publisherType) {
      dataObj.pageName = getUrlParam('pageName'),
      dataObj.messageType = 'INIT',
      dataObj.data = JSON.stringify(objdata),
      dataObj.applicationId = infoData.applicationId
    }
    return ApiService.directPost(data,dataObj,method)
  },
  flexiRequest (data, isAsync = false,) {
    let endpoints
    if (data.baseApi && data.baseApi.server!=='') {
      if (data.baseApi.server !== API_CONFIG.baseUrl) {
        endpoints = data.baseApi.server
      }
    }
    const slug = data.postData.endpoint
    endpoints = endpoints ? {server:endpoints,endPoint:slug} : slug
    let postData = data.postData
    let authInfo = data.authInfo

    delete postData.endpoint
    delete postData.entranceType
    var sessionData = {
      BrowserId: authInfo.browserFingerPrint,
      UserId: '123',
      revotioSessionId: authInfo.revotioSessionId,
      deviceId: authInfo.deviceId,
      AppId: API_CONFIG.appId,
      token: TokenService.getToken()
    }
    let params = {
      PostData: JSON.stringify(postData),
      SessionData: JSON.stringify(sessionData)
    }
    const key = authInfo.uid ? atob(authInfo.uid) : ''
    return JWTApiService.encrypt(params, key).then(encrypt => {
      const newdata = {
        data : encrypt,
        deviceId: sessionData.deviceId,
        revotioSessionId: sessionData.revotioSessionId,
        token: TokenService.getToken(),
        requestingUrl:API_CONFIG.currentHostName
      }
      if (!isAsync) {
        requests++
        return ApiService.post(endpoints, newdata).finally(()=>{
          if (requests > 0) requests--
        })
      } else {
        return  ApiService.postAsync(endpoints, newdata)
      }
    })
  },
  apiRequest (data, isAsync = false, endpoints = {}) {
    let publicpublisherServer = publicPublisherServer()
    endpoints.endPoint = endpoints.endPoint ? endpoints.endPoint : data.baseApi && data.baseApi.endPoint ? data.baseApi.endPoint : ''
    if (!endpoints.endPoint && !publicpublisherServer) endpoints.endPoint = '/action'
    const pgServer = data.baseApi && data.baseApi.server ? data.baseApi.server :  ''
    endpoints.server =  endpoints.server || publicpublisherServer || pgServer
    // response.baseApi && response.baseApi.endPoint ? response.baseApi.endPoint : 'initialize',
    let payload = data.payload
    let postData = data.postData
    let authInfo = data.authInfo
    var sessionData = {
      BrowserId: authInfo.browserFingerPrint,
      UserId: '123',
      revotioSessionId: authInfo.revotioSessionId,
      deviceId: authInfo.deviceId,
      AppId: API_CONFIG.appId,
      token: TokenService.getToken()
    }
    let params = {
      payload: payload,
      PostData: JSON.stringify(postData),
      SessionData: JSON.stringify(sessionData)
    }

    let pageName = getUrlParam('pageName')
    if (publicpublisherServer) {
      params.pageName = pageName
      params.messageType = 'action'
    }
    const key = authInfo.uid ? atob(authInfo.uid) : ''
    // return
    return JWTApiService.encrypt(params, key).then(encrypt => {
      const newdata = {
        data : encrypt,
        deviceId: sessionData.deviceId,
        revotioSessionId: sessionData.revotioSessionId,
        token: TokenService.getToken(),
        requestingUrl:API_CONFIG.currentHostName
      }
      if (publicpublisherServer) {
        newdata.pageName = pageName
        newdata.messageType = 'action'
        delete newdata.token
      }

      const wpPage = getWpPage()
      if (wpPage) {
        newdata.wpPage = wpPage
      }
      if (!isAsync) {
        requests++
        return ApiService.post(endpoints, newdata).finally(()=>{
          if (requests > 0) requests--
        })
      } else {
        return  ApiService.postAsync(endpoints, newdata)
      }
    })
  },
  async serveFileV2 (data,auth) {
    let servetype = data.servetype
    let absolutePath = data.path
    if (absolutePath) {
      let params = '?file=' + absolutePath + '&type='+ servetype
      + '&requestingUrl=' + API_CONFIG.currentHostName
      + `&token=${TokenService.getToken()}&deviceId=${auth.deviceId}&revotioSessionId=${auth.revotioSessionId}`
      + '&revotio-token=' + TokenService.getToken()
      const encryptedParams = `?postData=${await JWTApiService.encrypt(params,apiKey)}`
      return ApiService.get('/download', params,true)
    }
  },
  serveFile (data) {
    let servetype = data.servetype
    let absolutePath = data.path
    if (absolutePath) {
      let params = '?file=' + absolutePath + '&type=' + servetype + '&revotio-token=' + TokenService.getToken() + '&requestingUrl=' + API_CONFIG.currentHostName
      return ApiService.get('/download', params)
    }
  },
  uploadFile (data) {
    // let params = {
    //   files: data.files,
    //   appFolder: data.appFolder,
    //   type: data.type, // create remove,
    //   name: data.name
    // }

    // for (let i in params) {
    //   formData.append(i, params[i])
    // }
    data.requestingUrl = API_CONFIG.currentHostName
    return ApiService.media('/upload', data)
  },
  uploadFileV2 (data, auth) {
    data.append('deviceId', auth.deviceId)
    data.append('revotioSessionId', auth.revotioSessionId)
    data.append('token', TokenService.getToken())
    data.append('requestingUrl',API_CONFIG.currentHostName)
    // data.append('BrowserId',auth.browserFingerPrint)
    return ApiService.media('/upload', data, true).then(data=> {
      if (typeof data.data ==='string') {
        const decrypt = JWTApiService.decrypt(data.data, apiKey)
        data = {data:decrypt.body}
        return data
      }
      return data
    })
  },
  exitPost(data) {
    let authInfo = data.authInfo
    let params = {
      AppId: API_CONFIG.appId,
      revotioSessionId: authInfo.revotioSessionId,
      deviceId: authInfo.deviceId,
      requestingUrl:API_CONFIG.currentHostName
    }
    return navigator.sendBeacon(`${API_CONFIG.baseUrl}/exit`, JSON.stringify(params));
  },
  publicDownload (data) {
    const params = `?pathToken=${data.pathToken}&requestingUrl=${data.requestingUrl}`
    return ApiService.get('/downloadPublic', params)
  },
  errorLog(data) {
    data.requestingUrl = API_CONFIG.currentHostName
    return ApiService.post('/error', data)
  }
}
