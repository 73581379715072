var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.containerInitialize
    ? _c(
        "div",
        {
          staticClass: "presentationLayer layout--main",
          class: [
            _vm.pageClass,
            _vm.layoutTypeClass,
            _vm.navbarClasses,
            _vm.footerClasses,
            { "no-scroll": _vm.isAppPage },
          ],
          style: _vm.pageStyles,
        },
        [
          _c("apptour"),
          _vm._v(" "),
          _vm.hasMenu
            ? _c("revotiomenu", { attrs: { menuObject: _vm.menuObject } })
            : _vm._e(),
          _vm._v(" "),
          _vm.hasMenu
            ? _c("div", { staticClass: "menuVerticalNav" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableCustomizer
            ? _c("the-customizer", {
                attrs: {
                  footerType: _vm.footerType,
                  hideScrollToTop: _vm.hideScrollToTop,
                  navbarType: _vm.navbarType,
                  navbarColor: _vm.navbarColor,
                  routerTransition: _vm.routerTransition,
                },
                on: {
                  toggleHideScrollToTop: _vm.toggleHideScrollToTop,
                  updateFooter: _vm.updateFooter,
                  updateNavbar: _vm.updateNavbar,
                  updateNavbarColor: _vm.updateNavbarColor,
                  updateRouterTransition: _vm.updateRouterTransition,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.contentAreaClass,
                { "show-overlay": _vm.bodyOverlay },
              ],
              attrs: { id: "content-area" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "main-container content-wrapper",
                  class: [
                    { hasMenu: _vm.hasMenu, isright: _vm.isRight },
                    _vm.contentAreaClass,
                    { "show-overlay": _vm.bodyOverlay },
                  ],
                },
                [
                  [
                    _vm.hasMenu
                      ? _c("navBarHorizontalLayout", {
                          key: _vm.navBarReset + "navbar-horizontal",
                          class: [
                            {
                              "text-white":
                                _vm.isNavbarDark && !_vm.isThemeDark,
                            },
                            {
                              "text-base": !_vm.isNavbarDark && _vm.isThemeDark,
                            },
                          ],
                          attrs: {
                            navbarType: _vm.navbarType,
                            layerData: _vm.navBar,
                            logo: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.navbarType === "static"
                      ? _c("div", { staticStyle: { height: "62px" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasMenu &&
                    _vm.mainLayoutType === "horizontal" &&
                    _vm.windowWidth >= 1200
                      ? _c("h-nav-menu", {
                          attrs: { menuObject: _vm.menuObject },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasMenu ||
                    _vm.navBar.length > 0 ||
                    Object.keys(_vm.navBar).length > 0
                      ? _c("navBarLayout", {
                          key: _vm.navBarReset + "navbar",
                          class: [
                            {
                              "text-white":
                                _vm.isNavbarDark && !_vm.isThemeDark,
                            },
                            {
                              "text-base": !_vm.isNavbarDark && _vm.isThemeDark,
                            },
                          ],
                          attrs: {
                            layerData: _vm.navBar,
                            navbarColor: _vm.navbarColor,
                          },
                        })
                      : _vm._e(),
                  ],
                  _vm._v(" "),
                  _c("div", { staticClass: "content-wrapper" }, [
                    _c("div", { style: _vm.pagePadding }, [
                      _c(
                        "div",
                        {
                          class: {
                            "router-content":
                              _vm.hasMenu ||
                              _vm.navBar.length > 0 ||
                              Object.keys(_vm.navBar).length > 0,
                            dcontent: _vm.mainDivWidth,
                          },
                        },
                        [
                          _vm._l(_vm.pageContainer, function (container, inx) {
                            return _c(container.type, {
                              key:
                                container.id +
                                "-" +
                                _vm.reinitializePage +
                                "-" +
                                inx,
                              tag: "container",
                              attrs: { layerData: container },
                            })
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "content-area__content" },
                            [
                              !_vm.hideScrollToTop
                                ? _c(
                                    "back-to-top",
                                    {
                                      attrs: {
                                        bottom: "5%",
                                        visibleoffset: "500",
                                      },
                                    },
                                    [
                                      _c("vs-button", {
                                        staticClass:
                                          "shadow-lg btn-back-to-top",
                                        attrs: {
                                          "icon-pack": "feather",
                                          icon: "icon-arrow-up",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _vm.footer.type
            ? _c(
                "footer",
                [
                  _c(_vm.footer.type, {
                    tag: "container",
                    attrs: { layerData: _vm.footer },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }