var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.layerData
    ? _c(
        "div",
        {
          ref: _vm.layerData.id,
          staticClass: "cell",
          class: _vm.classes,
          style: _vm.cellStyles,
          attrs: { cellid: _vm.layerData.id, "data-type": "cell" },
        },
        [
          _vm.seen
            ? [
                _vm.regularView
                  ? _vm._l(_vm.layerData.columns, function (column, i) {
                      return _c(column.type, {
                        key: i,
                        tag: "component",
                        attrs: {
                          siblings: _vm.dataSibs,
                          colNum: _vm.layerData.columns.length,
                          layerData: column,
                          gIndex: _vm.gIndex,
                          renderFrom: _vm.renderFrom,
                          gadgetLists: _vm.gadgetLists,
                          renderLocation: _vm.renderLocation,
                          renderlocId: _vm.renderlocId,
                        },
                      })
                    })
                  : [
                      _c("actionButton", {
                        attrs: {
                          siblings: _vm.dataSibs,
                          colNum: _vm.layerData,
                          layerData: _vm.layerData,
                          gIndex: _vm.gIndex,
                          renderFrom: _vm.renderFrom,
                          gadgetLists: _vm.gadgetLists,
                          renderLocation: _vm.renderLocation,
                          renderlocId: _vm.renderlocId,
                          isModal: false,
                        },
                      }),
                    ],
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }