var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative horizontal" }, [
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper nav-menu-wrapper" },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "vx-navbar navbar-custom navbar-skelton",
            attrs: { color: _vm.navbarColor },
          },
          [
            _c(
              "ul",
              { staticClass: "menu-items flex flex-wrap w-full items-center" },
              [
                _vm._l(_vm.menuObject, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "menu-item",
                      class: { "mr-2": !(_vm.menuObject.length === index + 1) },
                    },
                    [
                      _vm.getItem(item) === "item"
                        ? [
                            _c(
                              "h-nav-menu-item",
                              {
                                staticClass: "relative py-4 cursor-pointer",
                                attrs: { item: item },
                              },
                              [
                                _c("span", { staticClass: "truncate" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _vm._v(" "),
                                item.bubble
                                  ? _c(
                                      "vs-chip",
                                      { attrs: { color: "primary" } },
                                      [_vm._v(_vm._s(item.bubble))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm.getItem(item) === "group"
                        ? [
                            _c("h-nav-menu-group", {
                              key: `group-${index}`,
                              staticClass: "menu-group relative py-4",
                              attrs: {
                                bottom: "",
                                group: item,
                                groupIndex: index,
                                level: 0,
                                open: _vm.checkGrpChildrenActive(item),
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _vm.enableThemeMenu
                  ? _c(
                      "li",
                      { staticClass: "menu-item" },
                      [
                        _c(
                          "h-nav-menu-item",
                          {
                            staticClass: "relative py-4 cursor-pointer",
                            attrs: {
                              item: {
                                iconName: "color_lens",
                                label: "Theme",
                                name: "Theme",
                                redirect: false,
                                inner: [],
                                themeConfig: true,
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "truncate" }, [
                              _vm._v("Theme"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.enableAppTour
                  ? _c(
                      "li",
                      { staticClass: "menu-item" },
                      [
                        _c(
                          "h-nav-menu-item",
                          {
                            staticClass: "relative py-4 cursor-pointer",
                            attrs: {
                              item: {
                                iconName: "tour",
                                label: "App Tour",
                                name: "AppTour",
                                redirect: false,
                                inner: [],
                                themeTour: true,
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "truncate" }, [
                              _vm._v("App Tour"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }