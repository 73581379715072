/**
 * File: download.vue                                                          *
 * Created Date: 2022-04-06T13:45:55                                           *
 * Author: Lei Fuentes                                                         *
 * Last Modified: 2022-04-06T13:45:55                                          *
 * Modified By: Lei Fuentes                                                    *
 */

<template>
  <div class="layout--full-page">
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <template v-if="downloadLink==='waiting'">
            <svg class="w-0">
                  <defs>
                    <filter id="w-0">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="7" result="blur" />
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -10"
                        result="res"
                      />
                      <feComposite in="SourceGraphic" in2="res" operator="atop" />
                    </filter>
                  </defs>
                </svg>
            <svg class="f-w-0" width="200" height="200" viewBox="0 0 200 200">
                  <defs>
                    <linearGradient id="linear-gradient">
                      <stop class="stop1" offset="0" />
                      <stop class="stop2" offset="1" />
                    </linearGradient>
                    <linearGradient
                      y2="160"
                      x2="160"
                      y1="40"
                      x1="40"
                      gradientUnits="userSpaceOnUse"
                      id="gradient"
                      xlink:href="#linear-gradient"
                    />
                  </defs>
                  <path
                    class="path-class"
                    d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776
                      -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64
                      0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736
                      -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317
                      64,-64 64,-64"
                  />
                  <circle class="cricle-class" cx="100" cy="100" r="64" />
                </svg>
            <svg class="svg-class-1" width="200" height="200" viewBox="0 0 200 200">
                  <path
                    class="path-class"
                    d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776
                      -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64
                      0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736
                      -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64
                      64,-64"
                  />
                  <circle class="cricle-class" cx="100" cy="100" r="64" />
            </svg>
          </template>
          <template v-if="downloadLink==='success'">
            <img src="@/assets/images/elements/downloadsucces2.png" alt="graphic-500" class="mx-auto mb-4 w-5/6" >
          </template>
            <img src="@/assets/images/elements/waitingdownload-min.jpg" alt="graphic-500" class="mx-auto mb-4 w-5/6" v-if="false">
          
            <img src="@/assets/images/elements/error-min.jpg" alt="graphic-500" class="mx-auto mb-4 w-5/6" v-if="downloadLink==='error'">
            <h3 class="sm:mx-0 mx-4 mb-4 sm:mb-12 text-5xl d-theme-heading-color">{{message}}</h3>
            <!-- <p class="sm:mx-0 mx-4 mb-4 sm:mb-16 d-theme-text-inverse">susceptive nonturbinated indoctrination formulary dyskinetic deafforest Strumella frolicsomeness encrustment portia myelination lachrymatory bestain hoople piscator pyramidoidal parter clipt.</p> -->
        </div>
    </div>
  </div>
 </div>
</template>
<script>
import {
  mapGetters,
  mapState
} from 'vuex'
  import im from '@/common/images.service'
  import {
    PageApiService
  } from '@/common/api.service'
import fn from '@/common/lib.service'
export default {
  name: 'download',
  beforeMount () {
    document.title = "File Download";
    this.$store.commit("UPDATE_PROGRESS_STATUS", false)
    this.$Progress.finish()
    let route =this.$router.currentRoute ? this.$router.currentRoute : {params:{}}
    let params = Object.keys(route.params).length > 0 ? route.params : {}
    this.pathToken = params.pathToken || ''
    const requestingUrl = params.requestingUrl
    // if (!this.pathToken)  return
    let fileget = {
      pathToken: this.pathToken,
      requestingUrl: requestingUrl || window.location.host
    }
    Promise.resolve(PageApiService.publicDownload(fileget)).then((response) => {
      if (response) {
            let data = response.data
            if (!data.hasOwnProperty('error') && data.type !== 'error') {
              const fileNamex = new Date().getTime()
              im.addImage(fileNamex, data)
              let dFile = im.getImage(fileNamex)
              fn.downloadFile({fileName:fileNamex,data:dFile,fileType:data.fileType})
              this.downloadLink='success'
              this.message='Thank you for downloading. May close this tab.'
              
              setTimeout(()=>{
                window.close()
              },500)
              
              // this.$set(this.gadgetData[index].avatar, 'src', im.getImage(data.file))
            }
          }
        }).catch(error => {
           this.downloadLink='error'
          this.message='An error occured while trying to get the file, please refresh or contact the administrator.'
          throw new Error(error)
          // TODO SHOW ERROR
        })
    
  },
  data() {
    return {
      pathToken:'',
      message:'Fetching the file to download. . .',
      downloadLink:'waiting'
    }
  }
}
</script>
<style>
.w-0 {
  width: 0;
}
.f-w-0 {
  filter: url(#w-0);
}
.stop1 {
  stop-color: rgb(168 85 247);
}
.stop2 {
  stop-color: rgb(37 99 235);
}
.path-class {
  animation: key01 8s infinite linear;
  stroke-dasharray: 180 800;
  fill: none;
  stroke: url(#gradient);
  stroke-width: 23;
  stroke-linecap: round;
}
.cricle-class {
  animation: key01 4s infinite linear;
  stroke-dasharray: 26 54;
  fill: none;
  stroke: url(#gradient);
  stroke-width: 23;
  stroke-linecap: round;
}
.svg-class-1 {
  filter: blur(5px);
  opacity: 0.3;
  position: absolute;
  transform: translate(3px, 3px);
}
@keyframes key01 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -403px;
  }
}

</style>

