var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mainLayoutType === "vertical" || _vm.windowWidth < 1200
    ? _c("div", { staticClass: "relative navBarLayout" }, [
        _c(
          "div",
          { staticClass: "vx-navbar-wrapper", class: _vm.classObj },
          [
            _c(
              "vs-navbar",
              {
                staticClass: "vx-navbar navbar-custom navbar-skelton",
                class: _vm.textColor,
                attrs: { color: _vm.navbarColorLocal },
              },
              [
                _vm.showMenu
                  ? _c("feather-icon", {
                      staticClass: "sm:inline-flex cursor-pointer mr-1",
                      attrs: { icon: "MenuIcon" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.showSidebar.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("vs-spacer"),
                _vm._v(" "),
                _vm.regularView
                  ? [
                      _vm.layerData &&
                      ["container", "cell"].includes(_vm.layerData.type)
                        ? _c(
                            "div",
                            {
                              staticClass: "container",
                              class: _vm.layerData.class,
                              attrs: {
                                containerid: _vm.layerData.id,
                                "data-containerid": "1",
                                "data-type": "container",
                              },
                            },
                            _vm._l(_vm.layerData.columns, function (column) {
                              return _c(column.type, {
                                key: column.id,
                                tag: "component",
                                attrs: { layerData: column },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.layerData && _vm.layerData.type === "item"
                        ? _c(
                            "div",
                            {
                              staticClass: "container",
                              class: _vm.layerData.class,
                              attrs: {
                                containerid: _vm.layerData.id,
                                "data-containerid": "1",
                                "data-type": "container",
                              },
                            },
                            [
                              _c(_vm.layerData.type, {
                                tag: "component",
                                attrs: { layerData: _vm.layerData },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : [
                      _c("actionButton", {
                        attrs: {
                          siblings: _vm.dataSibs,
                          colNum: _vm.layerData,
                          layerData: _vm.layerData,
                          isModal: false,
                        },
                      }),
                    ],
              ],
              2
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }