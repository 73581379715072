<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative navBarLayout" v-if="mainLayoutType === 'vertical' || windowWidth < 1200">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex cursor-pointer mr-1" v-if="showMenu" icon="MenuIcon" @click.stop="showSidebar" />
        <vs-spacer />
        <template class="regularView" v-if="regularView">
          <div class="container" v-bind:containerid="layerData.id" data-containerid="1"
          data-type="container" v-if="layerData && ['container','cell'].includes(layerData.type)" :class="layerData.class">
            <component :is="column.type" v-for="column in layerData.columns" v-bind:key="column.id" v-bind:layerData="column" ></component>
          </div>
          <div class="container" v-bind:containerid="layerData.id" data-containerid="1"
          data-type="container" v-if="layerData && layerData.type === 'item'" :class="layerData.class">
            <component :is="layerData.type" v-bind:layerData="layerData" ></component>
          </div>
        </template>
        <template class="actionbutton" v-else>
          <actionButton  :siblings="dataSibs" :colNum ="layerData"
            v-bind:layerData="layerData"
            :isModal="false"  />
        </template>
        <!-- <component :is="columns.type" v-for="columns in layerData.columns" v-bind:key="columns.id" v-bind:layerData="columns"/> -->
        <!-- <profile-drop-down /> -->
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import fn from '@/common/lib.service'
import {
  mapGetters,
  mapState
} from 'vuex'
export default {
  name: "navBar",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
    layerData:[Object,Array]
  },
  watch: {
    revotioPageData () {

      this.showMenu = false
      this.$destroy()
    },
    revotioGadgetData (newb, oldb) {
      // lets check if has container
      if (newb.hasOwnProperty('gadgetsList') && fn.getGadgetInfo(newb.gadgetsList, 'menu').hasOwnProperty('gadgetId')) {
        this.menuDisplay()
      }
    },
    windowWidth () {
      this.$set(this,'regularView',true)
      this.checkIfContentFit(100)
    }
  },
  components: {
    item: () => import('./item'),
    container: () => import('./container'),
    modal: () => import('./modal'),
    cell: () => import('./cell'),
    portlet: () => import('@/components/gadgets/portlet'),
    actionButton: () => import('./actionButton/actionButton')
    // ProfileDropDown: () => import('@/layouts/components/navbar/components/ProfileDropDown.vue')
  },
  computed: {
    ...mapGetters (['revotioFingerPrint', 'revotioDeviceID', 'revotioAuthInfo', 'revotioPageData','revotioGadgetData']),
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff" ? "#10163a" : this.navbarColor
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    itemsArray () {
      return this.revotioPageData.presentationLayer.items
    },
    textColor() {
      return {'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    mainLayoutType ()  { return this.$store.state.mainLayoutType  },
    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default")      return `navbar-default ${!this.regularView ? 'actionbuttondisplay' : ''}`
      else if (this.verticalNavMenuWidth == "reduced") return `navbar-reduced  ${!this.regularView ? 'actionbuttondisplay' : ''}`
      else if (this.verticalNavMenuWidth)              return `navbar-full  ${!this.regularView ? 'actionbuttondisplay' : ''}`
    },
    dataSibs: function () {
      return {
        id: this.layerData.id,
        sibs: this.layerData.columns
      }
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
    menuDisplay () {
      let menuSettings = this.revotioPageData.presentationLayer && this.revotioPageData.presentationLayer.menuSettings ? this.revotioPageData.presentationLayer.menuSettings : {}
      // this.showMenu = menuSettings.visible || false
      let gadgetInfo = fn.getGadgetInfo(this.revotioGadgetData.gadgetsList, 'menu')
      let gadgetOptions = gadgetInfo.gadgetOptions || {}
      if (Object.keys(gadgetOptions).length > 0 || Object.keys(gadgetInfo).length > 0) {
        this.showMenu = gadgetOptions.hasOwnProperty('visible') ? gadgetOptions.visible : menuSettings.visible
      }
    },
    checkIfContentFit (outSize = 300) {
        // TODO add the options here
        if (Array.isArray(this.layerData)) return
        let {disableResponsive,toActionButton} = this.layerData || {}
        if (!disableResponsive && !toActionButton) return
        // console.log(disableResponsive,toActionButton)
        setTimeout(() => {
          // if (this.layerData.id !== '25316751-81cb-7898-0be0-ed5eebf542c3') return
          let el = this.$el
          let children = this.$children
          let totalWidth = 0
          const childLoop = (childrenx) => {
            childrenx.forEach(child=> {
              if (child.hasOwnProperty('layerData') && ['cell','container'].includes(child.layerData.type)) {
                childLoop(child.$children)
              } else {
                if (child.layerData && child.layerData.type === 'item' && child.layerData.content[0].type === 'breadcrumbs') {
                  let bd = child.$el.querySelector('nav ol') || {}
                  totalWidth += bd.clientWidth || child.$el.clientWidth || 0
                } else {
                  totalWidth += child.$el.clientWidth
                }
              }
            })
          }
          childLoop(children)
          if (el.clientWidth < totalWidth) {
            // move to action button
            if (!this.layerData.icon) {
              this.$set(this.layerData,'icon','menu')
            }
            this.$set(this.layerData,'open','right-side-bar')
            this.$set(this,'regularView', false)
            // console.log(children)
            // console.log(el,el.clientWidth, totalWidth)
          } else this.$set(this,'regularView', true)
        },outSize)
      }
  },
  beforeMount () {
    if (!this.layerData) return
    if (!this.layerData.styles) this.layerData.styles = {}
    this.layerData.styles.width = '100%'
  },
  mounted () {
    // this.layerData.toActionButton = true
    // this.layerData.disableResponsive = true
    if(this.revotioGadgetData) {
      this.menuDisplay()
      if (this.revotioGadgetData.gadgetsList && this.revotioGadgetData.gadgetsList.length >= 1) {
        this.checkIfContentFit()
      }
    }
  },
  data () {
    return {
      showMenu: false,
      regularView: true
    }
  }
}
</script>

<style>
.navBarLayout header {
  max-height: 92px;
}
@media (max-width: 495px) {
  .navBarLayout header div.dynamic-text-area {
    display: none !important;
  }
}
</style>
