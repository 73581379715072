<template>
  <div class="relative horizontal">
    <div class="vx-navbar-wrapper nav-menu-wrapper">
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColor">
        <ul class="menu-items flex flex-wrap w-full items-center">
          <li
            class  = "menu-item"
            v-for  = "(item, index) in menuObject"
            :key   = "index"
            :class = "{'mr-2': !(menuObject.length === index+1)}">
            <!-- If header -->
            <template v-if="getItem(item) === 'item'">
              <h-nav-menu-item
                class       = "relative py-4 cursor-pointer"
                :item       = "item">
                  <span class="truncate">{{ item.name }}</span>
                  <vs-chip color="primary" v-if="item.bubble">{{ item.bubble }}</vs-chip>
              </h-nav-menu-item>
            </template>

            <!-- If it's group -->
            <template v-else-if="getItem(item) === 'group'">
              <h-nav-menu-group
                class="menu-group relative py-4"
                bottom
                :key        ="`group-${index}`"
                :group      ="item"
                :groupIndex ="index"
                :level="0"
                :open       ="checkGrpChildrenActive(item)" />
            </template>
          </li>
          <li class  = "menu-item" v-if="enableThemeMenu">
              <!-- theme item -->
              <h-nav-menu-item
                class       = "relative py-4 cursor-pointer"
                :item="{
                iconName: 'color_lens',
                label: 'Theme',
                name: 'Theme',
                redirect: false,
                inner: [],
                themeConfig: true
                }">
                  <span class="truncate">Theme</span>
              </h-nav-menu-item>
          </li>
          <li class  = "menu-item" v-if="enableAppTour">
              <!-- theme item -->
              <h-nav-menu-item
                class       = "relative py-4 cursor-pointer"
                :item="{
                iconName: 'tour',
                label: 'App Tour',
                name: 'AppTour',
                redirect: false,
                inner: [],
                themeTour: true
                }">
                  <span class="truncate">App Tour</span>
                  <!-- <vs-switch v-model="tour"/>-->
              </h-nav-menu-item>
          </li>
        </ul>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import HNavMenuGroup  from "./HorizontalNavMenuGroup.vue"
// import HNavMenuHeader from "./HorizontalNavMenuHeader.vue"
import HNavMenuItem   from "./HorizontalNavMenuItem.vue"
import {
  mapGetters
} from 'vuex'
export default {
  props: {
    // navbarColor  : { type: String, default: "#fff", },
    navMenuItems : { type: Array  },
    menuObject : { type: Array,  required: true   },
    enableThemeMenu:  { type: Boolean, default: true},
    enableAppTour: { type: Boolean, default: true}
  },
  components: {
    HNavMenuGroup,
    // HNavMenuHeader,
    HNavMenuItem,
  },
  watch: {
    revotioPageData () {
      this.tour = this.revotioPageData ? this.revotioPageData.tour : true
    }
  },
  computed: {
    ...mapGetters(['revotioPageData']),
    navbarColor() {
      return this.$store.state.theme === "dark" ? "#10163a" : "#fff"
    }
  },
  methods: {
    checkGrpChildrenActive(group) {
      const path        = this.$route.fullPath
      let active        = false
      const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

      if (group.submenu) {
        group.submenu.forEach((item) => {
          if (active) return true
          if ((path == item.url || routeParent == item.slug) && item.url) { active = true }
          else if (item.submenu) { this.checkGrpChildrenActive(item) }
        })
      }

      return active
    },
    getItem (ln) {
      if (ln.inner.length > 0 || ln.lazyload) {
        return 'group'
      } else {
        return 'item'
      }
    }
  },
  data () {
    return {
      tour: this.revotioPageData ? this.revotioPageData.tour : true
    }
  }
}
</script>

<style lang="scss">
@use "@/assets/scss/vuexy/components/horizontalNavMenu" as *;
</style>
