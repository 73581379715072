var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canSee
    ? _c(
        "div",
        {
          staticClass: "h-nav-menu-item",
          class: [
            { "h-nav-active-item text-primary font-medium": _vm.activeLink },
            { "disabled-item pointer-events-none": _vm.isDisabled },
          ],
        },
        [
          _vm.to
            ? _c(
                "router-link",
                {
                  class: [
                    { "router-link-active": _vm.activeLink },
                    "nav-link flex items-center",
                  ],
                  attrs: { exact: "", to: _vm.to, target: _vm.target },
                },
                [
                  !_vm.featherIcon
                    ? _c("vs-icon", {
                        attrs: { "icon-pack": _vm.iconPack, icon: _vm.icon },
                      })
                    : _c("feather-icon", {
                        class: _vm.iconClasses,
                        attrs: { icon: _vm.icon },
                      }),
                  _vm._v(" "),
                  _vm._t("default"),
                ],
                2
              )
            : _c(
                "label",
                {
                  staticClass: "nav-link flex items-center",
                  attrs: { target: _vm.target, href: _vm.href },
                  on: { click: _vm.callAction },
                },
                [
                  _vm.src !== ""
                    ? _c("vs-avatar", {
                        attrs: { src: _vm.src, size: "small" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.icon !== null
                    ? _c("vs-icon", {
                        attrs: { "icon-pack": _vm.iconPack, icon: _vm.icon },
                      })
                    : _c("feather-icon", {
                        class: _vm.iconClasses,
                        attrs: { icon: _vm.icon },
                      }),
                  _vm._v(" "),
                  _vm._t("default"),
                ],
                2
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }