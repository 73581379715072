<template>
  <div class="cell" :class="classes" v-bind:cellid="layerData.id" data-type="cell" v-bind:style="cellStyles" v-if="layerData"  :ref="layerData.id">
    <template v-if="seen">
      <template class="regular" v-if="regularView">
        <component :siblings="dataSibs" :is="column.type" v-for="(column,i) in layerData.columns" v-bind:key="i" :colNum ="layerData.columns.length"
        v-bind:layerData="column" :gIndex="gIndex"
        :renderFrom="renderFrom"
        :gadgetLists="gadgetLists"
        :renderLocation="renderLocation"
        :renderlocId="renderlocId" ></component>
      </template>

      <template class="actionbutton"  v-else>
        <actionButton  :siblings="dataSibs"  :colNum ="layerData"
        v-bind:layerData="layerData" :gIndex="gIndex"
        :renderFrom="renderFrom"
        :gadgetLists="gadgetLists"
        :renderLocation="renderLocation"
        :renderlocId="renderlocId"
        :isModal="false"  />
      </template>
    </template>
  </div>
</template>

<script>
import fn from '@/common/lib.service'
import { mapGetters } from 'vuex'

export default {
  name: 'Cell',
  props: {
    layerData: Object,
    colNum: { type: Number, default: 0 },
    gIndex: [Number, String],
    siblings: Object,
    disableWidthClass: { type: Boolean, default: false},
    isModal: { type: Boolean, default: false},
    renderFrom: {type: String, default:'main'},
    renderLocation: {type: String, default:'container'},
    gadgetLists: {type: [Object,Array]},
    renderlocId: {type: String, default:''}
  },
  components: {
    item: () => import('./item'),
    container: () => import('./container'),
    actionButton: () => import('./actionButton/actionButton'),
    modal: () => import('./modal'),
    portlet: () => import('@/components/gadgets/portlet')
  },
  watch: {
    revotioPageData () {
      this.$destroy()
    },
    revotioGadgetData () {
      this.$nextTick(()=> {
        if (!this.seen) {
          this.observer.unobserve(this.$el)
          this.observer.observe(this.$el)
        }
        setTimeout(()=> {
          this.$el.style.opacity = 0
          this.regularView = true
          this.checkIfContentFit(300)
        },200)
      })
    },
    windowWidth () {

      this.$set(this,'regularView',true)
      this.checkIfContentFit()
    }
  },
  computed: {
    ...mapGetters(['revotioPageData','revotioGadgetData']),
    // a computed getter
    cellStyles: function () {
      return fn.pageMergeDesign(this.revotioPageData, this.layerData)
    },
    classes: function () {
      // small
      let clsx = this.layerData.disableResponsive ? `noresponsive ${!this.regularView ? 'actionbuttondisplay' : ''}`: ''
      return this.disableWidthClass ? '' : clsx ? ` ${clsx}` : fn.gridClasses (this.colNum, 'cell', this.layerData.styles) 
    },
    dataSibs: function () {
      return {
        id: this.layerData.id,
        sibs: this.layerData.columns
      }
    },
    windowWidth() { return this.$store.state.windowWidth }
  },
  methods: {
    getComponentClasses: function () {
      fn.sibsGridClasses (this.layerData,  'cell')
      for (let i in this.layerData.columns) {
        let item = this.layerData.columns[i]
        if (item.type === 'item')
          item.class = fn.gridClasses (1,  'item', item.width)
        else if (item.type!== 'item' && item.hidden === true) item.class = fn.gridClasses (1,  'cell', 0)
        else item.class = fn.gridClasses (1,  'cell', item)
      }
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting}) => {
          if (!isIntersecting) {
            return;
          }
          this.observer.unobserve(target);
          this.seen = true
      });
    },
    checkIfContentFit (outSize = 300) {
        // TODO add the options here
        let {disableResponsive,toActionButton} = this.layerData || {}
        if (!disableResponsive && !toActionButton) {
          this.$el.style.opacity = 100
          return
        }
        // console.log(disableResponsive,toActionButton)
        setTimeout(() => {
          // if (this.layerData.id !== '25316751-81cb-7898-0be0-ed5eebf542c3') return
          let el = this.$el
          let children = this.$children
          let totalWidth = 0
          const childLoop = (childrenx) => {
            childrenx.forEach(child=> {
              if (child.hasOwnProperty('layerData') && ['cell','container'].includes(child.layerData.type)) {
                childLoop(child.$children)
              } else {
                if (child.layerData && child.layerData.type === 'item' && child.layerData.content[0].type === 'breadcrumbs') {
                  let bd = child.$el.querySelector('nav ol') || {}
                  totalWidth += bd.clientWidth || child.$el.clientWidth || 0
                } else {
                  totalWidth += child.$el.clientWidth
                }
              }
          })
          }
          childLoop(children)
          if (el.clientWidth < totalWidth) {
            // move to action button
            this.$set(this,'regularView', false)
          } else this.$set(this,'regularView', !this.touchDevice)
          this.$nextTick(()=>{
            if (!this.regularView) {
              if (!this.layerData.icon) {
                this.$set(this.layerData,'icon','menu')
              }
              this.$set(this.layerData,'open','right-side-bar')
            }
          })
          this.$el.style.opacity = 100
        },outSize)
    },
    setTheGadgets () {
      let gadgetLists = this.revotioGadgetData && this.revotioGadgetData.gadgetsList ? this.revotioGadgetData.gadgetsList : null
      if (gadgetLists && !this.gadgets) {
        let gadgets = {}
        this.findAGadget(this.layerData.columns,gadgets,gadgetLists)
        this.gadgets = gadgets
      }
    },
    findAGadget (columns,gadget,gadgetLists = []) {
      columns.forEach(col=> {
        if (col.type === 'item') {
          let gadgetId = col.content[0].id
          let theObj = gadgetLists.find (i=> i.gadgetId === gadgetId)
          if (theObj) {
            gadget[gadgetId] = theObj
          }
        } else {
          this.findAGadget(col.columns,gadget,gadgetLists)
        }
      })
      return gadget
    }
  },
  mounted (){
    // if (this.layerData.id === 'c58cee4b-5454-64f4-75ce-31c830425423') {
    //    this.layerData.toActionButton = true
    // this.layerData.disableResponsive = true
    // }
    this.$el.style.opacity = 0
    this.getComponentClasses()
    // hidden
    this.$on('hidden',data => {
    let totalHidden = 0
      for (let i in this.layerData.columns){
        let col = this.layerData.columns[i]
        if (col.id === data.layer) {
          totalHidden ++
        }
      }
      if (this.layerData.columns.length === totalHidden) { // hide whole
        this.$parent.$emit('hideMe', {layer: this.layerData.id, 'hide': true})
      }
    })
    this.observer.observe(this.$el);
    if (this.revotioGadgetData.gadgetsList && this.revotioGadgetData.gadgetsList.length >= 1) {
      this.setTheGadgets()
      setTimeout(() => {
        this.checkIfContentFit()
      },300)
    }
  },
  created() {
    this.observer = new IntersectionObserver(
      this.onElementObserved, 
      {
        root: this.$el,
        threshold: 0,
      }
    );
  },
  beforeDestroy () {
    this.$off('hidden');
    this.observer.disconnect();
  },
  data () {
    return {
      styles: { margin: 0, padding: '10px' }, // sample style for testing,
      componentClasses: {},
      observer: null,
      seen:false,
      regularView:true,
      touchDevice: fn.isTouchDevice() ? true : false,
      gadgets:null
    }
  }
}
</script>
