<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
<div class="relative navBarLayout" v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
  <div class="vx-navbar-wrapper navbar-full p-0">
    <vs-navbar class="navbar-custom navbar-skelton p-0 pl-4" :class="navbarClasses"  :style="navbarStyle" :color="navbarColor">
        <router-link tag="div" to="/" class="vx-logo cursor-pointer mx-auto flex items-center">

          <img :src="'static/'+logoFileName" width="40px" class="mr-1" v-if="logoFileName"/> 
          <logo class="w-10 mr-4 fill-current text-primary" v-else />
          <span class="vx-logo-text text-primary">{{appName}}</span>
        </router-link>
        <div class="container" v-bind:containerid="layerData.id" data-containerid="1"
        data-type="container" v-if="layerData && ['container','cell','actionButton'].includes(layerData.type) && layerData.columns" :class="layerData.class">
          <component :is="column.type" v-for="column in layerData.columns" v-bind:key="column.id+'h'" v-bind:layerData="column" ></component>
        </div>

        <div class="container" v-bind:containerid="layerData.id" data-containerid="1"
        data-type="container" v-if="layerData && layerData.type === 'item'" :class="layerData.class">
          <component :is="layerData.type" v-bind:layerData="layerData" ></component>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import container from './container'
import item from './item'
import cell from './cell'
import Logo from "./menu/Logo"
import NavBar from "./navBar"
import {API_CONFIG} from '@/common/configs'
import {
  mapGetters,
  mapState
} from 'vuex'
export default {
  name: "navBarHorizontal",
  props: {
    logo: {type: String},
    navbarType: {
      type: String,
      required: true
    },
    layerData:[Object,Array]
  },
  components: {
    container,
    item,
    cell,
    Logo,
    actionButton: () => import('./actionButton/actionButton')
  },
  computed: {
    ...mapGetters (['revotioFingerPrint', 'revotioDeviceID', 'revotioAuthInfo', 'revotioPageData','revotioGadgetData']),
    navbarColor() {
      let color = "#fff"
      if (this.navbarType === "sticky") color = "#fff"
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = "#fff"
        }
      }

      this.isThemedark === "dark" ? color === "#fff" ? color = "#10163a" : color = "#262c49" : null

      return color
    },
    isThemedark()          { return this.$store.state.theme                                                                       },
    navbarStyle()          { return this.navbarType === "static" ? {transition: "all .25s ease-in-out"} : {}                      },
    navbarClasses()        { return this.scrollY > 5 && this.navbarType === "static" ? null : "" },
    scrollY()              { return this.$store.state.scrollY                                                                     },
    verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth                                                        },
    windowWidth()          { return this.$store.state.windowWidth                                                                 },
    mainLayoutType()       { return this.$store.state.mainLayoutType                                                               },
    newLayerData () {
      return this.layerData
    }
  },
  watch: {
    revotioPageData () {
      this.$destroy()
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    }
  },
  data () {
    return {
      appName: API_CONFIG.appName === 'APP_NAME' ? 'Revotio' : API_CONFIG.appName,
      logoFileName : (API_CONFIG.logoFileName !== 'APP_LOGO') ? API_CONFIG.logoFileName : null
    }
  }
}
</script>

