var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.progressType === "type1"
    ? _c(
        "div",
        { staticClass: "preloader" },
        [
          _c(
            "vs-progress",
            {
              staticClass: "smallx blockx",
              attrs: { indeterminate: "", color: "primary", height: 2 },
            },
            [_vm._v("primary")]
          ),
          _vm._v(" "),
          _c(
            "vs-progress",
            {
              staticClass: "mediumx blockx mt-m mb-m mb-s",
              attrs: { indeterminate: "", color: "primary", height: 2 },
            },
            [_vm._v("primary")]
          ),
        ],
        1
      )
    : _c("div", { staticClass: "flex w-full" }, [
        _c("div", { staticClass: "w-full bg-grey-light h-39 33.33" }),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }