// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*=========================================================================================
    File Name: featherIcon.scss
    Description: Feather icon component's scss. Imported in FeatherIcon.vue file
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.feather-icon {
  display: inline-flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/vuexy/components/featherIcon.scss","webpack://./FeatherIcon.vue"],"names":[],"mappings":"AAAA;;;;;;;2FAAA;AAUA;EACI,oBAAA;EACA,mBAAA;ACDJ","sourcesContent":["/*=========================================================================================\n    File Name: featherIcon.scss\n    Description: Feather icon component's scss. Imported in FeatherIcon.vue file\n    ----------------------------------------------------------------------------------------\n    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template\n      Author: Pixinvent\n    Author URL: http://www.themeforest.net/user/pixinvent\n==========================================================================================*/\n\n\n.feather-icon {\n    display: inline-flex;\n    align-items: center;\n}\n","/*=========================================================================================\n    File Name: featherIcon.scss\n    Description: Feather icon component's scss. Imported in FeatherIcon.vue file\n    ----------------------------------------------------------------------------------------\n    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template\n      Author: Pixinvent\n    Author URL: http://www.themeforest.net/user/pixinvent\n==========================================================================================*/\n.feather-icon {\n  display: inline-flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
