/*=========================================================================================
  File Name: lib.service.js
  Description: this is where you add global functions to be used
  ----------------------------------------------------------------------------------------
==========================================================================================*/
/**
 * Since the style from the presentationlayer doesnt have unit,
 * we add them
 * @param {object} datastyles
 * return object
 */
import {colorCodes} from '@/common/constants';
// import { Filesystem, Directory } from '@capacitor/filesystem';
// import { Browser } from '@capacitor/browser';

import { ObjectId } from 'bson';
export function contentStylesFormat(datastyles) {
  let styles = datastyles
  for (const style in styles) {
    switch (style) {
      case 'width':
        styles[style] = parseFloat(styles[style]) + '%'
        break
      case 'padding':
      case 'padding-top':
      case 'padding-right':
      case 'padding-bottom':
      case 'padding-left':
      case 'margin':
      case 'margin-top':
      case 'margin-right':
      case 'margin-bottom':
      case 'margin-left':
      case 'font-size':
      case 'font-weight':
        styles[style] = parseFloat(styles[style]) + 'px'
        break
	    case 'backgroundColor':	
        if (styles[style] !== '') {	
          styles['background-color'] = `${styles[style]} !important`	
          delete styles.backgroundColor	
        }	
        break;	
      case 'backgroundUrl':	
        if (styles[style] !== '') {	
          styles['background-image'] = `url(${styles[style]}) !important`	
          delete styles.backgroundUrl	
        }	
        break;	
      case 'css':	
        styles.css
        var styleSheet = document.createElement("style")
        styleSheet.type = "text/css"
        styleSheet.setAttribute('customstyle',true)
        styleSheet.innerText = styles.css
        document.head.appendChild(styleSheet)
        break;
      case 'justify-content':
        const vx = styles[style].split('-')
        const px = vx[0] // position -- left/right/center
        const px2 = vx[1] // position -- top/bottom/center/full
        const px2Obj = {
          bottom:'end',
          full:'center',
          top:'start',
          center:'center'
        }
        const pxObj = {
          left:'flex-start',
          right:'flex-end',
          center:'center'
        }
        if (px === 'auto' || !px) {
          // do nothing
        } else {
          styles.display = 'flex'
          px2 ? styles['align-items'] = px2Obj[px2]: null
          px === 'flex' ? styles[style]= styles[style] : styles[style] = pxObj[px]
        }
        break;
      default:
        styles[style] = styles[style]
    }
  }
  return styles
}

export function hextorgb(hex) {
  try {
    hex = hex.replace('#','')
    var aRgbHex = hex.match(/.{1,2}/g);
    var aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16)
    ];
    return aRgb
  } catch(e) {
    return ''
  }
}
/**
 * Gets the presentationlayer design
 * @param {object} revotioPageData
 * @param {string} id
 * return object || null
 */
export function pageDesignObject(revotioPageData, id) {
  if (revotioPageData.hasOwnProperty('design')) {
    if (revotioPageData.design[id]) {
      return revotioPageData.design[id]
    } else {
      return null
    }
  }
  return null
}
/**
 * Merge the presentation layer design object and the content data design
 * @param {object} revotioPageData
 * @param {object} layerData
 * return object || null
 */
export function pageMergeDesign(revotioPageData, layerData) {
  // `this` points to the vm instance
  let designObject = null
  if (layerData !== undefined && typeof layerData === 'object') {
    if (layerData.hasOwnProperty('id')) {
      designObject = pageDesignObject(revotioPageData, layerData.id)
    }
    let mergedesign = null
    designObject = null
    if (layerData.hasOwnProperty('styles')) {
      let formatedstyle = contentStylesFormat(layerData.styles)
      if (designObject != null && formatedstyle != null) {
        mergedesign = Object.assign(formatedstyle, designObject)
      } else if (designObject === null && formatedstyle != null) {
        mergedesign = formatedstyle
      } else if (designObject != null && formatedstyle === null) {
        mergedesign = designObject
      }
      return mergedesign
    }
  }
  return designObject
}
/**
 * Gets the GadgetInfo from the gadget List
 * @param {array} gadgetList
 * @param {string} gadgetId
 * @param {string} gIndex
 * return object
 */
export function getGadgetInfo(gadgetList, gadgetObj, gIndex = undefined) {
  const gadgetId = typeof gadgetObj === 'string' ? gadgetObj : gadgetObj.id
  const gadgetType = typeof gadgetObj === 'string' ? 'any' : gadgetObj.type
  // for (const gadget in gadgetList) {
  //     if (!gadgetList[gadget].hasOwnProperty('gadgetId')) {
  //         continue
  //     }
  //     if (!gadgetList[gadget].hasOwnProperty('gadgetId')) {
  //         gadgetList[gadget].gadgetId = gadgetList[gadget].gadgetId
  //     }
  //     if (gadgetList[gadget].gadgetId === gadgetId && gIndex === undefined) {
  //         return gadgetList[gadget]
  //     } else if (gadgetList[gadget].hasOwnProperty('gIndex') && gIndex !== undefined && gadgetList[gadget].gadgetId === gadgetId &&
  //         parseInt(gadgetList[gadget].gIndex) === gIndex) {
  //         return gadgetList[gadget]
  //     }
  // }
  const hasgIndex = gIndex !== undefined ? gadgetList.find(i => (i.gIndex == gIndex && i.gadgetId === gadgetId && (gadgetType === 'any' || gadgetType == i.gadgetType) )) : undefined
  if (hasgIndex !== undefined) {
    return hasgIndex
  }

  const gId = gadgetId
  const gadgetInfo = gadgetList ? gadgetList.find(i => String(i.gadgetId) === String(gId)  && (gadgetType === 'any' || gadgetType == i.gadgetType)) : {}
  return gadgetInfo || {}
}
/**
 * Currently unused, already added a gIndex in the getGadgetInfo
 * @param {array} gadgetList
 * @param {string} customId
 */
export function getGadgetInfotrueCustom(gadgetList, customId) {
  for (const gadget in gadgetList) {
    if (!gadgetList[gadget].hasOwnProperty('customId')) {
      continue
    }
    if (gadgetList[gadget].customId === customId) {
      return gadgetList[gadget]
    }
  }
  return {}
}
/**
 * gets the gadgetdata form the gadget info/object
 * @param {object} gadgetInfo
 */
export function getGadgetData(gadgetInfo) {
  if (gadgetInfo.hasOwnProperty('gadgetData')) {
    return gadgetInfo.gadgetData
  }
  return {}
}
/**
 * gets the gadgetContent form the gadget info/object
 * @param {object} gadgetInfo
 */
export function getGadgetContent(gadgetInfo) {
  if (gadgetInfo.hasOwnProperty('gadgetData')) {
    return gadgetInfo.gadgetData
  }
  return {}
}
/**
 * gets the gadget options form the gadget info/object
 * @param {object} gadgetInfo
 */
export function getGadgetOptions(gadgetInfo) {
  if (gadgetInfo.hasOwnProperty('gadgetOptions')) {
    return gadgetInfo.gadgetOptions
  }
  return {}
}
/**
 *
 * @param {*} gadgetInfo
 * @param {*} objectName
 */
export function getGadgetContentData(gadgetInfo, objectName, emptyReturn = {}) {
  if (objectName === 'gadgetSettings') {
    objectName = 'gadgetOptions'
  } else if (objectName === 'gadgetContent') {
    objectName = 'gadgetData'
  }
  if (Object.keys(gadgetInfo).length === 0) return emptyReturn
  if (gadgetInfo.hasOwnProperty(objectName)) {
    return gadgetInfo[objectName]
  }
  return {}
}
/**
 * gets the mockdata form the mock folder or mock file
 * @param {string} type
 */
export function getMockData(type = '') {
  let mockdata = require('./gadgetMock.json')
  if (type !== '') {
    mockdata = require('./gadgetMockData/' + type + '.json')
  }
  return mockdata
}
/**
 * converts font awesome icon text to material,
 * this was used before when there's no font awesome yet on the current app
 * @param {*} icon
 */
export function convertIcon2Material(icon) {
  if (icon.includes('sign-out')) {
    return 'exit_to_app'
  } else if (icon.includes('plus')) {
    return 'add'
  } else if (icon.includes('wrench')) {
    return 'build'
  } else if (icon.includes('clock')) {
    return 'access_time'
  } else if (icon.includes('angle-double-right')) {
    return 'double_arrow'
  } else if (icon.includes('tachometer')) {
    return 'av_timer'
  } else if (icon.includes('folder')) {
    return 'folder_open'
  } else if (icon.includes('calendar')) {
    return 'event'
  } else if (icon.includes('briefcase')) {
    return 'card_travel'
  } else if (icon.includes('edit')) {
    return 'edit'
  } else if (icon.includes('trash')) {
    return 'delete_outline'
  } else if (icon.includes('archive')) {
    return 'archive'
  } else if (icon.includes('archive')) {
    return 'archive'
  } else if (icon.includes('upload')) {
    return 'backup'
  } else if (icon.includes('angle-right')) {
    return 'keyboard_arrow_right'
  }
}
/**
 * convert text to uppercase
 * @param {string} word
 */
export function upperCase(word) {
  return word[0].toUpperCase() + word.slice(1)
}
/**
 * check if the file is an image
 * @param {string} fileType
 */
export function isFileImage(fileType) {
  return fileType && fileType.split('/')[0] === 'image'
}
/**
 * gets an icon based on filetype
 * @param {stromg} fileType
 */
export function fileTypeIcon(fileType) {
  let icon = 'icon-file'
  if (fileType) {
    switch (fileType.split('/')[0]) {
      case 'image':
        icon = 'icon-image'
        break
    }
  }
  return icon
}
/**
 * sets a tailwind width class based on width
 * @param {string} width
 */
export function tailWidthClass(width) {
  let tail = 'w-'
  if (width !== 'auto' && typeof width === 'string') {
    width = parseFloat(width)
  }
  // this width are based on tailwind width classes
  switch (true) {
    case (width == 100):
      tail += 'full'
      break
    case (width > 75 && width <= 75):
      tail += '3/4'
      break
    case (width > 70 && width <= 75):
      tail += '3/4'
      break
    case (width > 66.666667 && width <= 70):
      tail += '3/3'
      break
    case (width > 60 && width <= 66.666667):
      tail += '8/12'
      break
    case (width > 58.333333 && width <= 60):
      tail += '3/5'
      break
    case (width > 50 && width <= 58.333333):
      tail += '7/12'
      break
    case (width > 40 && width <= 50):
      tail += '1/2'
      break
    case (width > 40 && width <= 41.666667):
      tail += '5/12'
      break
    case (width > 34 && width <= 40):
      tail += '2/5'
      break
    case (width > 30 && width <= 34):
      tail += '1/3'
      break
    case (width > 25 && width <= 30):
      tail += '3/10'
      break
    case (width > 20 && width <= 25):
      tail += '1/4'
      break
    case (width > 16.66667 && width <= 20):
      tail += '1/5'
      break
    case (width > 10 && width <= 16.66667):
      tail += '2/12'
      break
    case (width > 8.33333 && width <= 10):
      tail += '1/10'
      break
    case (width > 5 && width <= 8.33333):
      tail += '1/12'
      break
    case (width < -0):
      tail += '0'
      break
    case (width === 'auto'):
      tail = 'w-auto'
      break
    default:
      tail = ''
  }
  return tail
}
/**
 * Tailwind gridclasses
 * @param {number} noCol
 * @param {string} type
 * @param {object} styles
 */
export function gridClasses(noCol = 0, type = '', styles = {}) {
  // unsure if we needed a type let's just prepare it for now // cell|container|item
  // set sizes by screen size
  let classes = 'w-full '
  let width = ''
  if (styles && !styles.width && typeof styles === 'number')
    width = styles
  else if (styles && styles.width)
    width = styles.width
  // xl and lg
  if (width !== '') {
    let tail = tailWidthClass(width)
    classes += ' xl:' + tail + ' lg:' + tail + ' md:' + tail
  } else {
    let dividedWidth = 100
    if (noCol !== 0) {
      dividedWidth = 100 / noCol
    }
    let tail = tailWidthClass(dividedWidth)
    classes += ' xl:' + tail + ' lg:' + tail
  }
  //  else if ( noCol > 4) {
  //   classes += ' xl:w-1/4 lg:w-1/4'
  // } else if (noCol === 1) {
  //   classes += ' xl:w-full lg:w-full'
  // } else classes += ' xl:w-1/' + noCol + ' lg:w-1/' + noCol

  // md
  // if (noCol > 3 && Number.isInteger(noCol/3))  {
  //   classes += ' md:w-1/3'
  // } else if (noCol > 3 && !Number.isInteger(noCol/3)) {
  //   classes += ' md:w-1/2'
  // } else if (noCol === 1) {
  //   classes += ' md:w-full'
  // } else classes += ' md:w-1/' + noCol

  //sm
  if (noCol > 2) {
    classes += ' md:w-1/2'
  } else if (!classes.includes('md:')) {
    classes += ' md:w-full'
  }


  return classes
}
/**
 * Tailwind gridclasses
 * @param {number} noCol
 * @param {string} type
 * @param {object} styles
 */
 export function gridClassesItems(noCol = 0, type = '', styles = {},sameSize = true) {
  // unsure if we needed a type let's just prepare it for now // cell|container|item
  // set sizes by screen size
  let classes = 'w-full '
  let width = ''
  if (styles && !styles.width && typeof styles === 'number')
    width = styles
  else if (styles && styles.width)
    width = styles.width
  // xl and lg
  if( sameSize) {
    if (width !== '') {
      let tail = tailWidthClass(width)
      classes += ' xl:' + tail + ' lg:' + tail + ' md:' + tail
      classes += `md:${tail}`
    } else {
      let dividedWidth = 100
      if (noCol !== 0) {
        dividedWidth = 100 / noCol
      }
      let tail = tailWidthClass(dividedWidth)
      classes += ' xl:' + tail + ' lg:' + tail
      classes += ` md:${tail} sm:${tail} xs:${tail}`
    }
  } else {
    // this will give max width
    if (width !== '') {
      let tail = tailWidthClass(width)
      classes += ' xl:' + tail + ' lg:' + tail + ' md:' + tail
    } else {
      let dividedWidth = 100
      if (noCol !== 0) {
        dividedWidth = 100 / noCol
      }
      let tail = tailWidthClass(dividedWidth)
      classes += ' xl:' + tail + ' lg:' + tail + ` md:${tail}`
    }
    if (noCol > 0) {

      classes += ' sm:max-w-xs sm:min-w-xs xs:max-w-xs  xs:min-w-xs'
    }
  }

  //  else if ( noCol > 4) {
  //   classes += ' xl:w-1/4 lg:w-1/4'
  // } else if (noCol === 1) {
  //   classes += ' xl:w-full lg:w-full'
  // } else classes += ' xl:w-1/' + noCol + ' lg:w-1/' + noCol

  // md
  // if (noCol > 3 && Number.isInteger(noCol/3))  {
  //   classes += ' md:w-1/3'
  // } else if (noCol > 3 && !Number.isInteger(noCol/3)) {
  //   classes += ' md:w-1/2'
  // } else if (noCol === 1) {
  //   classes += ' md:w-full'
  // } else classes += ' md:w-1/' + noCol

  //sm
  // if (noCol > 2) {
  //   classes += `md:${tail}`
  // } else if (!classes.includes('md:')) {
  //   classes += ' md:w-full'
  // }


  return classes
}
/**
 *
 * @param {object} layerData
 * @param {string} type  // cell, item, container
 */
export function sibsGridClasses(layerData, type) {
  // unsure if we needed a type let's just prepare it for now // cell|container|item
  // set sizes by screen size
  // xl and lg
  if (type === 'item') {
    let from = 0
    let rowLayers = []
    for (let i in layerData) {
      let item = layerData[i]
      if (gadgetInMin(item.data.type, item.elementWidth) && item.hidden === false) {
        item.width = item.elementWidth
        rowLayers.push(item)
      } else {
        if (layerData.length > 1) {
          recalculate(rowLayers, item, layerData.slice(i), type)
        } else {
          item.hidden = true
        }
        // lets recalculate
      }
    }
  } else {
    let hidden = null
    for (let i in layerData.columns) {
      let item = layerData.columns[i]
      if (item.type === 'item') {
        if (item.hidden && hidden !== false) {
          hidden = true
        } else {
          hidden = false
        }
      }
    }
    layerData.hidden = true
  }
}
export function recalculate(rowLayers, item, nextLayers, type) {
  let total = rowLayers.length
  if (total < 1) {
    item.width = 100
  }
  let dividedWidth = item.elementWidth / total

  for (let i in rowLayers) {
    let layer = rowLayers[i]
    layer.width = layer.width + dividedWidth
  }
  if (nextLayers.length > 0 && item.parentWidth) {
    sibsGridClasses(nextLayers, type)
  } else return item.width = 100
}
/**
 *
 * @param {string} type
 * returns the minimum width of a gadget
 */
export function dataRecal(parent, siblings, needed, type, layerdata) {
  let parentWidth = parent.$el.offsetWidth
  let total = 0;
  if (siblings) {
    let totalSibs = siblings.sibs.length
    for (let i in siblings.sibs) {
      let sibs = siblings.sibs[i]
      if (layerdata.id === sibs.id && sibs < 100) {
        let newPercent = (needed / 100) * parentWidth
        sibs.style.width = parseFloat(sibs.styles.width) + newPercent
      }
      sibs.class = gridClasses((i + 1), 'item', sibs)
    }
  } else layerdata.styles.width = 100
}
/**
 * gets the min width based on gadget type
 * @param {string} type
 */
export function gadgetMin(type) {
  let min = 0
  switch (type) {
    case 'data-table':
      min = 235 // from 235 adding 35 for the item container
      break
    case 'data-card':
      min = 170
      break
    case 'highcharts':
      min = 200
      break
    case 'dynamic-text-area':
      min = 40
      break
    case 'chat':
    case 'media-viewer':
    case 'tabs-select':
    case 'infinite-accordion':
      min = 10
      break
  }
  return min
}
/**
 * check if gadget is in minimum width
 * @param {string} type
 * @param {number} itemWidth
 */
export function gadgetInMin(type, itemWidth = 12000) {
  let min = gadgetMin(type)
  if (itemWidth >= min) {
    return true
  } else return false
}
/**
 * check image by extension
 * @param {string} file
 */
export function isImage(file = '') {
  let type = ''
  if (typeof file === 'string') {
    type = file.split('.').pop().toUpperCase()
  }
  switch (type) {
    case 'JPG':
    case 'JPEG':
    case 'SVG':
    case 'BMP':
    case 'TIFF':
    case 'JFIF':
    case 'JFIF':
    case 'PNG':
      break;
    default:
      type = ''
  }
  if (type === '')
    return false
  else return true
}

export function getFileName(file) {

  if (!file) return ''
  if (typeof file === 'string') {
    return file.split('\\').pop().split('/').pop()
  } else if (file.name) {
    return file.name.split('\\').pop().split('/').pop()
  } else return ''
}
export function addRemoveBodyClass(cName, type) {
  document.getElementsByTagName('body')[0].classList[type](cName)
}
export function addRemoveAppClass(cName, type) {
  document.getElementById('app').classList[type](cName)
}
/**
 * sets the window size class based on clientwidth
 * @param {integer} clientWidth
 */
export function setwinSize(clientWidth) {
  if (clientWidth >= 300 && clientWidth <= 400) {
    return 'm'
  } else if (clientWidth < 300) {
    return 's'
  } else {
    return ''
  }
}
export function getProgress(Vue, postdata) {
  if (postdata.postData && postdata.postData.gadgetType !== 'Menu') {
    return Vue.prototype.$Progress
  } else {
    return {
      start: function () {},
      finish: function () {},
      fail: function () {},
      increase: function () {}
    }
  }
}

export function thousandFormat(number, symbol) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, symbol);
}

export function createGUID() {
  function _p8(s) {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }
  return _p8() + _p8(true) + _p8(true) + _p8();
}

export function createRandomID() {
  function _p8(s) {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }
  return _p8() + _p8(true) + _p8();
}

export function createBSONID() {
  // console.log()
  try{
    const objectId = new ObjectId();
    return objectId
  }
  catch(error) {
    return ''
  }
  // let ObjectID =  BSON.ObjectID
  // return new ObjectID()
}

export function avatars () {
  const icons = [
    'https://pics.freeicons.io/uploads/icons/png/13130251661606751747-64.png',
    // 'https://pics.freeicons.io/uploads/icons/png/17157033341606751747-512.png',
    'https://pics.freeicons.io/uploads/icons/png/17157033341606751747-64.png',
    'https://pics.freeicons.io/uploads/icons/png/9463915221606751747-64.png',
    'https://pics.freeicons.io/uploads/icons/png/14594750861606751746-64.png',
    'https://pics.freeicons.io/uploads/icons/png/11095948911606751747-64.png',
    'https://pics.freeicons.io/uploads/icons/png/9370244561606989246-64.png',
    'https://pics.freeicons.io/uploads/icons/png/2432795691606989247-64.png',
    'https://pics.freeicons.io/uploads/icons/png/4912598101606989248-64.png',
    'https://pics.freeicons.io/uploads/icons/png/19590918061606989247-64.png',
    'https://pics.freeicons.io/uploads/icons/png/15540074211606989246-64.png',
    'https://pics.freeicons.io/uploads/icons/png/10256810741606989247-64.png',
    'https://pics.freeicons.io/uploads/icons/png/13399828131606781781-64.png',
    'https://pics.freeicons.io/uploads/icons/png/3301292771606781780-64.png',
    'https://pics.freeicons.io/uploads/icons/png/13518026451606781782-64.png',
    'https://pics.freeicons.io/uploads/icons/png/6903376751606781783-64.png',
    'https://pics.freeicons.io/uploads/icons/png/10843995041606781172-64.png',
    'https://pics.freeicons.io/uploads/icons/png/8077226991606781173-64.png',
    'https://pics.freeicons.io/uploads/icons/png/15199725381606781174-64.png',
    'https://pics.freeicons.io/uploads/icons/png/14095646371606781172-64.png',
    'https://pics.freeicons.io/uploads/icons/png/8299389661606781171-64.png',
    'https://pics.freeicons.io/uploads/icons/png/14387405891606781172-64.png',
    'https://pics.freeicons.io/uploads/icons/png/8126746341606781173-64.png',
    'https://pics.freeicons.io/uploads/icons/png/9731805301606781173-64.png',
    'https://pics.freeicons.io/uploads/icons/png/16083191061606751248-64.png',
    'https://pics.freeicons.io/uploads/icons/png/1725949081606751248-64.png',
    'https://pics.freeicons.io/uploads/icons/png/9377743271606751248-64.png',
    'https://pics.freeicons.io/uploads/icons/png/10587832431606751248-64.png',
    'https://pics.freeicons.io/uploads/icons/png/7172526201606751249-64.png',
    'https://pics.freeicons.io/uploads/icons/png/16795727821606751248-64.png',
    'https://pics.freeicons.io/uploads/icons/png/17729800211606751249-64.png',
    'https://pics.freeicons.io/uploads/icons/png/16725751181606750573-64.png',
    'https://pics.freeicons.io/uploads/icons/png/167051681606750574-64.png',
    'https://pics.freeicons.io/uploads/icons/png/3716986431606750575-64.png',
    'https://pics.freeicons.io/uploads/icons/png/10271732511606750575-64.png',
    'https://pics.freeicons.io/uploads/icons/png/20159207211606750573-64.png',
    'https://pics.freeicons.io/uploads/icons/png/17140408991606066369-64.png',
    'https://pics.freeicons.io/uploads/icons/png/4473260331606066368-64.png',
    'https://pics.freeicons.io/uploads/icons/png/1290666401606066370-64.png',
    'https://pics.freeicons.io/uploads/icons/png/14597226801606066371-64.png',
    'https://pics.freeicons.io/uploads/icons/png/15580919831606066369-64.png',
    'https://pics.freeicons.io/uploads/icons/png/20671185841606066371-64.png',
    'https://pics.freeicons.io/uploads/icons/png/9459265381606066371-64.png',
    'https://pics.freeicons.io/uploads/icons/png/5085242361606066371-64.png',
    'https://pics.freeicons.io/uploads/icons/png/20450028461606066371-64.png',
    'https://pics.freeicons.io/uploads/icons/png/3109018531606066371-64.png',
    'https://pics.freeicons.io/uploads/icons/png/13194530711606066371-64.png',
    'https://pics.freeicons.io/uploads/icons/png/9809412281606066372-64.png',
    'https://pics.freeicons.io/uploads/icons/png/1922070351606066372-64.png',
    'https://pics.freeicons.io/uploads/icons/png/6383590981606004680-64.png',
    'https://pics.freeicons.io/uploads/icons/png/7861365051606004680-64.png',
    'https://pics.freeicons.io/uploads/icons/png/4769813841606004680-64.png',
    'https://pics.freeicons.io/uploads/icons/png/55637581606004680-64.png',
    'https://pics.freeicons.io/uploads/icons/png/6498314701606004681-64.png',
    'https://pics.freeicons.io/uploads/icons/png/9744117671606004680-64.png',
    'https://pics.freeicons.io/uploads/icons/png/8031567241606004682-64.png',
    'https://pics.freeicons.io/uploads/icons/png/15850739121606004683-64.png',
    'https://pics.freeicons.io/uploads/icons/png/20644452761606004681-64.png'
  ]
  return icons
}

export function dynamicImg() {
  let icons = avatars()
  let randomPic = (Math.random() * 57).toFixed(0)
  return icons[randomPic]
}

export function renameGadget(removeDash = true, gadgetName) {
  if (gadgetName.includes('revotio-')) gadgetName = gadgetName.replace('revotio-','')
  if (gadgetName.includes('revotio')) gadgetName = gadgetName.replace('revotio','')
  if (gadgetName.includes('tabsselect')) gadgetName = 'tabs-select'
  if (gadgetName === 'revotiohighcharts') return 'highcharts'
  if (removeDash) {
    return gadgetName.replace(/-/g, "");
  }
}

export function checkAppVersion(pageSessionId, redirect) {
  const appversion = require('../../package.json').version
  const currentHostName = window.location.origin
  let url = `${currentHostName}/static/appversion.json`
  return
  return fetch(url)
  .then(res => res.json())
  .then((out) => {
    const current = out.current
    if (appversion !== current && redirect) {
      window.onbeforeunload = null;
      // window.location.assign(`/i=${pageSessionId}`)
      window.location.reload()
      // win
      return
    }
    return
  })
  .catch(err => { throw err });

}

export function pageApp(requestObj, redirect) {
  const {ap,pageName,data } = requestObj
  const currentHostName = window.location.origin
  let url = `https://api.innonation.nl/pages?applicationId=${ap}&pageName=${pageName}&innonation_key=xx`
  if (data) url = url+`&data=${data}`
  return fetch(url)
  .then(res => res.json())
  .then((out) => {
    return out
  })
  .catch(err => { throw err });
}
export function exBackend(requestObj, requester) {
  const {ap,pageName } = requestObj
  const currentHostName = window.location.origin
  let url = `${requester}`
  return fetch(url)
  .then(res => res.json())
  .then((out) => {
    return out
  })
  .catch(err => { throw err });
}
export function fetchDummdyData(type, fileName,id) {
  let mock = getMockData(fileName)
  let obj = {}
  if (type === 'gadgets' && mock) {
    mock.gadgetId = id
    obj.gadgetsList = [mock]
  }
 return obj
}
export function isColorText (color) {
  return !/RGB|rgb|#/.test(color)
}

export function colorTextToHex (color) {
  if(!isColorText(color)) return color
  const colorL = color.toLowerCase()
  const code = colorCodes[colorL] ? colorCodes[colorL] : color
  return code
}

export function routerParams (router) {
  if (router.currentRoute) {
    return router.currentRoute.query
  } else return {}
}

export function getUrlParam (paramName) {
  let searchParams = new URLSearchParams(location.search);
  return searchParams.get(paramName)
}

export function isMobile (type,check=false) {
  // readFilePath()
  // testMap()
  var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      const mobilecheck = navigator.userAgentData.mobile;
      const isiPad = navigator.userAgent.match(iPad/i)
      return mobilecheck && !isiPad ? navigator.userAgent.match(/iPhone|iPad|iPod/i) : false;
    },
    iOSForce: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOSForce() || isMobile.Opera() || isMobile.Windows());
    }
  };
  return check ? isMobile[type]() : (isMobile.Android() ? 'android' : isMobile.iOSForce() ? 'android' : isMobile.BlackBerry() ? 'blackbery' : 'na');
}
export function appType () {
  return isMobile('',false)
}
export function isTouchDevice () {
  const istouchdevice = isMobile('any',true);
  return istouchdevice
}
export function isInViewport(el) {
  const rect = el ? el.getBoundingClientRect() : {};
  const val = {
    within:  (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    ),
    visibletop : rect.top >= 0,
    visibleLeft : rect.left >= 0,
    visiblebottom : rect.bottom <= (window.innerHeight || document.documentElement.clientHeight),
    visibleright: rect.right <= (window.innerWidth || document.documentElement.clientWidth),
    rectBottom : rect.bottom,
    windowHeight : window.innerHeight,
    windowWidth:window.innerWidth,
    rectTop : rect.top,
    rectRight : rect.right,
  }
  return val
}
export async function capacitorFileDownload(fileinfo) {return}
export async function capacitorFileDownloadDeprecated(fileinfo) {
  await Filesystem.writeFile({
    path: fileinfo.fileName,
    data: fileinfo.data,
    directory: Directory.Data
 }).then((e)=>{
   try {
    cordova.plugins.fileOpener2.showOpenWithDialog(
      e.uri, // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
      fileinfo.fileType,
      {
        error : function(e) { console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
      },
      success : function () {
        console.log('file opened successfully');
      },
      position : [0, 0]
      }
    );
   }catch(e) {
    downloadFile(fileinfo,true)
   }
 });
}
export async function downloadFile (fileinfo,force = false) {
  let touchDevice = !force ? isTouchDevice() : false;
  if (touchDevice) {
    // capacitorFileDownload(fileinfo)
    var a = document.createElement("a"); //Create <a>
    a.href = fileinfo.data; //Image Base64 Goes here
    a.download = fileinfo.fileName; //File name Here
    a.click();
  } else {
    var a = document.createElement("a"); //Create <a>
    a.href = fileinfo.data; //Image Base64 Goes here
    a.download = fileinfo.fileName; //File name Here
    a.click();
  }
}
export async function capacitorBrowser(url, target, force) {
  let touchDevice = !force ? isTouchDevice() : false;
  if (touchDevice) {
    // await Browser.open({ url: url }); --deprecated
    window.open(url, target || '_blank')
  } else {
    window.open(url, target || '_blank')
  }
  return
}
export function getWeek_deprecate(year,month,day) {
  /*
  function serial(days) { return 86400000*days; }
  function dateserial(year,month,day) { return (new Date(year,month-1,day).valueOf()); }
  function weekday(date) { return (new Date(date)).getDay()+1; }
  function yearserial(date) { return (new Date(date)).getFullYear(); }
  var date = year instanceof Date ? year.valueOf() : typeof year === "string" ? new Date(year).valueOf() : dateserial(year,month,day), 
      date2 = dateserial(yearserial(date - serial(weekday(date-serial(1))) + serial(4)),1,3);
  return ~~((date - date2 + serial(weekday(date2) + 5))/ serial(7));
  */
  const checkdate = new Date(year,month -1,day);
  const dd = checkdate.getDay() <= 2 && checkdate.getDay() > 0 ? parseInt(day) + 1 : day;
  const currentDate = new Date(year,month -1,dd);
  const startDate = new Date (currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil(days / 7);
  return weekNumber
}
function getWeek(year,month,day) {
  const date = new Date(year,month -1,day,3);
  const currentDate = (typeof date === 'object') ? date : new Date();
  const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
  const daysToNextMonday = (januaryFirst.getDay() === 1) ? 0 :   (7 - januaryFirst.getDay()) % 7;
  const nextMonday =  new Date(currentDate.getFullYear(), 0, januaryFirst.getDate() + daysToNextMonday);

  return (currentDate < nextMonday) ? 52 :
    (currentDate > nextMonday ? Math.ceil(
    (currentDate - nextMonday) / (24 * 3600 * 1000) / 7) : 1);
}
export function weekdate(year, week, dayNumber)
{
    var j1 = new Date( year,0,10,12,0,0),
        j2 = new Date( year,0,4,12,0,0),
        mon1 = j2.getTime() - j1.getDay() * 86400000;
    return new Date(mon1 + ((week- 1)  * 7  + dayNumber) * 86400000);
};
export function timeConvert(n,locale) {
  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  const hrs = locale ? locale.hrs : 'hrs'
  const hr = locale ? locale.hr : 'hr'
  const mins = locale ? locale.mins : 'mins'
  const min = locale ? locale.min : 'min'
  let hs = rhours > 1 ? hrs : hr
  let ms = rminutes > 1 ? mins : min
  let displayhr = rhours <= 0 ? '' : `${rhours}${hs}`
  let displaymn = rminutes <= 0 ? '' : `${rminutes}${ms}`
  return n === 0 ? `0 ${hr}` : `${displayhr} ${displaymn}`
}
export function getDateOfWeek(w, y) {
  var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
  return new Date(y, 0, d);
}
export default {
  contentStylesFormat,
  pageDesignObject,
  pageMergeDesign,
  getGadgetInfo,
  getGadgetInfotrueCustom,
  getGadgetData,
  getGadgetContent,
  getGadgetOptions,
  getGadgetContentData,
  getMockData,
  convertIcon2Material,
  upperCase,
  isFileImage,
  isImage,
  getFileName,
  fileTypeIcon,
  gridClasses,
  gridClassesItems,
  tailWidthClass,
  gadgetMin,
  gadgetInMin,
  sibsGridClasses,
  dataRecal,
  setwinSize,
  addRemoveAppClass,
  addRemoveBodyClass,
  getProgress,
  thousandFormat,
  createGUID,
  createRandomID,
  createBSONID,
  dynamicImg,
  avatars,
  renameGadget,
  checkAppVersion,
  isColorText,
  colorTextToHex,
  pageApp,
  fetchDummdyData,
  routerParams,
  getUrlParam,
  isTouchDevice,
  isMobile,
  appType,
  isInViewport,
  downloadFile,
  capacitorFileDownload,
  capacitorBrowser,
  getWeek,
  weekdate,
  timeConvert,
  getDateOfWeek
}
