var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showMenu
    ? _c(
        "div",
        { attrs: { id: "parentx menuGadget" } },
        [
          _c("v-nav-menu", {
            attrs: {
              navMenuItems: _vm.menuObject,
              enableThemeMenu: _vm.enableThemeMenu,
              enableAppTour: _vm.enableAppTour,
              title: _vm.appName,
              parent: ".layout--main",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }