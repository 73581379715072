// The Vue build version to load with the `import` command
import Vue from 'vue'
import App from './App'
import router from './router/appRouter'
import store from './store'
import VueProgressBar from 'vue-progressbar'
import ApiService from './common/api.service'
import ThemeService from '@/common/theme.service'
let themeConfigs = ThemeService.getThemeConfigs()
    // Firebase // move later to chat
    // import '@/firebase/firebaseConfig'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import './assets/css/main.css'
require("./assets/styles/global.scss")
    // Feather font icon
require('./assets/css/iconfont.css')
import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon);
/* eslint-disable */
// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
// Vuexy Admin Filters
import './filters/filters'
// Froala
// DEPRICATING
// import 'froala-editor/js/plugins.pkgd.min.js';
// // Require Froala Editor css files.
// require('froala-editor/css/froala_editor.pkgd.min.css')
// require('froala-editor/css/froala_style.min.css')

// import VueFroala from 'vue-froala-wysiwyg'
// Vue.use(VueFroala)

// let's add the front end component vue
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' // Vuesax styles
// import 'material-icons/iconfont/material-icons.css'
import './assets/css/materialIcon.css'
import accounting from 'accounting-js/dist/accounting.umd'
import VueClipboard from 'vue-clipboard2'

Vue.use(Vuesax) // geting error when adding the theme here

//vuetour
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard) // geting error when adding the theme here
import gallery from 'img-vuer'
// TODO hidden for issue 
import VuePlyr from 'vue-plyr'
Vue.use(gallery, VuePlyr)
    // eslint-disable-next-line
    // Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)
import VuePrism from 'vue-prism'
Vue.use(VuePrism)
import 'prismjs/themes/prism.css'

import Popover from 'vue-js-popover'

Vue.use(Popover)
Vue.prototype.$accounting = accounting;
Vue.config.productionTip = false
ApiService.init()
Vue.use(VueProgressBar, {
    color: themeConfigs.colors.primary, // todo
    failedColor: 'red',
    thickness: '5px',
    autoFinish: false
})
router.beforeEach((to, from, next) => {
    let query = to.query
    if(from.path === '/' && !from.name) {
      Promise.all([store.dispatch('checkAuthInfo', query)]).then(next)
    }
    }
  )

/* eslint-disable no-new */
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
