import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/components/Main'
import courseProgress from '@/components/gadgets/xapi-course/sendProgress'
import error from '@/components/error/error'
import E502 from '@/components/error/502'
import download from '@/components/download'
import networkerror from '@/components/error/networkerror'

Vue.use(Router)
const route = new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'Main',
      component: Main,
      children: [
        {
          path: '/activation=:activationToken',
          name: 'activationToken',
          component: Main
        },
        {
          path: '/reset=:resetToken',
          name: 'resetToken',
          component: Main
        },
        {
          path: '/partner=:partner',
          name: 'partner',
          component: Main
        },
        {
          path: '/i=:session',
          name: 'session',
          component: Main,
          children: [
            { path: '/i=:session/export',
             name: 'exportSession',
             component: Main,
             children: [
               { path: '/i=:session/export/:type',
                name: 'exportSessionType',
                component: Main
               }
             ]
            }
          ]
        },
        {
          path: '/export',
          name: 'exportMain',
          component: Main,
          children: [
            { path: '/export/:type',
             name: 'exportMainType',
             component: Main
            }
          ]
        },
        {
          path: '/_/:flexiendpoint',
          name: 'flexiendpoint',
          component: Main,
          children: [
            { path: '/_/:flexiendpoint/export',
             name: 'exportFlexi',
             component: Main
            }
          ]
        }
      ]
    },
    {
      path: '/sendProgress/:gadgetInfo/:progress',
      name: 'courseProgress',
      component: courseProgress
    },
    {
      path: '/error/:error/:message',
      name: 'error',
      component: error,
      props: true
    },
    {
      path: '/e502',
      name: 'e502',
      component: E502
    },
    {
      path: '/downloadPublic',
      name: 'download',
      component: download,
      children:[{
        path:'/downloadPublic/:pathToken/:requestingUrl',
        name: 'bypath',
        component: download,
      }
      ]
    },
    {
      path: '/networkerror',
      name: 'networkerror',
      component: networkerror
    }
  ]
})

export default route
